import { FieldProps } from 'formik';
import React, { FC, memo, useCallback, useMemo, useState } from 'react';
import BaseDatePicker from 'react-datepicker';
import InputMask from 'react-input-mask';
import { down, up } from 'styled-breakpoints';
import styled from 'styled-components';
import Label, { LabelProps } from '../Label';

const DatePickerWrapper = styled.div`
  .date-picker,
  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    width: 100%;

    input {
      font-family: inherit;
      color: #020204;
      box-sizing: border-box;
      width: 100%;
      border: none;
      border-radius: 4px;
      background-color: #fff;
      -webkit-appearance: none; // required for box-shadow to work on mobile safari
      box-shadow: 0 2px 6px 0 rgba(94, 97, 100, 0.2);
      font-size: 0.8rem;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.76;
      letter-spacing: 0.3px;
      outline: none;
      display: flex;
      align-items: center;
      padding: 0 0.75rem;
      height: 3rem;
      ${up('sm')} {
        height: 3.75rem;
      }
      // https://stackoverflow.com/questions/45592319/placeholder-style-not-working-in-chrome
      &::-webkit-input-placeholder {
        color: #787679;
      }
      &:-moz-placeholder {
        color: #787679;
      }
    }
  }
  .react-datepicker__tab-loop {
    ${down('sm')} {
      display: none;
    }
  }

  .SingleDatePicker {
    width: 100%;
  }

  .SingleDatePickerInput {
    display: flex;
  }

  .DateInput {
    width: 100%;
  }

  .error-border {
    border-radius: 4px;
    border: solid 1px ${({ theme }) => theme.colors.primaryDanger};
    background: #fff;
  }
`;

type DateType = Date | null | undefined;

type DatePickerProps = {
  onChange: (date: DateType) => void;
  initialDate: DateType;
  subLabel: string;
  label: string;
  placeholder: string;
  wrapperClass: string;
  labelWrapperClass: string;
  subLabelClass: string;
  labelClass: string;
  meta: {
    touched: boolean;
    error: any;
  };
  disabled: boolean;
  hasError: boolean;
  checkTouched: boolean;
  minDate: DateType;
  maxDate: DateType;
};

export const DatePicker: FC<DatePickerProps & FieldProps & LabelProps> = memo(
  ({
    label,
    subLabel,
    labelClass,
    labelWrapperClass,
    subLabelClass,
    placeholder,
    disabled,
    minDate,
    maxDate,
    wrapperClass,
    initialDate,
    form,
    field,
    onChange: externalOnChange,
    meta,
    hasError,
    checkTouched,
  }) => {
    const [date, setDate] = useState<DateType>(initialDate || null);

    const onChange = useCallback(
      (newDate: DateType) => {
        setDate(newDate);
        form.setFieldValue(field.name, newDate);
        if (externalOnChange) {
          externalOnChange(newDate);
        }
      },
      [field.name, form.setFieldValue, externalOnChange],
    );

    const showError = useMemo(() => {
      if (checkTouched) {
        return hasError || (meta && meta.touched && meta && meta.error);
      }
      return hasError || (meta && meta.error);
    }, [meta, hasError, checkTouched]);

    return (
      <DatePickerWrapper
        className={`${wrapperClass} ${showError ? ' error-wrapper' : ''}`}
        data-error-field={field.name}
      >
        <Label
          label={label}
          subLabel={subLabel}
          labelClass={labelClass}
          subLabelClass={subLabelClass}
          labelWrapperClass={labelWrapperClass}
          showError={showError}
        />

        <div className={showError ? 'error-border' : undefined}>
          <BaseDatePicker
            placeholderText={placeholder}
            selected={date}
            onChange={onChange}
            disabled={disabled}
            showYearDropdown={true}
            showMonthDropdown={true}
            minDate={minDate}
            maxDate={maxDate}
            autoComplete="off"
            customInput={<InputMask mask="99/99/9999" />}
          />
        </div>
      </DatePickerWrapper>
    );
  },
);
