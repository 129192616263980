import client from '../../../utils/api-client';
import { useCallback, useState } from 'react';

async function removeKid(kidId: string) {
  return await client(`api/kids/${kidId}/`, { method: 'DELETE' });
}

function useRemoveKid(onSuccess: (kidId: string) => void) {
  const [state, setState] = useState({ isLoading: false, error: null });

  const remove = useCallback(async (kidId: string) => {
    setState({ isLoading: true, error: null });
    try {
      await removeKid(kidId);
      setState({ isLoading: false, error: null });
      onSuccess(kidId);
    } catch (error) {
      setState({ isLoading: false, error: error });
    }
  }, []);

  return { ...state, remove };
}

export default useRemoveKid;
