import React, { memo, ReactNode, useCallback, useEffect, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { down, only } from 'styled-breakpoints';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(166, 179, 193, 0.9);
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

export const Modal = styled.div<{ width?: string }>`
  width: ${({ width }) => width || '55%'};
  padding: 30px 10px;
  border-radius: 4px;
  box-shadow: 0 12px 26px 0 rgba(20, 20, 20, 0.1);
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  ${down('md')} {
    width: 100%;
    margin: 0 10px;
  }

  ${only('md')} {
    width: 80%;
    margin: 0 10px;
  }

  .m0 {
    margin: 0;
  }
`;

export const Container = styled.div`
  padding: 30px 15px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  h2 {
    margin: 20px 0;
  }

  p {
    margin: 10px 50px;
    text-align: center;
  }
`;

const Header = styled.div`
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
`;

const CloseIcon = styled(AiOutlineClose)`
  font-weight: 600;
  cursor: pointer;
  font-size: 23px;
`;

export type SimpleModalProps = {
  children?: ReactNode;
  title?: ReactNode;
  onClose?: () => void;
  open: boolean;
  closeable?: boolean;
  className?: string;
  width?: string;
};

const SimpleModal = (props: SimpleModalProps) => {
  const [_isVisible, setIsVisible] = useState<boolean>(null);

  useEffect(() => {
    setIsVisible(props.open);
  }, [props.open]);

  const onCloseHandler = useCallback(() => {
    if (props.onClose) props.onClose();
    setIsVisible(false);
  }, [props.onClose]);

  if (!_isVisible) return null;

  return (
    <Wrapper className={props.className}>
      <Modal width={props.width}>
        <Header>
          {typeof props.title === 'object' ? props.title : <span>{props.title}</span>}
          {props.closeable && <CloseIcon onClick={onCloseHandler} />}
        </Header>
        <Container> {props.children} </Container>
      </Modal>
    </Wrapper>
  );
};

export default memo(SimpleModal);
