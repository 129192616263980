import styled from 'styled-components';

const SimpleLinkButton = styled.a`
  color: ${({ theme }) => theme.colors.black};
  text-decoration: underline;
  cursor: pointer;
  margin: 10px 0;
`;

export default SimpleLinkButton;
