import styled from 'styled-components';

import BaseButtonStyle, { SmallButtonStyle } from './BaseButtonStyle';

const LinkButton = styled.a`
  ${BaseButtonStyle};
  ${SmallButtonStyle};
  text-decoration: none;
`;

export default LinkButton;
