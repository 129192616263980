import { Field, useFormikContext } from 'formik';
import React, { FC } from 'react';
import { down } from 'styled-breakpoints';
import styled from 'styled-components';
import { Checkbox } from '../../../../../components/CheckboxField';
import { ChoiceWithImage } from '../../../../../components/ItemsCheckbox';
import { colors } from '../../../../../constants/colors';
import { StepId } from '../../../constants/steps';
import { useOnCompleteHookProvider, useQuizValues } from '../../../context/QuizDataContext';
import hasErrorFactory from '../../../utils/hasErrorFactory';
import { ConditionalHiddenArea } from '../../ConditionalDisabledArea';
import { GridWithImagesChoicesSelector } from '../../GridWithImagesChoicesSelector';
import { NoteWrapperBlock, StepBodyWrapper, StepHeader, StepWrapper } from '../../StepLayout';

const normalizedColors: ChoiceWithImage[] = colors.map(c => ({
  value: c.key,
  label: c.value,
  img: {
    src: c.src,
    alt: c.value,
  },
}));

const StyledStepBodyWrapper = styled(StepBodyWrapper)`
  .step-input {
    gap: 1rem;
    margin-top: 0;
    ${down('sm')} {
      column-gap: 0.25rem;
    }

    .checkbox-item {
      flex-basis: 8rem;
      min-width: unset;
      width: unset;
      margin: 0;
      padding: 0.25rem;
      ${down('sm')} {
        flex-basis: 6rem;
      }

      img {
        padding: 0;
        width: 100%;
        height: auto;
        aspect-ratio: 1/1;
        border-radius: 50%;
        border: 1px solid lightgrey;
      }
      .checkbox-item-value {
        padding: 0;
        ${down('sm')} {
          font-size: 0.75rem;
        }
      }
    }
  }

  ${down('sm')} {
    padding: 0;
    margin-top: -1rem;

    ${ConditionalHiddenArea} {
      width: 100%;

      .grid-wrapper {
        width: 100%;
      }
    }
  }
`;

export const HatedColorsStep: FC = () => {
  const allStepsValues = useQuizValues();
  const kidName = allStepsValues[StepId.SelfInfo] && allStepsValues[StepId.SelfInfo].name;
  const { values, errors, submitCount, touched } = useFormikContext<any>();
  const hasError = hasErrorFactory(submitCount, touched, errors);

  return (
    <StepWrapper>
      <StepHeader>
        {`What colors should we AVOID${kidName ? ` for ${kidName}` : ''}?`}
        <NoteWrapperBlock>(Select all that apply)</NoteWrapperBlock>
      </StepHeader>
      <StyledStepBodyWrapper>
        <Field
          name="dont_dislike_colors"
          value={values.dont_dislike_colors}
          label="Do not really dislike any of these colors"
          labelWrapperClass="step-label"
          wrapperClass="step-input"
          component={Checkbox}
        />
        <ConditionalHiddenArea disabled={values.dont_dislike_colors} data-testid="ConditionalDisabledArea">
          <GridWithImagesChoicesSelector
            name="colors_hated"
            className="grid-wrapper"
            items={normalizedColors}
            value={values.dont_dislike_colors ? [] : values.colors_hated}
            hasError={hasError('colors_hated')}
          />
        </ConditionalHiddenArea>
      </StyledStepBodyWrapper>
    </StepWrapper>
  );
};

export const useOnComplete = () => {
  const { updateKid, currentKidId } = useOnCompleteHookProvider();
  return async (values: { colors_hated?: string[]; dont_dislike_colors?: boolean }) => {
    return updateKid(currentKidId as string, {
      dont_dislike_colors: !!values.dont_dislike_colors,
      colors_hated: values.dont_dislike_colors ? [] : values.colors_hated,
    });
  };
};
