import { useCallback, useState } from 'react';
import * as yup from 'yup';
import { login } from '../../../services/auth';
import { useRouter } from 'next/router';
import { User } from '../../../types';
import { loginRedirect } from '../../../utils/redirect';
import useQueryParams from '../../../hooks/useQueryParams';
import { identifyOnImpact } from '../../../utils/analytics';

type SignInValues = {
  email: string;
  password: string;
};

type SingInState = {
  validationSchema: object;
  isLoading: boolean;
  error: Error | null;
  submitHandler: (values: SignInValues, actions) => void;
};

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .required('Required')
    .email(),
  password: yup.string().required(),
});

const initState = {
  isLoading: false,
  error: null,
};

const useSignInForm = (): SingInState => {
  const router = useRouter();
  const [state, setState] = useState<{ isLoading: boolean; error: Error | null }>(initState);
  const { queryAsString } = useQueryParams(['rt', 'return', 'error', 'quiz'], true);

  const submitHandler = useCallback(
    async (values, actions) => {
      setState({ isLoading: true, error: null });

      let user: User;

      try {
        const response = await login(values);
        user = response;
        identifyOnImpact(user.id, user.email_sha1);
      } catch (error) {
        setState({ isLoading: false, error });
        return;
      }

      actions.resetForm();
      loginRedirect(user, router, queryAsString);
    },
    [queryAsString, router],
  );

  return { validationSchema, submitHandler, ...state };
};

export default useSignInForm;
