import { up } from 'styled-breakpoints';
import styled from 'styled-components';

export const NavigationBlock = styled.div`
  max-width: 420px;
  width: 100%;
  margin: auto;
  padding: 0.875rem 0;
  ${up('sm')} {
    padding: 1.5rem 0;
  }
  display: flex;
  align-items: center;
  justify-self: center;
  justify-content: center;
`;
