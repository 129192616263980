import React, { FC, memo } from 'react';
import Alert, { AlertType } from '../../../../components/Alert';
import { FormikErrors, FormikTouched, useFormikContext } from 'formik';

type Props = {
  fieldName: string;
  message: string;
};

const objectHasNotEmptyKey: (obj: any, key: string) => boolean = (obj, key) => key in obj && !!obj[key];

const checkForError = (submitCount: number, touched: FormikTouched<any>, errors: FormikErrors<any>) => (name: string) =>
  (submitCount > 0 || objectHasNotEmptyKey(touched, name)) && objectHasNotEmptyKey(errors, name);

const ErrorAlert: FC<Props> = ({ fieldName, message }) => {
  const { errors, submitCount, touched } = useFormikContext<any>();
  const hasError = checkForError(submitCount, touched, errors);

  return hasError(fieldName) ? <Alert type={AlertType.ERROR}>{message}</Alert> : null;
};

export default memo(ErrorAlert);
