import React, { Children, ReactNode } from 'react';
import styled from 'styled-components';
import { down } from 'styled-breakpoints';

/* assets */
import loader from '../../../../../public/static/images/loader.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${down('sm')} {
    margin: 8px auto;
  }

  .button {
    border-width: 0;
    padding: 12px 20px;
    font-size: 14px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.primary};
    width: 100%;
    cursor: pointer;
    text-transform: uppercase;
    line-height: 1.5em;

    &:disabled {
      opacity: 0.4;
    }

    &:hover {
      opacity: 0.5;
    }
  }

  .button-inverse {
    background-color: white;
    color: ${({ theme }) => theme.colors.primary};
  }

  .loader {
    border-radius: 50%;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

type Props = {
  onClick?: () => void;
  handleSubmit?: () => void;
  name?: string;
  type: 'submit' | 'button';
  wrapperClass?: string;
  buttonClass?: string;
  value?: string;
  children?: ReactNode;
  disabled?: boolean;
  inverseColor?: boolean;
  isLoading?: boolean;
  className?: string;
};

const ButtonWrapper = (props: Props) => {
  const {
    name = '',
    type = 'button',
    wrapperClass = '',
    buttonClass = '',
    className = '',
    value = '',
    children,
    disabled = false,
    inverseColor = false,
    isLoading = false,
    handleSubmit = () => {
      // noop
    },
    onClick = () => {
      // noop
    },
  } = props;

  /* eslint-disable no-nested-ternary, react/button-has-type */
  return (
    <Container className={`${wrapperClass} ${className}`}>
      <button
        name={name}
        type={type}
        onClick={onClick}
        onSubmit={handleSubmit}
        className={`button ${buttonClass} ${inverseColor ? 'button-inverse' : ''}`}
        disabled={disabled || isLoading}
      >
        {isLoading ? (
          <img src={loader} className="loader" alt="" />
        ) : children && Children.count(children) ? (
          children
        ) : (
          value
        )}
      </button>
    </Container>
  );
  /* eslint-enable no-nested-ternary, react/button-has-type */
};

export default ButtonWrapper;
