import styled from 'styled-components';
import loaderBlack from '../../../public/static/images/loader-black.svg';

const Spinner = styled.img.attrs({ src: loaderBlack })`
  border-radius: 50%;
  animation: spin 2s linear infinite;
  margin-right: 5px;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default Spinner;
