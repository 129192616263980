import { FC } from 'react';
import { array, boolean, object, ObjectSchema, Shape, string } from 'yup';
import { brands } from '../../../constants/brands';
import { clothesBudget, numItems, outfitBudget, shoesBudget } from '../../../constants/budget';
import { boyClothes, girlClothes } from '../../../constants/clothes';
import colors from '../../../constants/colors';
import { motifs } from '../../../constants/motifs';
import seasons from '../../../constants/seasons';
import { clothesSizes, shoesSizes } from '../../../constants/sizes';
import { choices } from '../components/LikenessSelector';
import { AddKidStep, useOnComplete as addKidUseOnComplete } from '../components/steps/AddKidStep';
import { AlmostDone, useOnComplete as useOnAlmostDoneComplete } from '../components/steps/AlmostDone';
import { BohoLikenessStep, useOnComplete as useOnBohoComplete } from '../components/steps/BohoLikenessStep';
import { ConfirmOrder, useOnConfirmOrderComplete } from '../components/steps/ConfirmOrder';
import { EuroLikenessStep, useOnComplete as useOnEuroComplete } from '../components/steps/EuroLikenessStep';
import { GlamLikenessStep, useOnComplete as useOnGlamComplete } from '../components/steps/GlamLikenessStep';
import { HatedColorsStep, useOnComplete as useOnHatedColorsComplete } from '../components/steps/HatedColorsStep';
import { HatedMotifsStep, useOnComplete as useHatedMotifs } from '../components/steps/HatedMotifsStep';
import { HipsterLikenessStep, useOnComplete as useOnHipsterComplete } from '../components/steps/HipsterLikenessStep';
import { ItemsStep, useOnComplete as useOnItemsComplete } from '../components/steps/ItemsStep';
import { KiddieLikenessStep, useOnComplete as useOnKiddieComplete } from '../components/steps/KiddieLikenessStep';
import { KidInfoStep, useOnComplete as useOnKidInfoComplete } from '../components/steps/KidInfoStep';
import { KidInfoSizes, useOnComplete as useOnKidInfoSizesComplete } from '../components/steps/KidsInfoSizes';
import { LikedBrandsStep, useOnComplete as useOnLikedBrandsComplete } from '../components/steps/LikedBrandsSteps';
import { passwordSchema, PasswordStep, useOnPasswordComplete } from '../components/steps/PasswordStep';
import { PaymentInfo, paymentInfoSchema, useOnPaymentInfoComplete } from '../components/steps/PaymentInfo';
import { PreppyLikenessStep, useOnComplete as useOnPreppyComplete } from '../components/steps/PreppyLikenessStep';
import { ReviewOrder, useOnReviewOrderComplete } from '../components/steps/ReviewOrder';
import { SeasonsStep, useOnComplete as useOnSeasonsComplete } from '../components/steps/SeasonsStep';
// Steps
import { SelfInfoStep, useOnComplete as useOnSelfInfoComplete } from '../components/steps/SelfInfoStep';
import {
  ShippingAddress,
  shippingAddressSchema,
  useOnShippingAddressComplete,
} from '../components/steps/ShippingAddress';
import { ThankYouStep, useOnComplete as useOnThankYouComplete } from '../components/steps/ThankYouStep';
import { StepId } from './steps';

interface Step {
  stepComponent: FC;
  validationSchema: ObjectSchema<Shape<{}, any>>;
  hideNavButtonsBlock?: boolean;
  useOnComplete: () => (values: any) => Promise<any>;
  onlyValidValues?: boolean;
  submitBtnText?: string;
}

const combinedClothes = [...girlClothes, ...boyClothes].map(item => item.value);

export const stepIdToStepMap = new Map<StepId, Step>([
  [
    StepId.SelfInfo,
    {
      stepComponent: SelfInfoStep,
      validationSchema: object().shape({
        first_name: string().notRequired(),
        last_name: string().notRequired(),
        kids_count: string().notRequired(),
        name: string().required(),
      }),
      useOnComplete: useOnSelfInfoComplete,
    },
  ],
  [
    StepId.Kid,
    {
      stepComponent: KidInfoStep,
      validationSchema: object().shape({
        date_of_birth: string().required(),
        gender: string().required(),
        gender_preference: string()
          .nullable()
          .when('gender', {
            is: 'other',
            then: string().required(),
          }),
        women_dress_size: string().notRequired(),
      }),
      useOnComplete: useOnKidInfoComplete,
    },
  ],
  [
    StepId.KidSizes,
    {
      stepComponent: KidInfoSizes,
      validationSchema: object().shape({
        top_size: string()
          .required()
          .oneOf(
            clothesSizes.map(size => size.value),
            'Please, select a valid size',
          ),
        bottom_size: string()
          .required()
          .oneOf(
            clothesSizes.map(size => size.value),
            'Please, select a valid size',
          ),
        shoes_size: string()
          .required()
          .oneOf(
            shoesSizes.map(size => size.value),
            'Please, select a valid size',
          ),
        top_price: string()
          .required()
          .oneOf(
            clothesBudget.map(budget => budget.value),
            'Please, select a valid budget',
          ),
        bottom_price: string()
          .required()
          .oneOf(
            clothesBudget.map(budget => budget.value),
            'Please, select a valid budget',
          ),
        shoes_price: string()
          .required()
          .oneOf(
            shoesBudget.map(budget => budget.value),
            'Please, select a valid budget',
          ),
        outfit_price: string()
          .required()
          .oneOf(
            outfitBudget.map(budget => budget.value),
            'Please, select a valid budget',
          ),
        num_items: string()
          .required()
          .oneOf(
            numItems.map(item => item.value),
            'Please, select a valid number of items',
          ),
      }),
      useOnComplete: useOnKidInfoSizesComplete,
    },
  ],
  [
    StepId.Preppy,
    {
      stepComponent: PreppyLikenessStep,
      validationSchema: object().shape({
        preppy: string()
          .oneOf(
            choices.map(choice => choice.value),
            'Please, select a valid option',
          )
          .required(),
      }),
      hideNavButtonsBlock: true,
      useOnComplete: useOnPreppyComplete,
    },
  ],
  [
    StepId.Euro,
    {
      stepComponent: EuroLikenessStep,
      validationSchema: object().shape({
        euro: string()
          .oneOf(
            choices.map(choice => choice.value),
            'Please, select a valid option',
          )
          .required(),
      }),
      hideNavButtonsBlock: true,
      useOnComplete: useOnEuroComplete,
    },
  ],
  [
    StepId.Hipster,
    {
      stepComponent: HipsterLikenessStep,
      validationSchema: object().shape({
        hipster: string()
          .oneOf(
            choices.map(choice => choice.value),
            'Please, select a valid option',
          )
          .required(),
      }),
      hideNavButtonsBlock: true,
      useOnComplete: useOnHipsterComplete,
    },
  ],
  [
    StepId.Kiddie,
    {
      stepComponent: KiddieLikenessStep,
      validationSchema: object().shape({
        kiddie: string()
          .oneOf(
            choices.map(choice => choice.value),
            'Please, select a valid option',
          )
          .required(),
      }),
      hideNavButtonsBlock: true,
      useOnComplete: useOnKiddieComplete,
    },
  ],
  [
    StepId.Boho,
    {
      stepComponent: BohoLikenessStep,
      validationSchema: object().shape({
        boho: string()
          .oneOf(
            choices.map(choice => choice.value),
            'Please, select a valid option',
          )
          .required(),
      }),
      hideNavButtonsBlock: true,
      useOnComplete: useOnBohoComplete,
    },
  ],
  [
    StepId.Glam,
    {
      stepComponent: GlamLikenessStep,
      validationSchema: object().shape({
        glam: string()
          .oneOf(
            choices.map(choice => choice.value),
            'Please, select a valid option',
          )
          .required(),
      }),
      hideNavButtonsBlock: true,
      useOnComplete: useOnGlamComplete,
    },
  ],
  [
    StepId.Seasons,
    {
      stepComponent: SeasonsStep,
      validationSchema: object().shape({
        seasons: array()
          .of(string())
          .compact(val => !seasons.map(season => season.value).includes(val))
          .required(),
      }),
      onlyValidValues: true,
      useOnComplete: useOnSeasonsComplete,
    },
  ],
  [
    StepId.Items,
    {
      stepComponent: ItemsStep,
      validationSchema: object().shape({
        items: array()
          .of(string())
          .compact(val => !combinedClothes.includes(val))
          .required('You must select at least one item.'),
      }),
      onlyValidValues: true,
      useOnComplete: useOnItemsComplete,
    },
  ],
  [
    StepId.HatedColors,
    {
      stepComponent: HatedColorsStep,
      validationSchema: object().shape({
        colors_hated: array()
          .of(string())
          .compact(val => !colors.map(color => color.key).includes(val))
          .notRequired(),
        dont_dislike_colors: boolean().notRequired(),
      }),
      onlyValidValues: true,
      useOnComplete: useOnHatedColorsComplete,
    },
  ],
  [
    StepId.HatedMotifs,
    {
      stepComponent: HatedMotifsStep,
      validationSchema: object().shape({
        dont_dislike_motifs: boolean().notRequired(),
        motifs_hated: array()
          .of(string())
          .compact(val => !motifs.map(motif => motif.key).includes(val))
          .notRequired(),
      }),
      onlyValidValues: true,
      useOnComplete: useHatedMotifs,
    },
  ],
  [
    StepId.LikedBrands,
    {
      stepComponent: LikedBrandsStep,
      validationSchema: object().shape({
        preferred_brands: array()
          .of(string())
          .compact(val => !brands.map(brand => brand.key).includes(val))
          .required(),
        custom_brands: string().notRequired(),
      }),
      onlyValidValues: true,
      useOnComplete: useOnLikedBrandsComplete,
    },
  ],
  [
    StepId.AddKidStep,
    {
      stepComponent: AddKidStep,
      validationSchema: object().shape({}),
      hideNavButtonsBlock: true,
      useOnComplete: addKidUseOnComplete,
    },
  ],
  [
    StepId.AlmostDone,
    {
      stepComponent: AlmostDone,
      validationSchema: object().shape({}),
      useOnComplete: useOnAlmostDoneComplete,
      hideNavButtonsBlock: true,
    },
  ],
  [
    StepId.Password,
    {
      stepComponent: PasswordStep,
      validationSchema: passwordSchema,
      useOnComplete: useOnPasswordComplete,
      onlyValidValues: true,
      submitBtnText: 'SAVE PASSWORD',
    },
  ],
  [
    StepId.ReviewOrder,
    {
      stepComponent: ReviewOrder,
      validationSchema: object({}),
      useOnComplete: useOnReviewOrderComplete,
    },
  ],
  [
    StepId.ShippingAddress,
    {
      stepComponent: ShippingAddress,
      validationSchema: shippingAddressSchema,
      useOnComplete: useOnShippingAddressComplete,
    },
  ],
  [
    StepId.PaymentInfo,
    {
      stepComponent: PaymentInfo,
      validationSchema: paymentInfoSchema,
      useOnComplete: useOnPaymentInfoComplete,
      submitBtnText: 'SAVE PAYMENT',
    },
  ],
  [
    StepId.ConfirmOrder,
    {
      stepComponent: ConfirmOrder,
      validationSchema: object({}),
      useOnComplete: useOnConfirmOrderComplete,
      submitBtnText: 'PLACE ORDER',
    },
  ],
  [
    StepId.ThankYouStep,
    {
      stepComponent: ThankYouStep,
      validationSchema: object().shape({
        hear_about_us: string().notRequired(),
      }),
      hideNavButtonsBlock: true,
      useOnComplete: useOnThankYouComplete,
    },
  ],
]);
