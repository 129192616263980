import { format } from 'date-fns';
import { Form, Formik, useFormikContext } from 'formik';
import { findLast, pathOr } from 'ramda';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { NavButton } from '../../../../components/Buttons/NavButton';
import FormikScrollToError from '../../../../components/FormikScrollToError';
import { logCustomPageView, logFBEvent } from '../../../../utils/analytics';
import useKlaviyo from '../../../sale-event/hooks/useKlaviyo';
import { KlaviyoEvent } from '../../../sale-event/types';
import { NavigationBlock } from '../../components/NavigationBlock';
import { ProgressBar } from '../../components/ProgressBar';
import { stepIdToStepMap } from '../../constants/stepIdToStepMap';
import { StepId, stepIdToIndexMap, stepSections } from '../../constants/steps';
import {
  useCurrentKidId,
  useCurrentStepId,
  useGoBackOrToIndex,
  useIsSubmitting,
  useLoadedKids,
  useQuizValues,
  useSetSubmitting,
  useSetValuesForStep,
} from '../../context/QuizDataContext';
import { retrieveStoredStepData } from '../../utils';
import { StepInjectorProvider } from './context';

const logQuizStepChangeAnalytics = (stepId: string) => {
  /*
   * Since the transition from storing the step index in the URL,
   *   e.g. /quiz/5 for step 5, we need to send events manually for each
   *   quiz step
   */

  const url = `/quiz/${stepId}`;

  logCustomPageView(url);
  if (stepId === StepId.ConfirmOrder) {
    logFBEvent('track', 'InitiateCheckout');
  }
};

const StyledForm = styled(Form)`
  width: 100%;
  .kid-name {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 12px;
    font-size: 15px;
  }
`;

function flattenQuizValues(slug: String, quizValues: Record<any, any>) {
  const dateFields = ['date_of_birth'];
  const obj = {};
  Object.entries(quizValues).forEach(values => {
    const fieldName = values[0];
    obj[`${slug}_${fieldName}`] = dateFields.includes(fieldName) ? format(new Date(values[1]), 'Y-MM-dd') : values[1];
  });
  return obj;
}

export const StepSubmitBtn: FC = ({ children }) => {
  const isSubmitting = useIsSubmitting();
  const { isValid } = useFormikContext();

  return (
    <NavigationBlock>
      <NavButton type="submit" isLoading={isSubmitting} disabled={isSubmitting} {...{ isValid }}>
        {children || 'Next'}
      </NavButton>
    </NavigationBlock>
  );
};

export const StepInjector: FC = () => {
  const currentStepId = useCurrentStepId();
  const currentStepIndex = stepIdToIndexMap.get(currentStepId);
  const quizValues = useQuizValues();
  const setValuesForStep = useSetValuesForStep();
  const setSubmitting = useSetSubmitting();
  const goBackOrToIndex = useGoBackOrToIndex();
  const step = stepIdToStepMap.get(currentStepId);
  const kidId = useCurrentKidId();
  const loadedKids = useLoadedKids();
  const { trackProperties } = useKlaviyo(true);

  const { lastKidName } = retrieveStoredStepData();

  useEffect(() => {
    logQuizStepChangeAnalytics(currentStepId);
  }, [currentStepIndex]);

  const initialValues = useMemo(() => {
    const values: { [fieldName: string]: any } = {};
    if (step) {
      Object.keys(step.validationSchema.fields).forEach(fieldName => {
        values[fieldName] = ['password', 'password_confirmation'].includes(fieldName)
          ? ''
          : quizValues[currentStepId] && quizValues[currentStepId][fieldName];
        // use the last instagramId if empty
        if (fieldName === 'instagram_id' && loadedKids && !values.instagram_id) {
          const lastKid = findLast(item => item.name != pathOr('', ['self', 'name'], quizValues), loadedKids);
          values['instagram_id'] = pathOr('', ['instagram_id'], lastKid);
        }
      });
    }
    return values;
  }, [step, quizValues, loadedKids]);

  const stepOnComplete = step && step.useOnComplete && step.useOnComplete();
  const [beforeSubmit, setBeforeSubmit] = useState<() => Promise<boolean | undefined>>();

  const handleOnSubmit = useCallback(
    async (values: any, actions: any) => {
      let nextStep = null;

      // formik doesn't filter out yup.array().compact() values. So re-validate
      if (step.onlyValidValues) values = step.validationSchema.validateSync(values);

      if (beforeSubmit) {
        setSubmitting(true);
        const shouldProceed = await beforeSubmit();
        if (!shouldProceed) {
          setSubmitting(false);
          return;
        }
      }

      // tracking quiz step and quiz answers to Klaviyo
      trackProperties(KlaviyoEvent.QUIZ_STEP, {
        quizStep: currentStepIndex,
        stepId: currentStepId,
        kidName: lastKidName,
        ...flattenQuizValues(currentStepId, values),
      });

      try {
        if (stepOnComplete) {
          nextStep = await stepOnComplete(values);
        }
      } finally {
        setSubmitting(false);
      }
      actions.setSubmitting(false);
      setValuesForStep(currentStepId, values);

      if (nextStep) {
        goBackOrToIndex(nextStep);
      }

      window.scrollTo(0, 0);
    },
    [stepOnComplete, setValuesForStep, currentStepId, currentStepIndex, trackProperties, lastKidName],
  );

  if (!step) {
    return null;
  }

  const navDisabled = useMemo(() => {
    if (currentStepIndex === 0 && !kidId) {
      return true;
    }
    return false;
  }, [currentStepIndex, currentStepId, kidId]);

  return (
    <StepInjectorProvider value={{ setBeforeSubmit: cb => setBeforeSubmit(() => cb) }}>
      <Formik
        key={currentStepId}
        onSubmit={handleOnSubmit}
        initialValues={initialValues}
        validationSchema={step.validationSchema}
        validateOnMount={true}
      >
        <StyledForm>
          <ProgressBar stepSections={stepSections} navDisabled={navDisabled} />
          <step.stepComponent />
          {!step.hideNavButtonsBlock && <StepSubmitBtn>{step.submitBtnText}</StepSubmitBtn>}
          <FormikScrollToError />
        </StyledForm>
      </Formik>
    </StepInjectorProvider>
  );
};
