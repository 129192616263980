import { clone } from 'ramda';
import client from '../../../../utils/api-client';
import { IQuizValues } from '../../context/QuizDataContext';
import { assignQuizValuesFromList } from '../../utils';

export interface Address {
  id?: string;
  address_1?: string;
  address_2?: string;
  city?: string;
  zip_code?: string;
  state?: string;
}

// TODO: Clean this up, why is everything optional?
export interface MeDataResponse {
  email?: string;
  first_name?: string;
  last_name?: string;
  auth_token?: string;
  stripe_payment_source_last4?: string;
  stripe_payment_source_exp_month?: string;
  stripe_payment_source_exp_year?: string;
  stripe_payment_source_funding?: 'credit' | 'debit' | 'prepaid' | 'unknown';
  allow_emails?: boolean;
  allow_texts?: boolean;
  phone_number?: string;
  kids_count?: number;
  stripe_token?: string;
  stripe_payment_source_address_city?: string;
  stripe_payment_source_address_line1?: string;
  stripe_payment_source_address_line2?: string;
  stripe_payment_source_address_state?: string;
  stripe_payment_source_address_zip?: string;
  stripe_customer_id?: string;
  stripe_account_balance?: string;
  has_quiz_completed?: boolean;
  id?: number;
  can_refer?: boolean;
  active_gift?: any;
  leftover_gift_credit?: string;
  hear_about_us?: string;
  is_superuser?: boolean;
  address?: Address;
  password?: string;
  password_confirmation?: string;
  women_dress_size?: string;
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
  signup_coupon_code?: string;
  email_sha1?: string;
  has_password?: boolean;
}

type KlaviyoProfileParams = {
  address_line_1: string;
  address_line_2: string;
  email: string;
  zip: string;
};

export const createAddress: (address: Address) => Promise<Address> = address =>
  client<Address>('api/addresses/', { body: address, method: 'POST' });

export const updateSelfInfo: (newInfo: MeDataResponse) => Promise<MeDataResponse> = newInfo =>
  client<MeDataResponse>('api/users/me/', { body: newInfo, method: 'PATCH' });

export const loadSelfInfo: () => Promise<MeDataResponse> = () => client<MeDataResponse>('api/users/me/');

export const nestFlatMeDataToSteps: (initialValues: IQuizValues, _data: MeDataResponse) => IQuizValues = (
  initialValues,
  _data,
) => {
  // IMeDataResponse, but with flatten address
  const data: any = clone(_data);
  // flatten address
  if (data.address) {
    Object.keys(data.address).forEach(key => {
      data[key] = data.address[key];
    });
    data.address = undefined;
  }
  return assignQuizValuesFromList(initialValues, data);
};

export const updateKlaviyoProfile: (data: KlaviyoProfileParams) => Promise<any> = data =>
  client('api/addresses/prior_quiz_confirmation/', { body: data, method: 'POST' });
