import { pathOr } from 'ramda';

async function decodeErrorResponse(
  errorResponse: Response,
  defaultResponse: string = 'An error has occurred.',
  errorKey?: string | any[] | ((errJson: any) => string | string[]),
): Promise<string> {
  try {
    if (!errorResponse) {
      return defaultResponse;
    }
    if (errorResponse.status === 401) {
      return 'Session expired. Please reload the page.';
    }
    const text = await errorResponse.text();
    const data = JSON.parse(text);
    const dataKeys = Object.keys(data);

    if (data?.message) {
      return data?.message;
    }
    if (data?.detail) {
      return data?.detail;
    }
    if (data?.coupon?.[0]) {
      return data.coupon[0];
    }
    if (data?.non_field_errors?.[0]) {
      return data.non_field_errors[0];
    }
    if (typeof data === 'string') {
      return data;
    }
    if (errorKey && Array.isArray(dataKeys)) {
      const msg =
        typeof errorKey === 'function'
          ? errorKey(data) || defaultResponse
          : pathOr(defaultResponse, Array.isArray(errorKey) ? errorKey : [errorKey], data);
      if (Array.isArray(msg)) {
        return msg.join(' ');
      }
      return msg;
    }
    if (Array.isArray(data) && !data.some((d: any) => typeof d === 'object')) {
      return data.join(' ');
    }
    return defaultResponse;
  } catch (e) {
    // non parseable response
    return defaultResponse;
  }
}

export { decodeErrorResponse };
