import { css } from 'styled-components';

const SocialTextButtonStyle = css`
  margin: 0 !important;
  color: ${({ theme }) => theme.colors.facebook} !important;
  &:hover {
    background-color: unset;
  }
  background-color: unset;
  text-transform: unset;
  font-size: 0.8rem;
  letter-spacing: 0.12em;
  font-weight: 600;
`;

export default SocialTextButtonStyle;
