import styled from 'styled-components';

type ConditionalDisabledArea = { disabled?: boolean };

/**
 * ConditionalDisabledArea - div wrapper, upon some condition changes opacity
 * and disables pointer events.
 * @prop disabled {boolean} - if true everything inside wrapper is dimmed and pointer
 * events are disabled.
 *
 */
export const ConditionalDisabledArea = styled.div<ConditionalDisabledArea>`
  pointer-events: ${props => (props.disabled ? 'none' : 'unset')};
  opacity: ${props => (props.disabled ? 0.4 : 1)};
`;

export const ConditionalHiddenArea = styled.div<ConditionalDisabledArea>`
  ${({ disabled }) => (disabled ? 'display: none;' : '')}
`;
