import React, { useState, useEffect, useCallback } from 'react';

type KountCollectEndParams = {
  MercSessId: string;
  MerchantId: string;
};

function useKountSession() {
  const [sessionId, setSessionId] = useState<string>('');

  const collectEndFn = useCallback((params: KountCollectEndParams) => setSessionId(params.MercSessId), []);

  useEffect(() => {
    if ((window as any).ka && (window as any).ka.ClientSDK) {
      const kaClient = new (window as any).ka.ClientSDK();
      kaClient.setupCallback({
        'collect-end': collectEndFn,
      });

      kaClient.autoLoadEvents();
    }
  }, []);

  return { sessionId };
}

export { useKountSession };
