import React, { FC, memo } from 'react';
import styled from 'styled-components';
import { down } from 'styled-breakpoints';
import { Field } from 'formik';

import ItemsCheckbox, { ChoiceWithImage } from '../../../../components/ItemsCheckbox';

type GridWithImagesChoicesSelectorProps = {
  name: string;
  items: ChoiceWithImage[];
  value?: string[];
  hasError?: boolean;
  width?: string;
  className?: string;
  style?: object;
};

const GridWrapper = styled.div<{ width: string }>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: ${({ width }) => width};

  ${down('md')} {
    width: unset;
  }

  ${down('sm')} {
    width: 370px;
  }

  & > div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .checkbox-item {
    box-shadow: none;
    margin: 5px;

    ${down('sm')} {
      flex: unset;
      width: 46%;
    }

    .checkbox-item-value {
      font-weight: bold;
    }

    img {
      border-radius: 10px;
      height: 122px;
      width: 122px;
      display: unset;
    }
  }

  label {
    min-width: 100px;
    display: flex;
    justify-items: center;
    flex-direction: column;
  }
`;

export const GridWithImagesChoicesSelector: FC<GridWithImagesChoicesSelectorProps> = memo(
  ({ name, items, value, hasError, width = '900px', className, style }) => {
    return (
      <GridWrapper width={width} data-error-field={name} className={className} style={style}>
        <Field
          name={name}
          items={items}
          wrapperClass="step-input"
          component={ItemsCheckbox}
          value={value}
          hasError={hasError}
        />
      </GridWrapper>
    );
  },
);
