import bluePond from '../../../../public/static/images/blue-pond.png';
import firstMonster from '../../../../public/static/images/monster-1.png';
import secondMonster from '../../../../public/static/images/monster-2.png';
import thirdMonster from '../../../../public/static/images/monster-3.png';
import pinkPond from '../../../../public/static/images/pink-pond.png';

export enum StepId {
  SelfInfo = 'self',
  Kid = 'kid',
  KidSizes = 'kidSizes',
  Preppy = 'preppy',
  Euro = 'euro',
  Hipster = 'hipster',
  Kiddie = 'kiddie',
  Boho = 'boho',
  Glam = 'glam',
  Seasons = 'seasons',
  Items = 'items',
  HatedColors = 'hatedColors',
  HatedMotifs = 'hatedMotifs',
  LikedBrands = 'likedBrands',
  AddKidStep = 'addKidStep',
  AlmostDone = 'almostDone',
  Password = 'password',
  ReviewOrder = 'reviewOrder',
  ShippingAddress = 'shippingAddress',
  PaymentInfo = 'paymentInfo',
  ConfirmOrder = 'confirmOrder',
  ThankYouStep = 'thankYouStep',
}

export const stepSequence = [
  StepId.SelfInfo,
  StepId.Kid,
  StepId.KidSizes,
  StepId.Preppy,
  StepId.Euro,
  StepId.Hipster,
  StepId.Kiddie,
  StepId.Boho,
  StepId.Glam,
  StepId.Seasons,
  StepId.Items,
  StepId.HatedColors,
  StepId.HatedMotifs,
  StepId.LikedBrands,
  StepId.AddKidStep,
  StepId.AlmostDone,
  StepId.Password,
  StepId.ReviewOrder,
  StepId.ShippingAddress,
  StepId.PaymentInfo,
  StepId.ConfirmOrder,
  StepId.ThankYouStep,
] as const;

export const stepIdToIndexMap = new Map(stepSequence.map((id, index) => [id, index] as const));
export const LAST_STEP_INDEX = stepSequence.length - 1;

export type SectionType = {
  start: number;
  end: number;
  text: string;
  image: {
    icon: string;
    underline: string;
  };
};

export const stepSections: SectionType[] = [
  {
    start: stepIdToIndexMap.get(StepId.SelfInfo),
    end: stepIdToIndexMap.get(StepId.Kid),
    text: 'Your Kiddo(s)',
    image: {
      icon: firstMonster,
      underline: pinkPond,
    },
  },
  {
    start: stepIdToIndexMap.get(StepId.KidSizes),
    end: stepIdToIndexMap.get(StepId.LikedBrands),
    text: 'Their Look',
    image: {
      icon: secondMonster,
      underline: bluePond,
    },
  },
  {
    start: stepIdToIndexMap.get(StepId.AddKidStep),
    end: stepSequence.length,
    text: 'Checkout',
    image: {
      icon: thirdMonster,
      underline: bluePond,
    },
  },
];
