import { Field, useFormikContext } from 'formik';
import React, { FC } from 'react';
import styled from 'styled-components';
import SelectField from '../../../../../components/SelectField';
import { clothesBudget, numItems, outfitBudget, shoesBudget } from '../../../../../constants/budget';
import { clothesSizes, shoesSizes } from '../../../../../constants/sizes';
import { StepId } from '../../../constants/steps';
import { useOnCompleteHookProvider, useQuizValues } from '../../../context/QuizDataContext';
import hasErrorFactory from '../../../utils/hasErrorFactory';
import { StepBodyWrapper, StepHeader, StepSubHeader, StepWrapper } from '../../StepLayout';

export const FutureBirthDateNotice = styled.p`
  margin-top: 0.5rem;
  font-size: 12px;
  text-align: center;
  font-style: italic;
`;

export const KidInfoSizes: FC = () => {
  const allStepsValues = useQuizValues();
  const kidName = allStepsValues[StepId.SelfInfo] && allStepsValues[StepId.SelfInfo].name;

  const { values, errors, submitCount, touched } = useFormikContext<any>();
  const hasError = hasErrorFactory(submitCount, touched, errors);

  return (
    <StepWrapper>
      {kidName && <StepHeader>Tell us more about {kidName}</StepHeader>}
      <StepSubHeader>Sizes</StepSubHeader>
      <StepBodyWrapper>
        <Field
          name="top_size"
          label="Top Size:"
          labelWrapperClass="step-label"
          wrapperClass="step-input"
          placeholder="Select size"
          options={clothesSizes}
          component={SelectField}
          value={values.top_size}
          hasError={hasError('top_size')}
        />
        <Field
          name="top_price"
          label="Budget for tops:"
          labelWrapperClass="step-label"
          wrapperClass="step-input"
          placeholder="Select budget"
          options={clothesBudget}
          component={SelectField}
          value={values.top_price}
          hasError={hasError('top_price')}
        />
        <Field
          name="bottom_size"
          label="Bottom Size:"
          labelWrapperClass="step-label"
          wrapperClass="step-input"
          placeholder="Select size"
          options={clothesSizes}
          component={SelectField}
          value={values.bottom_size}
          hasError={hasError('bottom_size')}
        />
        <Field
          name="bottom_price"
          label="Budget for bottoms:"
          labelWrapperClass="step-label"
          wrapperClass="step-input"
          placeholder="Select budget"
          options={clothesBudget}
          component={SelectField}
          value={values.bottom_price}
          hasError={hasError('bottom_price')}
        />
        <Field
          name="shoes_size"
          label="Shoe Size:"
          labelWrapperClass="step-label"
          wrapperClass="step-input"
          placeholder="Select size"
          options={shoesSizes}
          component={SelectField}
          value={values.shoes_size}
          hasError={hasError('shoes_size')}
        />
        <Field
          name="shoes_price"
          label="Budget for shoes:"
          labelWrapperClass="step-label"
          wrapperClass="step-input"
          placeholder="Select budget"
          options={shoesBudget}
          component={SelectField}
          value={values.shoes_price}
          hasError={hasError('shoes_price')}
        />
        <Field
          name="outfit_price"
          label="Budget for special occasion outfit:"
          labelWrapperClass="step-label"
          wrapperClass="step-input"
          placeholder="Select budget"
          subLabel="{excluding shoes}"
          options={outfitBudget}
          component={SelectField}
          value={values.outfit_price}
          hasError={hasError('outfit_price')}
        />
        <hr />
        <Field
          name="num_items"
          label="How many items would you like us to include in your Drop?"
          labelWrapperClass="step-label"
          wrapperClass="step-input"
          placeholder="Select how many items"
          options={numItems}
          component={SelectField}
          value={values.num_items}
          hasError={hasError('num_items')}
          style={{ margin: 0 }}
        />
      </StepBodyWrapper>
    </StepWrapper>
  );
};

export const useOnComplete = () => {
  const { updateKid, currentKidId } = useOnCompleteHookProvider();
  return async (values: {
    top_size: string;
    bottom_size: string;
    shoes_size: string;
    top_price: string;
    bottom_price: string;
    shoes_price: string;
    outfit_price: string;
    num_items: string;
  }) => {
    return updateKid(currentKidId as string, {
      ...values,
    });
  };
};
