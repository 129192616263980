import specialBannerLele from '../../../public/static/images/lele-sadoughi-gate-banner.jpg';
import specialBannerRylee from '../../../public/static/images/rylee-surprise-drop-052020.jpg';
import specialBannerMilesMilan from '../../../public/static/images/miles-milan-blm-signin.jpg';
import specialBannerVilebrequin from '../../../public/static/images/vilebrequin.jpg';
import specialBanner4thJuly from '../../../public/static/images/4th-july-2020-final-sale-gate.jpg';
import specialBannerRagsToRaches from '../../../public/static/images/rags-to-raches-072020-banner.jpg';
import specialBannerGrayLabelSale2 from '../../../public/static/images/gray-label-sale-signin-202008.jpg';
import specialBannerHowloween from '../../../public/static/images/cubcoats-gate-banner.gif';
import specialBannerBonpoint from '../../../public/static/images/non-subscribers-surprise-drop.jpg';
import specialSummerSale from '../../../public/static/images/0821-summer-sale-gate-banner.jpg';
import holidayShopEvent from '../../../public/static/images/holiday-shop-gate-banner.png';
import blackFriday from '../../../public/static/images/black-friday-shop-gate-banner.jpg';
import ciberMonday from '../../../public/static/images/cyber-monday-shop-gate-banner.jpg';
import specialOcassion from '../../../public/static/images/special_occassion_sale_gate_banner.jpg';
import cozyEssentials from '../../../public/static/images/cozy_essentials_gate_banner.jpg';
import ryleeCruBanner from '../../../public/static/images/rylee__cru_shop_gate_banner.png';

export const eventBanners = [
  {
    name: 'Lele Sadoughi Sale',
    route: '/event/lele-sadoughi',
    url: specialBannerLele,
  },
  {
    name: 'The Mother Lode',
    route: '/event/rylee-cru-mother-lode',
    url: specialBannerRylee,
  },
  {
    name: 'Miles and Milan / BLM',
    route: '/event/miles-milan-blm',
    url: specialBannerMilesMilan,
  },
  {
    name: 'Vilebrequin',
    route: '/event/vilebrequin',
    url: specialBannerVilebrequin,
  },
  {
    name: '4th of July',
    route: '/event/4th-of-july',
    url: specialBanner4thJuly,
  },
  {
    name: 'Rags to Raches',
    route: '/event/rags-to-raches',
    url: specialBannerRagsToRaches,
  },
  {
    name: 'Gray Label',
    route: '/event/GrayLabelFallSurprise',
    url: specialBannerGrayLabelSale2,
  },
  {
    name: 'Howl-oween',
    route: '/event/howl-oween',
    url: specialBannerHowloween,
  },
  {
    name: 'Bonpoint',
    route: '/event/bonpoint',
    url: specialBannerBonpoint,
  },
  {
    name: 'Summer Sale',
    route: '/event/summer-sale',
    url: specialSummerSale,
  },
  {
    name: 'Holiday Gift Shop',
    route: '/event/holiday-gift-shop',
    url: holidayShopEvent,
  },
  {
    name: 'Black Friday Sale',
    route: '/event/black-friday-sale',
    url: blackFriday,
  },
  {
    name: 'Cyber Monday',
    route: '/event/cyber-monday-mystery-bundle',
    url: ciberMonday,
  },
  {
    name: 'Special Ocassion',
    route: '/event/special-occasion-shop',
    url: specialOcassion,
  },
  {
    name: 'Cozy Essentials',
    route: '/event/cozy-essentials',
    url: cozyEssentials,
  },
  {
    name: 'Rylee + Cru',
    route: '/event/ryleecru-surprise-drop',
    url: ryleeCruBanner,
  },
];
