import { useFormikContext } from 'formik';
import { useRouter } from 'next/router';
import React, { FC, MouseEvent, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useOnCompleteHookProvider, useUpdateSelfInfo } from '../../../context/QuizDataContext';
import { StepBodyWrapper, StepHeader, StepWrapper } from '../../StepLayout';
import KiddoIcon from '../../../../../../public/static/images/dashboard/kids/kiddo3.png';
import ThankYouLeftImage from '../../../../../../public/static/images/dashboard/kids/thank-you-page-left.png';
import ThankYouRightImage from '../../../../../../public/static/images/dashboard/kids/thank-you-page-right.png';
import { getWishlistUrl } from '../../../../../utils/wishlistUrls';

const StyledBodyWrapper = styled(StepBodyWrapper)`
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  && {
    width: 100% !important;
    min-width: auto !important;
    max-width: 1000px !important;
  }

  > div {
    width: 100% !important;
    min-width: auto !important;
    max-width: 1000px !important;
  }
`;

const ContentDiv = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  && {
    width: 100% !important;
    min-width: auto !important;
  }
`;

const IconImage = styled.img`
  height: 50px;
  display: block;
  margin: 10px auto;
`;

const SecondHeader = styled.h2.attrs({ className: 'heading-s' })`
  font-size: 2rem;
  margin: 20px 0;
  text-align: center;
`;

const UnderlinedText = styled.span`
  text-decoration: underline;
`;

const BoldText = styled.span`
  font-weight: bold;
`;

const ImagesContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 20px auto;
  width: 100%;
  max-width: 1000px;
`;

const ThankYouImage = styled.img`
  height: 325px;
  width: auto;
  object-fit: contain;

  @media (min-width: 2000px) {
    height: 409px;
  }

  @media (max-width: 1440px) {
    height: 290px;
  }

  @media (max-width: 1024px) {
    height: 250px;
  }

  @media (max-width: 768px) {
    height: 200px;
  }
`;

const ActionButton = styled.button`
  background: black;
  color: white;
  padding: 15px 30px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin: 20px 0 20px;
  width: auto;
  min-width: 200px;

  &:hover {
    opacity: 0.9;
  }
`;

const NoThanksLink = styled.button`
  background: none;
  border: none;
  color: #666;
  text-decoration: underline;
  cursor: pointer;
  padding: 5px 0 10px;
  width: auto;

  &:hover {
    color: #333;
  }
`;

type FormValues = {
  hear_about_us: string;
  other?: string;
};

export const ThankYouStep: FC = () => {
  const { values } = useFormikContext<FormValues>();
  const router = useRouter();
  const updateSelfInfo = useUpdateSelfInfo();

  const handleClick = useCallback(
    async (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      await updateSelfInfo({
        hear_about_us: (values.hear_about_us === 'Other' && values.other) || values.hear_about_us,
      });
      window.location.href = '/dashboard/orders';
    },
    [values.hear_about_us, values.other, updateSelfInfo],
  );

  const handleBuildWishlist = async () => {
    try {
      const url = await getWishlistUrl();
      window.open(url, '_blank', 'noopener,noreferrer');
    } catch (error) {
      console.error('Failed to open wishlist:', error);
    }
  };

  useEffect(() => {
    window?.scrollTo(0, 0);
  }, [router?.pathname]);

  return (
    <StepWrapper>
      <StepHeader>Yippee!</StepHeader>
      <StyledBodyWrapper>
        <ContentDiv>
          <p>Thank you for your order!</p>
          <IconImage src={KiddoIcon} alt="kiddo" />

          <SecondHeader>Let's make Gifting Easy!</SecondHeader>

          <p style={{ marginBottom: '30px' }}>
            <BoldText>Did you know?</BoldText> You can create a <BoldText>wishlist</BoldText> for your kiddo's next
            birthday or special occasion!
          </p>

          <ImagesContainer>
            <ThankYouImage src={ThankYouLeftImage} alt="thank you left" />
            <ThankYouImage src={ThankYouRightImage} alt="thank you right" />
          </ImagesContainer>

          <ActionButton onClick={handleBuildWishlist}>BUILD WISHLIST NOW</ActionButton>

          <NoThanksLink onClick={handleClick}>No Thanks, View Order</NoThanksLink>
        </ContentDiv>
      </StyledBodyWrapper>
    </StepWrapper>
  );
};

export const useOnComplete = () => {
  useOnCompleteHookProvider();
  return () => null;
};
