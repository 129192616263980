import React, { FC, useMemo } from 'react';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { STYLE_FEE } from '../../../constants';
import { useLoadedKids, useOnCompleteHookProvider, useQuiz, useSelfInfo } from '../../../context/QuizDataContext';
import { StepBodyWrapper, StepWrapper } from '../../StepLayout';
import { StepHeader } from '../../StepLayout/v2';

const StyledStepBodyWrapper = styled(StepBodyWrapper)`
  align-items: stretch;
  font-size: 0.875rem;
  padding: 1rem 0.5rem 2rem;
  row-gap: 0.75rem;

  ${up('lg')} {
    font-size: 1rem;
    padding: 2rem 0;
  }

  del {
    text-decoration-thickness: 2px;
  }
  ins {
    margin-left: 0.5rem;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 800;
    color: ${p => p.theme.colors.warning};
    filter: brightness(0.85);
  }
`;
const ItemRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
const Hr = styled.hr`
  width: 100%;
  margin: 0;
  border: none;
  border-top: solid 1px ${p => p.theme.colors.borderColorDarker};
`;
const TotalRow = styled(ItemRow)`
  font-weight: 800;
`;

export const ReviewOrder: FC = () => {
  const loadedKids = useLoadedKids();
  const { selectedKids: selectedKidIds } = useQuiz();
  const selectedKids = useMemo(() => loadedKids.filter(k => selectedKidIds.includes(k.id)), [
    loadedKids,
    selectedKidIds,
  ]);
  const { has_quiz_completed } = useSelfInfo();
  const totalFee = `$${STYLE_FEE * selectedKids.length}`;

  return (
    <StepWrapper className="font-nunito">
      <StepHeader>Review your order</StepHeader>
      <StyledStepBodyWrapper>
        <section>
          {selectedKids.map(k => (
            <ItemRow key={k.id}>
              <span>Drop for {k.name}</span>
              <span>x1</span>
            </ItemRow>
          ))}
        </section>
        <section>
          <ItemRow>
            <span>Today's styling fee:</span>
            <span>
              {has_quiz_completed ? (
                totalFee
              ) : (
                <>
                  <del>{totalFee}</del>
                  <ins>$0</ins>
                </>
              )}
            </span>
          </ItemRow>
          <ItemRow>
            <span>Shipping fee:</span>
            <span>FREE</span>
          </ItemRow>
        </section>
        <Hr />
        <TotalRow>
          <span>Total:</span>
          <span>{has_quiz_completed ? totalFee : '$0'}</span>
        </TotalRow>
        <div>
          {has_quiz_completed
            ? "Purchase anything from your Drop, and we'll credit the styling fee back to you."
            : 'Styling fee waived on your first box.'}
        </div>
      </StyledStepBodyWrapper>
    </StepWrapper>
  );
};

export const useOnReviewOrderComplete = () => {
  const { currentKidId, updateKid } = useOnCompleteHookProvider();

  return () => updateKid(currentKidId, {});
};
