import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import useCountDown from '../../../hooks/useCountDown';
import { confirmQuizRecoveryCode, requestQuizRecoveryCode } from '../../../services/auth';
import jsCookie from 'js-cookie';
import * as Sentry from '@sentry/browser';
import useQueryParams from '../../../hooks/useQueryParams';
import { queryParamsToString } from '../../../utils/redirect';
import queryString, { extract } from 'query-string';
import { pathOr } from 'ramda';
import { cookieDomain, tokenCookieName } from '../../../constants';
import storage from '../../../utils/storage';
import { QUIZ_TYPE } from '../containers/SignUpPage';

type State = {
  isSubmitting: boolean;
  error?: string;
};

function useQuizRecovery() {
  const [state, setState] = useState<State>({ isSubmitting: false });
  const { query } = useQueryParams(['error', 'email', 'code']);
  const { restart, remaining } = useCountDown({ initStart: 9 });
  const { push, asPath } = useRouter();
  const returnTo = storage.retrieve(QUIZ_TYPE);

  // avoid encoded email issues
  const email = useMemo(() => {
    const parsedParams = queryString.parse(extract(decodeURIComponent(asPath)), { decode: false });
    return pathOr('', ['email'], parsedParams);
  }, [asPath]);

  const requestCodeHandler = useCallback(async () => {
    if (!email) return;
    restart();
    try {
      await requestQuizRecoveryCode(email);
    } catch (e) {
      if (e?.response?.status === 400) {
        setState({ error: 'Email not found.', isSubmitting: false });
      } else {
        setState({ error: 'An error occurred while trying to request the recovery code.', isSubmitting: false });
        Sentry.captureException(e);
      }
    }
  }, [email]);

  useEffect(() => {
    requestCodeHandler();
  }, [email]);

  const recoverQuizHandler = useCallback(async values => {
    try {
      setState({ error: undefined, isSubmitting: true });
      const response = await confirmQuizRecoveryCode(values.email, values.code);
      const cookieOptions = cookieDomain ? { domain: cookieDomain } : undefined;
      jsCookie.set(tokenCookieName, response.token, cookieOptions);
      push(`${returnTo}/${queryParamsToString(query)}`);
      storage.remove(QUIZ_TYPE);
      setState({ error: undefined, isSubmitting: false });
    } catch (e) {
      setState({ error: undefined, isSubmitting: false });
      if (e?.response?.status === 400) {
        setState({ error: 'The code is invalid or has expired. Please request a new one.', isSubmitting: false });
      } else {
        setState({ error: 'An error occurred while trying to recover the account.', isSubmitting: false });
        Sentry.captureException(e);
      }
    }
  }, []);

  const goBackHandler = useCallback(() => push('/sign-up', '/sign-up', { shallow: true }), []);

  return {
    goBackHandler,
    recoverQuizHandler,
    confirmQuizRecoveryCode,
    requestCodeHandler,
    email,
    remaining,
    ...state,
  };
}

export default useQuizRecovery;
