import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import LoadingAnimation from '../../../../components/LoadingAnimation';
import { useLocalStorageState } from '../../../../hooks/useLocalStorageState';
import useQuizType from '../../../../hooks/useQuizType';
import useRedirectIfLogged from '../../../../hooks/useRedirectIfLogged';
import { logFBEvent } from '../../../../utils/analytics';
import storage from '../../../../utils/storage';
import { STORED_KIDS_KEY } from '../../../bonpoint-quiz/context';
import BonpointSignupHeader from '../../components/BonpointSignupHeader';
import QuizRecoveryForm from '../../components/QuizRecoveryForm';
import SignUpForm from '../../components/SignUpForm';
import SignUpHeader from '../../components/SignUpHeader';
import useRedirectToShop from '../../hooks/useRedirectToShop';

const Container = styled.div<{ isBonpointQuiz: boolean }>`
  background: rgb(255, 255, 255);
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 40%, rgba(238, 243, 243, 1) 50%);
  ${({ isBonpointQuiz, theme }) =>
    isBonpointQuiz &&
    `
   background: ${theme.colors.paper}
  `}
`;

export const QUIZ_TYPE = 'quiz_type';

const SignUpPage = () => {
  const {
    query: { gift_code: giftCode, error },
  } = useRouter();
  useRedirectIfLogged();
  const { isRedirecting } = useRedirectToShop();
  const { isBonpointQuiz } = useQuizType();
  const [storedQuizType, setStoredQuizType] = useLocalStorageState<string>(QUIZ_TYPE, null);

  useEffect(() => {
    // TODO seed gift code from query string
    // TODO this may be a show stopper until resolved!
    // - but where does it go now?
    // const { gift_code: giftCode } = qs.parse(location.search);
    //
    // if (giftCode) {
    //   setGiftDropCode(giftCode);
    // }

    if (giftCode) {
      // TODO now what?
    }

    logFBEvent('trackCustom', 'EmailSignup');
    // set quiz type in cache
    if (!storedQuizType) {
      setStoredQuizType(isBonpointQuiz ? '/quiz/bonpoint' : '/quiz');
    }
    // clear the bonpoint quiz cache
    storage.remove(STORED_KIDS_KEY);
  }, []);

  if (isRedirecting) {
    return <LoadingAnimation />;
  }

  return (
    <Container isBonpointQuiz={isBonpointQuiz}>
      {isBonpointQuiz ? <BonpointSignupHeader /> : <SignUpHeader />}
      {error === 'INCOMPLETE_QUIZ' ? <QuizRecoveryForm /> : <SignUpForm />}
    </Container>
  );
};

export default SignUpPage;
