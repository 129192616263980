import client from '../../../../utils/api-client';
import { FeeDiscounts } from '../../context/CreateOrder';

interface OrderData {
  kount_session_id?: string;
  kids?: string[];
  kid?: string;
  coupon: string | null;
  gift_code?: string | null;
  is_single: boolean;
  impact_irclickid?: string;
  kid_style?: KidStyle;
}

type KidStyle = {
  kid: string;
  coupon: string;
  bottom_size: string;
  top_size: string;
  items: string[];
  notes: string;
  seasons: string[];
  shoes_size: string;
};

export type OrderResponse = {
  is_signup: boolean;
  orders: Array<{ id: string }>;
};

export const createOrder = (data: OrderData) =>
  client<OrderResponse>('api/drop-orders/request-quiz', { body: data, method: 'POST' });

export const createSingleDrop = (data: OrderData) =>
  client<OrderResponse>('api/drop-orders/request-single-drop', { body: data, method: 'POST' });

export const feeDiscount = (data: OrderData) =>
  client<FeeDiscounts>('api/drop-orders/quiz-discount', { body: data, method: 'POST' });
