import React, { FC, memo } from 'react';
import styled from 'styled-components';
import { Field } from 'formik';
import ItemsCheckbox from '../../../../components/ItemsCheckbox';
import { Choice } from '../../../../types';

const VerticalChoisesWrapper = styled.div`
  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    & > label {
      width: 280px;
    }
  }

  .checkbox-item {
    margin-bottom: 10px;
  }
`;

type VerticalChoicesProps = {
  name: string;
  items: Choice[];
  value?: string[];
  hasError?: boolean;
};

export const VerticalChoicesSelector: FC<VerticalChoicesProps> = memo(({ name, items, value, hasError }) => {
  return (
    <VerticalChoisesWrapper data-error-field={name} data-testid="vertical-choices-selector">
      <Field
        name={name}
        items={items}
        wrapperClass="step-input"
        component={ItemsCheckbox}
        value={value}
        hasError={hasError}
      />
    </VerticalChoisesWrapper>
  );
});
