import { Theme } from '../theme';
import { useTheme as useStyledTheme } from 'styled-components';

/**
 * Wrapper in order to export the custom theme interface
 */
const useTheme = (): Theme => {
  return useStyledTheme() as Theme;
};

export default useTheme;
