import storage from '../../../utils/storage';
import { stepIdToStepMap } from '../constants/stepIdToStepMap';
import { stepSequence } from '../constants/steps';
import { IQuizValues } from '../context/QuizDataContext';
import { KidDataResponse } from '../services/KidService';
import { MeDataResponse } from '../services/MeService';
import { QuizStorageKey } from '../types';

const assignQuizValuesFromList = (initialValues: IQuizValues, data?: KidDataResponse | MeDataResponse | null) => {
  const flatMapOfFieldsFromStepBundles = initialValues;
  // iterate over steps sequence and assign fields to steps from api response
  for (const stepId of stepSequence) {
    flatMapOfFieldsFromStepBundles[stepId] = flatMapOfFieldsFromStepBundles[stepId] || {};
    if (!data) continue;

    const { fields } = stepIdToStepMap.get(stepId).validationSchema;
    for (const fieldName of Object.keys(fields)) {
      flatMapOfFieldsFromStepBundles[stepId][fieldName] = data[fieldName as keyof (KidDataResponse | MeDataResponse)];
    }
  }
  return flatMapOfFieldsFromStepBundles;
};

/**
 * Save the needed step data in order to allow continue the quiz in the same step in case of abandon it
 */
const storageCurrentStepData = (key: QuizStorageKey, value) => {
  storage.set(key, value);
};

/**
 * Get saved data of the last step
 */
const retrieveStoredStepData = (): { lastKidName: string; lastSelectedKids: string[] } => {
  if (typeof window === 'undefined') {
    return { lastKidName: '', lastSelectedKids: [] };
  }

  return {
    lastKidName: storage.retrieve(QuizStorageKey.LAST_KID_NAME),
    lastSelectedKids: storage.retrieve(QuizStorageKey.LAST_SELECTED_KIDS),
  };
};

/**
 * Allow restart the quiz in the first step
 */
const clearStoredStepData = () => {
  storage.remove(QuizStorageKey.LAST_KID_NAME);
  storage.remove(QuizStorageKey.LAST_SELECTED_KIDS);
};

/**
 * Allow restart the quiz in the first step
 */
const clearStoredCurrentKid = kidId => {
  storage.remove(QuizStorageKey.LAST_KID_NAME);
  const { lastSelectedKids } = retrieveStoredStepData();
  const newlastSelectedKidsId = lastSelectedKids ? [...lastSelectedKids.filter(id => id !== kidId)] : [];
  storageCurrentStepData(QuizStorageKey.LAST_SELECTED_KIDS, newlastSelectedKidsId);
};

const shortOrdinalNames = n => `${n}${[, 'st', 'nd', 'rd'][(n / 10) % 10 ^ 1 && n % 10] || 'th'}`;

export {
  assignQuizValuesFromList,
  storageCurrentStepData,
  retrieveStoredStepData,
  clearStoredStepData,
  clearStoredCurrentKid,
  shortOrdinalNames,
};
