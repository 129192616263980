import { clone, pathOr } from 'ramda';
import client from '../../../../utils/api-client';
import { IQuizValues } from '../../context/QuizDataContext';
import { assignQuizValuesFromList } from '../../utils';

export interface KidDataResponse {
  name?: string;
  date_of_birth?: string;
  gender?: string;
  top_size?: string;
  top_price?: string;
  bottom_size?: string;
  bottom_price?: string;
  shoes_size?: string;
  shoes_price?: string;
  outfit_price?: string;
  quality?: string;
  preppy?: string;
  euro?: string;
  hipster?: string;
  kiddie?: string;
  boho?: string;
  glam?: string;
  dont_dislike_colors?: boolean;
  dont_dislike_motifs?: boolean;
  items?: string[];
  colors_liked?: string[];
  colors_hated?: string[];
  motifs_liked?: string[];
  motifs_hated?: string[];
  preferred_brands?: string[];
  holidays?: string[];
  id?: string;
  last_quiz_step?: number; // but numerical
  is_quiz_completed?: boolean;
  has_active_subscription?: boolean;
  gender_preference?: string;
  instagram_id?: string;
  age?: string;
  next_drop?: any;
  modified?: string;
  seasons?: string[];
  editable?: boolean;
  additional_holidays?: string;
  notes?: string;
  current_drop_status?: string;
  num_items?: string; // but numerical
  quiz_type?: string;
  quiz_data?: any;
}

export const createKid: (name: string) => Promise<KidDataResponse> = name =>
  client<KidDataResponse>('api/kids/', { body: { name }, method: 'POST' });

export const updateKid: (id: string, data: KidDataResponse) => Promise<KidDataResponse> = (id, data) =>
  client<KidDataResponse>(`api/kids/${id}/`, { body: { ...data }, method: 'PATCH' });

export const loadAllKidsData: () => Promise<KidDataResponse[]> = () =>
  client<{ results: KidDataResponse[] }>('api/kids/').then(r => r.results);

export const nestFlatKidDataToSteps: (initialValues: IQuizValues, _data: KidDataResponse) => IQuizValues = (
  initialValues,
  _data,
) => {
  const data: any = clone(_data);
  if (pathOr(null, ['additional_holidays'], data) && pathOr(null, ['holidays'], data)) {
    data.holidays.push('other');
  }
  return assignQuizValuesFromList(initialValues, data);
};
