import { Field, useFormikContext } from 'formik';
import React, { FC } from 'react';
import styled from 'styled-components';
import { InferType, object, string } from 'yup';
import { InputField } from '../../../../../components/InputField';
import { minPasswordLength } from '../../../../../constants';
import { useOnCompleteHookProvider } from '../../../context/QuizDataContext';
import { FieldRow } from '../../FieldContainers';
import { StepBodyWrapper, StepWrapper } from '../../StepLayout';
import { ErrorMsg, StepHeader } from '../../StepLayout/v2';

const StyledStepWrapper = styled(StepWrapper)`
  margin-bottom: 2rem;
`;
const StyledFieldRow = styled(FieldRow)`
  width: 100%;
  margin-top: 0.5rem;

  label {
    font-weight: 600;
    line-height: normal;
  }
`;

const pwdSpclChars = '!@#$%^&',
  passwordHint = `Must be ${minPasswordLength}+ characters with at least one number, lowercase, uppercase, and symbol( ${pwdSpclChars} )`;

export const passwordSchema = (() => {
  const strSchema = string().required('This field is required');
  return object({
    password: strSchema.matches(
      new RegExp(`^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[${pwdSpclChars}]).{${minPasswordLength},}$`),
      passwordHint,
    ),
    password_confirmation: strSchema.test('passwords-match', 'Passwords do not match', function(value) {
      return this.parent.password === value;
    }),
  });
})();
type TSchema = InferType<typeof passwordSchema>;

export const PasswordStep: FC = () => {
  const { errors, touched, values } = useFormikContext();

  return (
    <StyledStepWrapper className="font-nunito">
      <StepHeader>Create your password</StepHeader>
      <StepBodyWrapper>
        {(['password', 'password_confirmation'] as const).map(fldName => {
          const error = touched[fldName] && errors[fldName];

          return (
            <StyledFieldRow key={fldName}>
              <Field
                name={fldName}
                label={fldName === 'password' ? 'Create a password' : 'Confirm your password'}
                labelWrapperClass="step-label"
                wrapperClass="step-input"
                placeholder={`Enter password${fldName === 'password' ? '' : ' again'}`}
                value={values[fldName]}
                showError={error}
                component={InputField}
                isTouchable
                type="password"
              />
              {fldName === 'password' && !error && <small>* {passwordHint}</small>}
              <ErrorMsg>{error}</ErrorMsg>
            </StyledFieldRow>
          );
        })}
      </StepBodyWrapper>
    </StyledStepWrapper>
  );
};

export const useOnPasswordComplete = () => {
  const { updateSelfInfo, updateKid, currentKidId } = useOnCompleteHookProvider();
  return async (values: TSchema) => {
    await updateSelfInfo(values);
    return updateKid(currentKidId, {}); // updates last_quiz_step
  };
};
