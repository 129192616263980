import React, { createContext, Dispatch, FC, ReactNode, Reducer, useContext, useEffect, useReducer } from 'react';
import { Action, ActionType, reducer } from './reducer';
import { useInitializeCheckoutContext } from './useInitializeCheckoutContext';
import { KidDataResponse } from '../../style-quiz/services/KidService';
import { useLocalStorageState } from '../../../hooks/useLocalStorageState';

export type KidsState = KidDataResponse[];

export type State = {
  isSubmitting: boolean;
  kids: KidsState;
  kidsLength: number;
  isFormClear: boolean;
  error?: string;
};

const initialState: State = {
  isSubmitting: false,
  kids: [],
  kidsLength: 0,
  isFormClear: false,
};

export const ShortQuizContext = createContext<{ state: State; dispatch: Dispatch<Action> }>({
  state: initialState,
  dispatch: () => null,
});

export const STORED_KIDS_KEY = 'BONPOINT_QUIZ_KIDS';

export const ShortQuizProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer as Reducer<State, Action>, { ...initialState });
  const [storedKids] = useLocalStorageState<KidsState>(STORED_KIDS_KEY, []);
  useInitializeCheckoutContext(state);

  // get kids from the storage in the first render, prevent get the old stored object
  useEffect(() => {
    dispatch({ type: ActionType.SET_KIDS, payload: Array.isArray(storedKids) ? storedKids : [] });
  }, []);

  return (
    <ShortQuizContext.Provider
      value={{
        state: {
          ...state,
        },
        dispatch,
      }}
    >
      {children}
    </ShortQuizContext.Provider>
  );
};

export const useShortQuizContext = () => {
  const context = useContext(ShortQuizContext);
  if (!context) {
    throw new Error('useShortQuizContext must be used within a ShortQuizContextProvider');
  }
  return context;
};
