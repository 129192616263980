import { FieldProps } from 'formik';
import { append, pathOr } from 'ramda';
import React, { useCallback, useEffect, useState } from 'react';
import { down } from 'styled-breakpoints';
import styled from 'styled-components';
import uniqid from 'uniqid';
import checkedAsset from '../../../public/static/images/checked.svg';
import uncheckedAsset from '../../../public/static/images/unchecked.svg';
import { Choice } from '../../types';

export interface ChoiceWithImage extends Choice {
  img: {
    src: string;
    alt: string;
  };
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  min-width: 648px;

  .checkbox-item {
    display: inline-block;
    border: 1px solid white;
    box-shadow: 1px 1px 0.5em silver;
    background: ${({ theme }) => theme.colors.white};
    border-radius: 10px;
    min-width: 157px;
    max-width: 280px;
    text-align: center;
    padding-top: 18px;
    cursor: pointer;

    img {
      height: 122px;
      border-radius: 4px;
    }
    input {
      clip: rect(0 0 0 0);
      overflow: hidden;
      position: absolute;
    }

    .checkbox-item-value {
      text-align: center;
      cursor: pointer;
      position: relative;
      padding: 5px 14px 24px 13px;
      font-size: 14px;

      ${down('sm')} {
        text-align: center;
        padding: 15px 10px;
        /*white-space: nowrap;*/
      }

      &::after {
        content: url(${uncheckedAsset});
        position: absolute;
        right: 5px;

        ${down('sm')} {
          content: unset;
        }
      }
    }

    &.selected {
      border: 1px solid black;

      .checkbox-item-value::after {
        content: url(${checkedAsset});
        position: absolute;
        right: 5px;

        ${down('sm')} {
          content: unset;
        }
      }
    }
  }

  ${down('sm')} {
    padding: 0;
    justify-content: center;
    align-content: center;
    width: 100%;
    min-width: unset;

    .checkbox-item {
      flex: 1;
      padding: 0;
      min-width: 157px;

      img {
        height: auto;
        display: block;
        width: 100%;
        padding: 14px;
        border-radius: 10%;
      }
    }
  }
`;

export interface CheckboxProps {
  name: string;
  item: Choice;
  checked?: boolean;
  onInputChange: (arg: any) => void;
}

export const Checkbox: React.FC<CheckboxProps> = props => {
  const { item, name, onInputChange, checked } = props;
  const id = uniqid('choice-');

  const handleChange = useCallback(
    (e: any) => {
      onInputChange(e.target.value);
    },
    [onInputChange],
  );

  return (
    <label aria-hidden={true} htmlFor={id} key={item.value} className={`checkbox-item ${checked ? 'selected' : ''} `}>
      <input id={id} name={name} type="checkbox" value={item.value} checked={checked} onChange={handleChange} />
      {(item as ChoiceWithImage).img && (
        <img src={(item as ChoiceWithImage).img.src} alt={(item as ChoiceWithImage).img.alt} />
      )}
      <div className="checkbox-item-value">{item.label}</div>
    </label>
  );
};

type ItemsCheckboxProps = {
  name: string;
  items: Array<Choice | ChoiceWithImage>;
  isRadiobox?: boolean;
  wrapperClass?: string;
  widthSm?: string;
  justifyC?: string;
  onChoiceChange?: (choice: Choice) => void;
};

const ItemsCheckbox: React.FC<ItemsCheckboxProps & FieldProps<any>> = props => {
  const { items, form, field, name, isRadiobox, wrapperClass = '', onChoiceChange } = props;
  const [choices, setChoices] = useState<Array<Choice | ChoiceWithImage>>([]);

  useEffect(() => {
    if (field?.name?.includes('[')) {
      const fieldNameFixed = field.name.replace('[', ',').replace('].', ',');
      const fieldName = fieldNameFixed.split(',');
      setChoices(pathOr([], fieldName, form.values));
    } else if (field?.name?.includes('.')) {
      const fieldName = field.name.split('.');
      setChoices(pathOr([], fieldName, form.values));
    } else {
      setChoices(pathOr([], [field.name], form.values));
    }
  }, [field.name]);

  const handleInput = useCallback(
    (choice: Choice) => {
      if (!isRadiobox) {
        const newChoices =
          choices.indexOf(choice) > -1
            ? choices.filter((value: Choice) => value !== choice)
            : append<Choice>(choice, choices);
        form.setFieldValue(field.name, newChoices);
        setChoices(newChoices);
      } else {
        form.setFieldValue(field.name, choice);
        setChoices([choice]);
      }
      onChoiceChange?.(choice);
    },
    [choices, form, field],
  );

  return (
    <Container className={wrapperClass}>
      {items?.map((item: Choice, index) => (
        <Checkbox
          key={index}
          name={name}
          item={item}
          onInputChange={handleInput}
          checked={isRadiobox ? item.value === field.value : ((field.value as string[]) || []).includes(item.value)}
        />
      ))}
    </Container>
  );
};

export default ItemsCheckbox;
