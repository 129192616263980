import React from 'react';
import { FaFacebookSquare as FacebookIcon } from 'react-icons/fa';
import SocialButton from './SocialButton';
import useTheme from '../../hooks/useTheme';
import styled from 'styled-components';

const StyledSocialButton = styled(SocialButton)`
  &:hover {
    background-color: ${({ theme }) => theme.colors.facebook};
  }
`;

const FacebookButton = props => {
  const { colors } = useTheme();

  return <StyledSocialButton {...props} icon={<FacebookIcon size={18} />} color={colors.facebook} />;
};

export default FacebookButton;
