import { down } from 'styled-breakpoints';
import styled from 'styled-components';
import ContainerWithLabel from '../../../../../components/ContainerWithLabel';
import theme from '../../../../../theme';

export const stripeInputStyles = {
  base: {
    fontSize: '16px',
    fontFamily: '"Soehne Breit", Arial, sans-serif',
    color: theme.colors.black,

    ['::placeholder']: {
      color: '#787679',
      fontSize: '12px',
    },
  },
};

export const StripeLogoContainer = styled.div`
  width: 100px;
  align-self: flex-end;
  & > img {
    width: 100px;
  }
`;

export const StripeInputContainer = styled(ContainerWithLabel)<{ hasError?: boolean }>`
  .StripeElement {
    min-height: 60px;
    border-radius: 4px;
    border: ${({ hasError, theme }) =>
      hasError ? `solid 1px ${theme.colors.primaryDanger}` : `solid 1px ${theme.colors.white}`};
    box-shadow: 0 2px 6px 0 rgba(94, 97, 100, 0.2);
    background-color: #ffffff;
    padding: 17px 16px;
    width: 100%;
    ${({ hasError }) =>
      !hasError &&
      `
        &:focus,
        &:hover {
          border-color: #cccccc;
        }
      `}
  }

  &.half {
    &:last-child {
      margin-left: 36px;
    }
  }

  ${down('sm')} {
    &.half {
      width: 100%;
      margin-top: 32px;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-left: 0;
      }
    }
  }
`;

export const fontsForStripe = [
  {
    cssSrc: 'https://fonts.googleapis.com/css?family=Merriweather|Montserrat:300,400,400i,500,700,700i',
  },
];
