import { captureException } from '@sentry/browser';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import Alert, { AlertType } from '../../../../../components/Alert';
import { useRetainedUrlParams } from '../../../../../contexts/RetainedUrlParamsContext';
import { useCtx } from '../../../../../utils';
import { STYLE_FEE } from '../../../constants';
import { useStepInjectorSetBeforeSubmit } from '../../../containers/StepInjector/context';
import { CheckableCodeContextProvider } from '../../../context/CheckableCode';
import {
  ActionTypes,
  CreateOrderContext,
  CreateOrderProvider,
  useFeeDiscount,
  useOrderErrorMessage,
} from '../../../context/CreateOrder';
import { useLoadedKids, useOnCompleteHookProvider, useQuiz, useSelfInfo } from '../../../context/QuizDataContext';
import { decodeErrorResponse } from '../../../utils/decodeErrorResponse';
import { StepBodyWrapper, StepWrapper } from '../../StepLayout';
import { ErrorMsg, StepHeader } from '../../StepLayout/v2';
import { CheckableCode } from '../ShippingAndBillingAndConfirmStep/CheckableCode';

const StyledStepBodyWrapper = styled(StepBodyWrapper)`
  align-items: stretch;
  font-size: 0.875rem;
  padding: 1rem 0.5rem 2rem;
  row-gap: 0.75rem;

  ${up('lg')} {
    font-size: 1rem;
    padding: 2rem 0;
  }

  del {
    text-decoration-thickness: 2px;
  }
  ins {
    margin-left: 0.5rem;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 800;
    color: ${p => p.theme.colors.warning};
    filter: brightness(0.85);
  }
`;
const ItemRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
const Hr = styled.hr`
  width: 100%;
  margin: 0;
  border: none;
  border-top: solid 1px ${p => p.theme.colors.borderColorDarker};
`;
const TotalRow = styled(ItemRow)`
  font-weight: 800;
`;
const CouponWrapper = styled.div`
  h3 {
    color: inherit;
    font-size: inherit;
    font-weight: 800;
    margin-top: 0;
  }
  & > :nth-child(2) {
    align-items: stretch;
  }
  .step-input {
    margin: 0;

    label {
      margin: 0;
    }

    input {
      min-height: 0;
      padding: 0.75rem;
    }
  }
  button {
    margin-top: 0;
    height: 100%;
  }
`;
const StyledAlert = styled(Alert)`
  margin: 0;
`;

const Note = styled.p`
  color: gray;
  margin-top: 1rem;
  font-size: 0.825rem;
`;

const _ConfirmOrder: FC = () => {
  const loadedKids = useLoadedKids();
  const { selectedKids: selectedKidIds } = useQuiz();
  const selectedKids = useMemo(() => loadedKids.filter(k => selectedKidIds.includes(k.id)), [
    loadedKids,
    selectedKidIds,
  ]);
  const { has_quiz_completed } = useSelfInfo();
  const [validatedCoupon, setValidatedCoupon] = useState<string | null>(null);
  const autoWaiveServiceFee = !has_quiz_completed && !validatedCoupon;
  const { feeDiscount, isLoadingFee, feeDiscountError, fetchFeeDiscount } = useFeeDiscount();

  const serviceFee = useMemo(() => {
    const discount = feeDiscount.total_discount;
    const subtotal = STYLE_FEE * selectedKids.length;
    const total = autoWaiveServiceFee ? 0 : Math.max(0, subtotal - discount);

    return { subtotal, total, discount };
  }, [selectedKids.length, feeDiscount.total_discount, autoWaiveServiceFee]);
  useEffect(() => {
    fetchFeeDiscount(validatedCoupon, null, false);
  }, [validatedCoupon]);

  const {
    state: { irclickid: impactIrClickId = '' },
  } = useRetainedUrlParams();
  const { createOrder, dispatch } = useCtx(CreateOrderContext);
  const handleBeforeSubmit = useCallback(async () => {
    if (isLoadingFee || feeDiscountError) return;

    try {
      await createOrder(validatedCoupon, null, false, !has_quiz_completed, impactIrClickId);
      dispatch({ type: ActionTypes.CreateOrderSuccess });
    } catch (error) {
      const errorMessage = await decodeErrorResponse(
        error?.response,
        undefined,
        errJson => Object.values<string | string[]>(errJson)[0],
      );
      dispatch({ type: ActionTypes.CreateOrderFailed, error: errorMessage });

      captureException(error, s =>
        s
          .setTag('QUIZ', 'CREATE_ORDER')
          .setExtra('coupon', validatedCoupon)
          .setExtra('errorMsg', errorMessage),
      );

      return;
    }

    return false; // prevent stepInjector from increasing step index since createOrder already does that
  }, [isLoadingFee, feeDiscountError, validatedCoupon, has_quiz_completed, impactIrClickId]);
  useStepInjectorSetBeforeSubmit(handleBeforeSubmit);

  const orderError = useOrderErrorMessage();

  return (
    <StepWrapper className="font-nunito">
      <StepHeader>Confirm Order</StepHeader>
      <StyledStepBodyWrapper>
        <section>
          {selectedKids.map(k => (
            <ItemRow key={k.id}>
              <span>Drop for {k.name}</span>
              <span>x1</span>
            </ItemRow>
          ))}
        </section>
        <section>
          <ItemRow>
            <span>Today's styling fee:</span>
            <span>
              {autoWaiveServiceFee ? (
                <>
                  <del>${serviceFee.subtotal}</del>
                  <ins>$0</ins>
                </>
              ) : (
                `$${serviceFee.subtotal}`
              )}
            </span>
          </ItemRow>
          {serviceFee.discount ? (
            <ItemRow>
              <span>Promo code discount:</span>
              <ErrorMsg>-${serviceFee.discount}</ErrorMsg>
            </ItemRow>
          ) : (
            ''
          )}
          <ItemRow>
            <span>Shipping fee:</span>
            <span>FREE</span>
          </ItemRow>
        </section>
        <Hr />
        <TotalRow>
          <span>Total:</span>
          <span>${serviceFee.total}</span>
        </TotalRow>
        <div>
          {autoWaiveServiceFee
            ? 'Styling fee waived on your first box.'
            : serviceFee.total
            ? "Purchase anything from your Drop, and we'll credit the styling fee back to you."
            : ''}
        </div>
        <Hr />

        <CouponWrapper>
          {(!validatedCoupon || feeDiscountError || isLoadingFee) && <h3>Apply Promo Code</h3>}
          <CheckableCodeContextProvider type="coupons">
            <CheckableCode responseSetter={(coupon: { id: string } | null) => setValidatedCoupon(coupon?.id ?? null)} />
          </CheckableCodeContextProvider>
        </CouponWrapper>

        <Note>
          A temporary hold will be placed on your card after this transaction to verify funds before you receive your
          drop. This will be released and not show up on your credit statement.
        </Note>
        {orderError && (
          <StyledAlert type={AlertType.ERROR}>
            <p>
              <strong>Failed to place order</strong> <br />
              {orderError}
            </p>
          </StyledAlert>
        )}
      </StyledStepBodyWrapper>
    </StepWrapper>
  );
};

export const ConfirmOrder: FC = () => (
  <CreateOrderProvider>
    <_ConfirmOrder />
  </CreateOrderProvider>
);

export const useOnConfirmOrderComplete = () => {
  useOnCompleteHookProvider();

  return async () => null;
};
