import { captureException } from '@sentry/browser';
import { Field, useFormikContext } from 'formik';
import { equals } from 'ramda';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { boolean, InferType, object, string } from 'yup';
import { Checkbox } from '../../../../../components/CheckboxField';
import { InputField } from '../../../../../components/InputField';
import { PhoneInputField } from '../../../../../components/PhoneInputField';
import SelectField from '../../../../../components/SelectField';
import states from '../../../../../constants/states';
import { verifyAddress } from '../../../../../services/address';
import { Address } from '../../../../../types';
import VerifyAddressModal, { notFoundAddress } from '../../../../sale-event/components/Checkout/VerifyAddressModal';
import { useStepInjectorSetBeforeSubmit } from '../../../containers/StepInjector/context';
import {
  useCreateAddress,
  useOnCompleteHookProvider,
  useSelfInfo,
  useUpdateSelfInfo,
} from '../../../context/QuizDataContext';
import { ShippingAddressProvider, useShippingAddress } from '../../../context/ShippingAddress';
import { decodeErrorResponse } from '../../../utils/decodeErrorResponse';
import { StepBodyWrapper, StepWrapper } from '../../StepLayout';
import { ErrorMsg, StepHeader } from '../../StepLayout/v2';

const StyledStepBodyWrapper = styled(StepBodyWrapper)`
  width: 100%;
  max-width: 32rem;
  align-items: stretch;
  font-size: 0.875rem;
  padding: 1rem 0.5rem 2rem;
  row-gap: 1.25rem;

  .step-input {
    margin-top: 0;
  }
  .step-label {
    font-weight: 600;
    line-height: normal;
  }
`;
const Row = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: inherit;

  ${up('lg')} {
    flex-direction: row;
    column-gap: 1.25rem;
  }

  & > * {
    flex: 1;
  }
`;
const CheckboxWrapper = styled.div`
  & > [role='button'] {
    margin: 0;

    a {
      color: ${p => p.theme.colors.info};
    }
  }
`;

export const shippingAddressSchema = object({
  address_1: string().required('Address is required'),
  address_2: string().notRequired(),
  city: string().required('City is required'),
  state: string()
    .required('State is required')
    .nullable(),
  zip_code: string()
    .required('Zip Code is required')
    .matches(/^\d{5}$/, 'Zip Code must be exactly 5 digits'),
  phone_number: string()
    .required('Phone Number is required')
    .test({ test: (v: any) => (v || '').indexOf('_') === -1, message: 'Invalid phone number' }),
  allow_texts: boolean().notRequired(),
});
type TSchema = InferType<typeof shippingAddressSchema>;

const _ShippingAddress: FC = () => {
  const { values, errors, touched, handleSubmit, setValues } = useFormikContext<TSchema>();
  const getError = (fldName: keyof TSchema) => touched[fldName] && errors[fldName];
  const { addressToChoose, setAddressToChoose } = useShippingAddress();
  const [addressVerificationError, setAddressVerificationError] = useState<string>();
  const createAddress = useCreateAddress();
  const [isConfirmed, setIsConfirmed] = useState(false);
  const selfInfo = useSelfInfo();
  const updateSelfInfo = useUpdateSelfInfo();
  const existingAddress: Pick<Address, keyof TSchema & keyof Address> = useMemo(
    () => ({
      address_1: selfInfo.address?.address_1 ?? '',
      address_2: selfInfo.address?.address_2 ?? '',
      city: selfInfo.address?.city ?? '',
      state: selfInfo.address?.state ?? '',
      zip_code: selfInfo.address?.zip_code ?? '',
    }),
    [selfInfo],
  );

  useEffect(() => {
    setValues({ ...existingAddress, allow_texts: selfInfo.allow_texts, phone_number: selfInfo.phone_number });
  }, [existingAddress, setValues, selfInfo]);

  const confirmAddress = async (address: Address) => {
    try {
      if (!equals(address, existingAddress)) await createAddress(address);
      await updateSelfInfo({ allow_texts: values.allow_texts, phone_number: values.phone_number });
      setIsConfirmed(true);
      setAddressToChoose(null);
    } catch (error) {
      const errorMsg = await decodeErrorResponse(error?.response);
      setAddressVerificationError(errorMsg);
      captureException(error, s => s.setExtra('errorMsg', errorMsg));
    }
  };
  const handleBeforeSubmit = useCallback(async () => {
    if (isConfirmed) return true;

    const address: Address = {
      address_1: values.address_1,
      address_2: values.address_2,
      city: values.city,
      state: values.state,
      zip_code: values.zip_code,
    };
    const correctedAddresses = equals(address, existingAddress)
      ? [address] // skip verification if address has not changed
      : await verifyAddress(address).catch(async e => {
          setAddressVerificationError(await decodeErrorResponse(e.response));
          return [{ address_1: notFoundAddress, city: '', state: '', zip_code: '' }, address];
        });

    if (correctedAddresses && correctedAddresses.length > 1) {
      setAddressToChoose(correctedAddresses);
      return;
    }

    await confirmAddress(correctedAddresses[0]);
    return true;
  }, [values]);

  useStepInjectorSetBeforeSubmit(handleBeforeSubmit);

  return (
    <StepWrapper className="font-nunito">
      <StepHeader>Add shipping address</StepHeader>
      <StyledStepBodyWrapper>
        <div>
          <Field
            name="address_1"
            label="Address"
            labelWrapperClass="step-label"
            wrapperClass="step-input"
            placeholder="Enter address"
            value={values.address_1}
            component={InputField}
            showError={getError('address_1')}
            isTouchable
          />
          <ErrorMsg>{getError('address_1')}</ErrorMsg>
        </div>
        <div>
          <Field
            name="address_2"
            label="Address (Line 2)"
            labelWrapperClass="step-label"
            wrapperClass="step-input"
            placeholder="Enter address line 2"
            component={InputField}
            value={values.address_2}
            showError={getError('address_2')}
          />
          <ErrorMsg>{getError('address_2')}</ErrorMsg>
        </div>
        <div>
          <Field
            name="city"
            label="City"
            labelWrapperClass="step-label"
            wrapperClass="step-input"
            placeholder="Enter city"
            component={InputField}
            value={values.city}
            showError={getError('city')}
            isTouchable
          />
          <ErrorMsg>{getError('city')}</ErrorMsg>
        </div>
        <Row>
          <div>
            <Field
              name="state"
              label="State"
              labelWrapperClass="step-label"
              wrapperClass="step-input"
              placeholder="Enter state"
              component={SelectField}
              options={states}
              value={values.state}
              showError={getError('state')}
              isTouchable
            />
            <ErrorMsg>{getError('state')}</ErrorMsg>
          </div>
          <div>
            <Field
              name="zip_code"
              label="Zip"
              labelWrapperClass="step-label"
              wrapperClass="step-input"
              placeholder="Enter zip"
              component={InputField}
              inputMode="numeric"
              value={values.zip_code}
              showError={getError('zip_code')}
              isTouchable
            />
            <ErrorMsg>{getError('zip_code')}</ErrorMsg>
          </div>
        </Row>
        <div>
          <Field
            name="phone_number"
            label="Phone number"
            labelWrapperClass="step-label"
            wrapperClass="step-input"
            placeholder="Enter phone number"
            component={PhoneInputField}
            inputMode="numeric"
            value={values.phone_number}
            showError={getError('phone_number')}
            isTouchable
          />
          <ErrorMsg>{getError('phone_number')}</ErrorMsg>
        </div>
        <CheckboxWrapper>
          <Field
            name="allow_texts"
            value={values.allow_texts}
            label={
              <>
                I agree to receive promotional and personalized marketing text messages.{' '}
                <a href="https://www.thedopple.com/terms" rel="noreferrer" target="_blank">
                  View Terms &gt;
                </a>
              </>
            }
            component={Checkbox}
          />
        </CheckboxWrapper>

        {addressToChoose && (
          <VerifyAddressModal
            addresses={addressToChoose}
            confirmed={async (address: Address) => {
              await confirmAddress(address);
              return handleSubmit();
            }}
            error={addressVerificationError}
          />
        )}
      </StyledStepBodyWrapper>
    </StepWrapper>
  );
};

export const ShippingAddress: FC = () => (
  <ShippingAddressProvider>
    <_ShippingAddress />
  </ShippingAddressProvider>
);

export const useOnShippingAddressComplete = () => {
  const { currentKidId, updateKid } = useOnCompleteHookProvider();

  return async () => updateKid(currentKidId, {});
};
