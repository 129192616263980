import React from 'react';
import qs from 'query-string';
import styled from 'styled-components';
import { down } from 'styled-breakpoints';
import useEventRedirect from '../../hooks/useEventRedirect';

export function getUriRedirect(location, defaultValue = '') {
  const queryStringValues = qs.parse(location.search);
  return queryStringValues.return || defaultValue;
}

const BannerContainer = styled.div`
  width: 100vh;
  display: flex;
  justify-content: center;
  margin: 34px 0 5px;
  ${down('md')} {
    img {
      width: 500px;
      height: 280px;
    }
  }
  ${down('sm')} {
    img {
      width: 400px;
      height: 225px;
    }
  }
`;

const SpecialBanner = () => {
  const eventUri = useEventRedirect();

  return (
    <BannerContainer>
      {eventUri.eventBanner && <img src={eventUri.eventBanner.url} alt={eventUri.eventBanner.name} />}
    </BannerContainer>
  );
};

export default SpecialBanner;
