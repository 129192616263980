import React from 'react';
import { FaFacebookSquare as FacebookIcon } from 'react-icons/fa';
import useTheme from '../../hooks/useTheme';
import styled from 'styled-components';
import { SocialButton } from './index';
import SocialTextButtonStyle from './SocialTextButtonStyle';

const StyledLinkButton = styled(SocialButton)`
  ${SocialTextButtonStyle}
  svg {
    margin-top: 1px;
    margin-right: -3px;
    width: 20px;
    height: 23px;
  }
`;

const FacebookTextButton = props => {
  const { colors } = useTheme();

  return (
    <StyledLinkButton
      {...props}
      fontColor={colors.facebook}
      icon={<FacebookIcon size={18} />}
      color={colors.facebook}
    />
  );
};

export default FacebookTextButton;
