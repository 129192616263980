import React, { memo, useMemo } from 'react';
import styled from 'styled-components';

const StyledAlert = styled.div`
  font-size: 0.75rem;
  width: 100%;
  text-align: center;
  padding: 3px 0;
  background: ${({ theme }) => theme.colors.warning};
`;

const DisabledCookiesAlert = () => {
  const cookieEnabled = useMemo(() => {
    try {
      return Boolean(navigator.cookieEnabled);
    } catch {
      return true;
    }
  }, []);

  return (
    <>
      {!cookieEnabled ? (
        <StyledAlert>Cookies are disabled, some pages will not work until you enable them.</StyledAlert>
      ) : null}
    </>
  );
};

export default memo(DisabledCookiesAlert);
