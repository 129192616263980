import React, { createContext, FC, ProviderProps, useEffect } from 'react';
import { useCtx } from '../../../../utils';

type TContext = { setBeforeSubmit: (cb: (() => Promise<boolean | undefined>) | undefined) => void };
const StepInjectorContext = createContext<TContext>({
  setBeforeSubmit: undefined,
});
export const StepInjectorProvider: FC<ProviderProps<TContext>> = ({ value, children }) => (
  <StepInjectorContext.Provider value={value}>{children}</StepInjectorContext.Provider>
);

export const useStepInjectorSetBeforeSubmit = (cb: Parameters<TContext['setBeforeSubmit']>[0]) => {
  const { setBeforeSubmit } = useCtx(StepInjectorContext);

  useEffect(() => {
    setBeforeSubmit(cb);
    return () => setBeforeSubmit(undefined);
  }, [setBeforeSubmit, cb]);
};
