import React from 'react';
import styled from 'styled-components';

const NewLabel = styled.span`
  background: #ff0000;
  color: white;
  font-size: 8px;
  padding: 1px 6px;
  font-weight: 500;
  text-transform: uppercase;
  font-style: italic;
  transform: skew(-10deg);
  display: inline-flex;
  align-items: center;
  margin-left: 4px;
  height: 12px;
  line-height: 1;

  span {
    display: inline-block;
    transform: skew(10deg);
    margin-top: 1px;
  }
`;

export default function NewLabelComponent() {
  return (
    <NewLabel>
      <span>NEW</span>
    </NewLabel>
  );
}
