import { useCallback } from 'react';
import { useAsync } from 'react-async';

import client from '../../../utils/api-client';
import { Kid } from '../types';

interface State {
  isLoading: boolean;
  error: Error | null;
  data: any | null;
  reload: () => void;
}

const getKidsList = () => {
  return client<Kid>(`api/kids/`);
};

export function useKidsList(): State {
  const promiseFn = useCallback(() => getKidsList(), []);

  const { data = null, error = null, isPending: isLoading, reload } = useAsync({ promiseFn });

  return {
    data,
    isLoading,
    error,
    reload,
  };
}

export default useKidsList;
