import React from 'react';
import SocialButton from './SocialButton';
import { FaGoogle as GoogleIcon } from 'react-icons/fa';
import useTheme from '../../hooks/useTheme';
import styled from 'styled-components';

const StyledSocialButton = styled(SocialButton)`
  &:hover {
    background-color: ${({ theme }) => theme.colors.google};
  }
`;

export const GoogleButton = props => {
  const { colors } = useTheme();

  return <StyledSocialButton {...props} icon={<GoogleIcon size={18} />} color={colors.google} />;
};

export default GoogleButton;
