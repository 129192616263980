import React, { memo } from 'react';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Wrapper = styled.div`
  width: 6rem;
  height: 6rem;
  border-radius: 10px;
  box-shadow: 0 8px 14px 0 rgba(244, 244, 244, 0.25);
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
`;

const Container = styled.div`
  position: relative;
  width: 80%;
  height: 80%;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 4rem;
    height: 4rem;
    margin: 6px;
    border: 6px solid ${({ theme }) => theme.colors.primaryHighlight};;
    border-radius: 50%;
    animation: ${rotate} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    //border-color: ${({ theme }) => theme.colors.primaryHighlight}; transparent transparent transparent
    border-top: 0.8em solid ${({ theme }) => theme.colors.primaryHighlight};
    border-right: 0.8em solid ${({ theme }) => theme.colors.darkGray};
    border-bottom: 0.8em solid ${({ theme }) => theme.colors.primaryHighlight};
    border-left: 0.8em solid ${({ theme }) => theme.colors.darkGray};

    &:nth-child(1) {
      animation-delay: -0.45s;
    }
    &:nth-child(2) {
      animation-delay: -0.3s;
    }
    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
`;

export default memo(({ className }: { className?: string }) => (
  <Wrapper data-testid="loader" className={className}>
    <Container>
      <div />
      <div />
      <div />
      <div />
    </Container>
  </Wrapper>
));
