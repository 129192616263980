import React, { FC } from 'react';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import almostDoneCalendarImg from '../../../../../../public/static/images/style-quiz/almost_done_calendar.svg';
import almostDoneCreditImg from '../../../../../../public/static/images/style-quiz/almost_done_credit.svg';
import almostDoneHeroImg from '../../../../../../public/static/images/style-quiz/almost_done_hero.jpg';
import almostDonePostponeImg from '../../../../../../public/static/images/style-quiz/almost_done_postpone.svg';
import { NavButton } from '../../../../../components/Buttons';
import { STYLE_FEE } from '../../../constants';
import { StepSubmitBtn } from '../../../containers/StepInjector';
import { useOnCompleteHookProvider, useQuiz, useSelfInfo } from '../../../context/QuizDataContext';
import { NavigationBlock } from '../../NavigationBlock';
import { StepBodyWrapper, StepWrapper } from '../../StepLayout';
import { headerMixin, StepHeader } from '../../StepLayout/v2';

const StyledStepHeader = styled(StepHeader)`
  font-size: 1.25rem;

  ${up('lg')} {
    font-size: 1.75rem;
  }
`;
const SubHeader = styled.div`
  ${headerMixin}
  font-size: 0.875rem;

  ${up('lg')} {
    font-size: 1rem;
  }
`;
const StepSubHeader = styled(SubHeader)`
  font-weight: 600;
  color: ${p => p.theme.colors.darkGray};
  margin-top: 0.5rem;
`;
const StepContent = styled(StepBodyWrapper)`
  width: 100%;
`;
const Hero = styled.section`
  margin-top: 1.5rem;
  width: 100vw;
  display: grid;
  background: ${p => p.theme.colors.paper};

  ${up('lg')} {
    margin-top: 2.5rem;
    grid-template-columns: repeat(2, 1fr);
  }
`;
const HeroImg = styled.div`
  background: url('${almostDoneHeroImg}');
  width: 100%;
  height: 10rem;
  background-position: top;
  background-size: cover;

  ${up('lg')} {
    height: 100%;
  }
`;
const HeroContent = styled.div`
  padding: 1rem 2rem 3rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: stretch;
  max-width: fit-content;
  margin: 0 auto;

  ${up('lg')} {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  ul {
    font-size: 0.875rem;

    ${up('lg')} {
      font-size: 1rem;
    }

    li + li {
      ${up('lg')} {
        margin-top: 0.25rem;
      }
    }
  }

  ${NavigationBlock} {
    padding: 0;
    margin: 0;
  }
  ${NavButton} {
    margin: 0;
  }
`;
const HeroHeader = styled.h3`
  ${headerMixin}
  font-size: 1rem;

  ${up('lg')} {
    font-size: 1.25rem;
  }

  del {
    text-decoration: none;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: -0.1em;
      right: -0.1em;
      border: solid 1px red;
      transform: rotate(-15deg);
    }
  }

  ins {
    text-decoration: none;
    color: ${p => p.theme.colors.primaryDanger};
    margin-left: 0.5rem;
  }
`;
const InfoSection = styled.section`
  margin: 2rem 0;
  align-self: stretch;

  ul {
    display: grid;
    gap: 1.5rem;
    list-style: none;
    margin: 1.5rem 0;
    padding: 0;

    ${up('lg')} {
      grid-auto-columns: minmax(0, 25rem);
      grid-auto-flow: column;
      justify-content: space-evenly;
    }

    li {
      display: flex;
      gap: 1rem;
      font-weight: 600;
      align-items: start;

      img {
        width: 2.5rem;
        aspect-ratio: 1;
      }
    }
  }
`;
const InfoNote = styled.p`
  text-align: center;
  font-weight: 600;

  text-wrap: balance;
`;
const FaqLink = styled.a`
  color: ${p => p.theme.colors.warning};
  font-weight: 800;
  filter: brightness(0.8);
`;

export const AlmostDone: FC = () => {
  const { selectedKids } = useQuiz();
  const { has_quiz_completed } = useSelfInfo();
  const styleFee = `$${selectedKids.length * STYLE_FEE}`;

  return (
    <StepWrapper className="font-nunito">
      <StyledStepHeader>We're ready to curate your Drop!</StyledStepHeader>
      <StepSubHeader>Get 5-10 items curated by a stylist when you order your first Drop.</StepSubHeader>
      <StepContent>
        <Hero>
          <HeroImg />
          <HeroContent>
            <HeroHeader>
              Today's styling fee |{' '}
              {has_quiz_completed ? (
                styleFee
              ) : (
                <>
                  <del>{styleFee}</del>
                  <ins>FREE</ins>
                </>
              )}
            </HeroHeader>
            <SubHeader>YOUR NEXT STEPS</SubHeader>
            <ul>
              <li>
                <strong>Order</strong> today & your Drop will arrive within the week.
              </li>
              <li>
                <strong>Try</strong> them before you buy them.
              </li>
              <li>
                <strong>Buy</strong> it all and save an extra 25% off.
              </li>
              <li>
                <strong>Free</strong> shipping and returns.
              </li>
            </ul>
            <StepSubmitBtn />
          </HeroContent>
        </Hero>

        <InfoSection>
          <SubHeader>MORE DETAILS</SubHeader>
          <ul>
            <li>
              <img src={almostDonePostponeImg} alt="" />{' '}
              <span>You can postpone or cancel your subscription at any time.</span>
            </li>
            <li>
              <img src={almostDoneCreditImg} alt="" />{' '}
              <span>
                Your {has_quiz_completed || 'next'} personalized Drop has a $10 styling fee, which is then credited to
                your order.
              </span>
            </li>
            <li>
              <img src={almostDoneCalendarImg} alt="" />{' '}
              <span>
                We'll check in again in three months to get your little one's updated sizes so we can send a new Drop
                for the new season.
              </span>
            </li>
          </ul>
          <InfoNote>
            For more information or questions, see our{' '}
            <FaqLink target="_blank" href="https://www.thedopple.com/faq">
              FAQ
            </FaqLink>{' '}
            or contact us at <a href="mailto:parentals@thedopple.com">parentals@thedopple.com</a>
          </InfoNote>
        </InfoSection>
      </StepContent>
    </StepWrapper>
  );
};

export const useOnComplete = () => {
  const { updateKid, currentKidId } = useOnCompleteHookProvider();
  return async (values: {}) => {
    return updateKid(currentKidId as string, values);
  };
};
