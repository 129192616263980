const pagesMeta = {
  home: {
    title: 'Designer Kids Clothing Subscription Box | Dopple',
    description:
      'Dopple is the kids clothing subscription box that sends designer kids clothing to your home in a personalized monthly package. Get access to the trendiest styles without the hassle of in-person shopping.',
  },
  about: {
    title: 'About Us | Dopple',
    description: 'Dopple is a membership service reimagining how parents shop for their kids | Dopple.',
  },
  renewal: {
    title: 'Renewal | Dopple',
    description: 'Renewal | Dopple',
  },
  faqs: {
    title: 'Frequently Asked Questions | Dopple',
    description: 'The answers to your most common questions at Dopple.',
  },
  resetPassword: {
    title: 'Reset Password | Dopple',
    description: 'Reset your Dopple password here.',
  },
  gift: {
    title: 'Gifts & Gift Codes | Dopple',
    description:
      'Give the gift of fashionable kids clothing to your friends and loved ones. The best part? You set the dollar amount, and they get to pick their favorite outfits.',
  },
  giftBox: {
    title: 'Gift Drop | Dopple',
    description: 'Give the gift of fashionable kids clothing to your friends and loved ones.',
  },
  hiw: {
    title: 'How it Works | Dopple',
    description: 'Learn how to get designer kids clothing delivered to your doorstep in three simple steps.',
  },
  privacyPolicy: {
    title: 'Privacy Policy | Dopple',
    description: 'Learn more about how Dopple is keeping your kids and their data safe.',
  },
  quiz: {
    title: 'Get Started | Dopple',
    description: 'Request a Dopple drop to get the latest designer kids clothing brands delivered to your doorstep.',
  },
  requestDrop: {
    title: 'Returning Customer? Request a Drop | Dopple',
    description: 'Request a new Dopple drop to get more of your favorite kids clothing brands.',
  },
  signin: {
    title: 'Sign In | Dopple',
    description: 'Sign into your Dopple account here.',
  },
  signup: {
    title: 'Get Started | Dopple',
    description: 'Request a Dopple drop to get the latest designer kids clothing brands delivered to your doorstep.',
  },
  terms: {
    title: 'Terms and Conditions | Dopple',
    description: 'Read terms and conditions for all Dopple customers.',
  },
  doen: {
    title: 'Dôen | Dopple',
    description: '',
  },
  careers: {
    title: 'Open Opportunities | Dopple',
    description: 'Open Opportunities | Dopple',
  },
  kiddos: {
    title: 'Kiddos | Dopple',
    description: 'Kiddos | Dopple',
  },
  yourKiddo: {
    title: 'Kiddo | Dopple',
    description: 'Kiddo | Dopple',
  },
  quizResult: {
    title: 'Your Style Quiz Results | Dopple',
    description: 'See your Dopple style quiz results.',
  },
  orders: {
    title: 'Your Orders | Dopple',
    description: 'Your Orders | Dopple',
  },
  order: {
    title: 'Your Order | Dopple',
    description: 'Your Order | Dopple',
  },
  keepAll: {
    title: 'Keep All | Dopple',
    description: 'Keep All | Dopple',
  },
  referFriend: {
    title: 'Refer a Friend | Dopple',
    description: 'Learn how to refer a friend to Dopple and get $25 off your next Dopple drop.',
  },
  referrals: {
    title: 'My Referrals | Dopple',
    description: 'My Referrals | Dopple',
  },
  account: {
    title: 'Your Account | Dopple',
    description: 'Your Account | Dopple',
  },
  event: {
    title: 'Events | Dopple',
    description: 'Events | Dopple',
  },
  checkout: {
    title: 'Checkout | Dopple',
    description: 'Checkout | Dopple',
  },
};

export default pagesMeta;
