import jsCookie from 'js-cookie';
import { cookieDomain, tokenCookieName } from '../constants';
import { decodeErrorResponse } from '../features/style-quiz/utils/decodeErrorResponse';
import { User } from '../types';
import client from '../utils/api-client';
import storage from '../utils/storage';

type LoginPayload = {
  email: string;
  password: string;
};

type ConfirmPasswordPayload = {
  token: string;
  password: string;
  password_confirmation: string;
};

export const login = async (payload: LoginPayload): Promise<User> => {
  try {
    const response = await client<Promise<User>>('api/users/login/', { body: payload, method: 'POST' });
    storage.set('user', response);
    if (response && response.auth_token) {
      const cookieOptions = cookieDomain ? { domain: cookieDomain } : undefined;
      jsCookie.set(tokenCookieName, response.auth_token, cookieOptions);
      if (response.can_refer) {
        jsCookie.set('user_can_refer', 'true', cookieOptions);
      }
    }
    return response;
  } catch (error) {
    throw new Error("We don't have an account matching those credentials");
  }
};

export const resetPassword = async (email: string): Promise<any> => {
  try {
    await client('api/users/send_password_reset/', {
      body: { email },
      method: 'POST',
    });
  } catch (error) {}
};

export const confirmPasswordReset = async (values: ConfirmPasswordPayload): Promise<any> => {
  try {
    const response = await client('api/users/confirm_password_reset/', {
      body: values,
      method: 'POST',
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const requestQuizRecoveryCode = email =>
  client('api/users/send_quiz_recover_code/', {
    body: { email },
    method: 'POST',
    //headers: { Accept: 'text/html', 'Content-Type': 'text/html' },
  });

export const confirmQuizRecoveryCode = (email: string, code: string) =>
  client<{ token: string }>('api/users/confirm_quiz_recover_code/', {
    body: { email, code },
    method: 'POST',
  });

export const loginCreateUser = async (email: string, password: string) => {
  try {
    return await client<Promise<User>>('/api/users/login_by_email', {
      body: { email, password },
      method: 'POST',
    });
  } catch (exception) {
    const error =
      exception.response!.status === 400 ? await decodeErrorResponse(exception.response) : 'Invalid email or password.';
    throw new Error(error);
  }
};

export const loginByEmail = async (email: string) => {
  try {
    return await client<Promise<User>>('api/users/login_by_email/', {
      body: { email },
      method: 'POST',
    });
  } catch (exception) {
    const error = exception.response!.status === 400 ? await decodeErrorResponse(exception.response) : 'Invalid email.';
    throw new Error(error);
  }
};

export const verifyEmail = (email: string): Promise<{ has_password_or_social_auth: boolean }> =>
  client(`api/users/check_by_email/?email=${encodeURIComponent(email)}`);

export const createByEmail = (email: string): Promise<User> =>
  client(`api/users/create_by_email/`, {
    body: { email },
    method: 'POST',
  });
