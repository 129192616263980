import { append, uniq } from 'ramda';
import { LAST_STEP_INDEX, StepId, stepIdToIndexMap, stepSequence } from '../../../constants/steps';
import { KidDataResponse } from '../../../services/KidService';
import { MeDataResponse } from '../../../services/MeService';
import { GenderValues } from '../../../types';
import { ActionTypes, IQuizValues, QuizStateContextValues } from '../index';

type Action =
  | { type: ActionTypes.SetValuesForStep; payload: { stepId: string; data: { [fieldName: string]: any } } }
  | { type: ActionTypes.SetAllValues; payload: { values: IQuizValues } }
  | { type: ActionTypes.WipeAllValues }
  | { type: ActionTypes.GoBackOrToIndex; payload: { index?: number } }
  | { type: ActionTypes.UpdateKidInfo; payload: { id: string; data: KidDataResponse } }
  | { type: ActionTypes.CreateKid; payload: { data: KidDataResponse } }
  | { type: ActionTypes.AllKidsLoaded; payload: { data: KidDataResponse[] } }
  | { type: ActionTypes.UpdateSelfInfo; payload: { data: MeDataResponse } }
  | { type: ActionTypes.SetLoading; isLoading?: boolean }
  | { type: ActionTypes.SetSubmitting; isSubmitting: boolean }
  | { type: ActionTypes.SelectKid; kidId: string }
  | { type: ActionTypes.RemoveFromSelectedKids; kidId: string }
  | { type: ActionTypes.SetCurrentStepData; payload: { stepIndex: number; data: any } };

const getNextUnskippedStep = (state: QuizStateContextValues, stepIndex: number, skipForward: boolean) => {
  const stepId = stepSequence[stepIndex];

  let shouldSkip =
    (stepId === StepId.Password && state.selfInfo.has_password) ||
    (stepId === StepId.PaymentInfo && !!state.selfInfo.stripe_payment_source_last4);

  if (!shouldSkip) {
    const currentKid = state.currentKidId && state.loadedKids.find(kid => kid.id === state.currentKidId);
    shouldSkip =
      stepId === StepId.Glam &&
      currentKid?.gender !== GenderValues.GIRL &&
      currentKid?.gender_preference !== GenderValues.GIRL;
  }

  if (shouldSkip) {
    stepIndex = stepIdToIndexMap.get(
      // recursively get the next stepId where shouldSkip is false
      getNextUnskippedStep(state, stepIndex + (skipForward ? 1 : -1), skipForward),
    );
  }
  if (stepIndex > LAST_STEP_INDEX || stepIndex < 0) {
    stepIndex = 0;
  }

  return stepSequence[stepIndex];
};

export const reducer = (state: QuizStateContextValues, action: Action): QuizStateContextValues => {
  if (action.type === ActionTypes.SetValuesForStep) {
    const { stepId, data } = action.payload;
    const newQuizValues: IQuizValues = {
      ...state.values,
      [stepId]: {
        ...state.values[stepId],
        ...data,
      },
    };
    const nextStepIndex = stepIdToIndexMap.get(state.currentStepId) + 1;
    const newStepId = getNextUnskippedStep(state, nextStepIndex > LAST_STEP_INDEX ? 0 : nextStepIndex, true);

    return {
      ...state,
      values: newQuizValues,
      currentStepId: newStepId,
    };
  }
  if (action.type === ActionTypes.SetCurrentStepData) {
    const { stepIndex, data } = action.payload;

    const currentStepId = getNextUnskippedStep(state, stepIndex, true);

    return {
      ...state,
      currentStepId,
      currentKidId: data.kidId,
      selectedKids: data.selectedKids,
    };
  }
  if (action.type === ActionTypes.SetAllValues) {
    const { values } = action.payload;
    return {
      ...state,
      values,
    };
  }
  if (action.type === ActionTypes.WipeAllValues) {
    return {
      ...state,
      prevValues: state.values,
      values: {},
    };
  }
  if (action.type === ActionTypes.GoBackOrToIndex) {
    const { index } = action.payload;
    let nextStepId = state.currentStepId;
    let nextIndex = stepIdToIndexMap.get(nextStepId);
    const currentStepIndex = nextIndex;
    const { currentKidId, values, prevValues } = state;

    if (currentStepIndex > 0) {
      if (index !== undefined) {
        nextIndex = index;
      } else {
        nextIndex = currentStepIndex - 1;
      }

      if (nextIndex > LAST_STEP_INDEX) {
        nextIndex = 1;
      }

      nextStepId = getNextUnskippedStep(state, nextIndex, index !== undefined);
    }

    // Allow go to back AddKidStep again
    if (currentKidId && currentStepIndex === 0 && index === undefined) {
      nextStepId = StepId.AddKidStep;
      nextIndex = stepIdToIndexMap.get(nextStepId);
    }

    return {
      ...state,
      currentStepId: nextStepId,
      values: currentKidId && currentStepIndex === 0 ? prevValues : values,
    };
  }
  if (action.type === ActionTypes.UpdateKidInfo) {
    const { id, data } = action.payload;
    return {
      ...state,
      loadedKids: state.loadedKids.map(k => {
        if (k.id !== id) {
          return k;
        }
        return {
          ...k,
          ...data,
        };
      }),
      currentKidId: id,
    };
  }
  if (action.type === ActionTypes.AllKidsLoaded) {
    const { data } = action.payload;
    return {
      ...state,
      loadedKids: data,
    };
  }
  if (action.type === ActionTypes.CreateKid) {
    const { data } = action.payload;
    return {
      ...state,
      loadedKids: [...state.loadedKids, data],
      currentKidId: data.id || null,
    };
  }
  if (action.type === ActionTypes.UpdateSelfInfo) {
    const { data } = action.payload;
    return {
      ...state,
      selfInfo: {
        ...state.selfInfo,
        ...data,
      },
    };
  }
  if (action.type === ActionTypes.SetLoading) {
    return {
      ...state,
      isLoading: !!action.isLoading,
    };
  }
  if (action.type === ActionTypes.SetSubmitting) {
    return {
      ...state,
      isSubmitting: action.isSubmitting,
    };
  }
  if (action.type === ActionTypes.SelectKid) {
    return {
      ...state,
      selectedKids: uniq(append(action.kidId, state.selectedKids)),
    };
  }
  if (action.type === ActionTypes.RemoveFromSelectedKids) {
    const newSelectedKidsId = state.selectedKids ? [...state.selectedKids.filter(id => id !== action.kidId)] : [];
    return {
      ...state,
      selectedKids: newSelectedKidsId,
    };
  }
  return state;
};
