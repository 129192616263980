import { up } from 'styled-breakpoints';
import styled, { css } from 'styled-components';

export const headerMixin = css`
  font-weight: 800;
  text-align: center;
  color: ${p => p.theme.colors.black};
  margin: 0;
`;
export const StepHeader = styled.h1`
  ${headerMixin}
  font-size: 1.5rem;
  line-height: normal;
  margin-top: 0.5rem;

  ${up('lg')} {
    margin-top: 2rem;
    font-size: 2rem;
  }
`;
export const ErrorMsg = styled.div`
  font-size: 0.875rem;
  margin-top: ${p => (p.children ? '0.25rem' : 0)};
  color: ${p => p.theme.colors.primaryDanger};
`;
