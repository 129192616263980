import Link from 'next/link';
import React, { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { down } from 'styled-breakpoints';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import styled from 'styled-components';
import useQueryParams from '../../hooks/useQueryParams';
import { User } from '../../types';
import { useUser } from '../../utils/auth-client';
import { hardRedirect } from '../../utils/hard-redirect';
import BaseButtonStyle, { SmallButtonStyle } from '../Buttons/BaseButtonStyle';
import Logo from '../Logo';
import DisabledCookiesAlert from './DisabledCookiesAlert';
import MobileMenu from './MobileMenu';
import MobileMenuTrigger from './MobileMenuTrigger';
import NewLabel from '../NewLabel';
import { getWishlistUrl } from '../../utils/wishlistUrls';

type VisibleFn = (user: User | null) => boolean;

export type NavLink = {
  title: ReactNode;
  url?: string;
  visible?: VisibleFn;
  external?: boolean;
  children?: NavLink[];
  className?: string;
  onClick?: (e: React.MouseEvent) => void;
};

const requiresUser: VisibleFn = user => user !== null;
const requiresUserCanRefer: VisibleFn = user => user !== null && user.can_refer;
const requiresAnon: VisibleFn = user => user === null;
export const alwaysVisible: VisibleFn = _ => true;

// @ts-ignore
const StyledHeader = styled.header<{ canRefer: boolean }>`
  position: sticky;
  z-index: 500;
  top: 0;
  width: 100%;
  background: #fff;
  display: flex;
  flex-flow: row wrap;
  min-height: 80px;
  align-items: center;
  justify-content: space-between;
  ${Logo} {
    width: 129px;
    height: 79px;
    position: absolute;
    margin: ${({ canRefer }) => (canRefer ? '0px 0px 0px -331px' : '0px 0px 0px -255px')};
    top: 17px;
    ${down('md')} {
      position: absolute;
      width: 96px;
      height: 60px;
      margin: 0px 0px 0px 68px;
      top: 12px;
      z-index: 10;
    }
    ${down('sm')} {
      width: 80px;
      height: 51px;
      margin: 4px 0px 0px 63px;
    }
  }

  ${down('md')} {
    min-height: 64px;
  }

  .header-button{
    position: absolute;
    z-index: 10;
    top: 10px;
    ${down('md')} {
      top: 3px;
    }
  }
  .gift-link{
    ${down('md')} {
      padding-top: 0 !important;
    }
  }
  .signin-link{
    position: absolute;
    right: 194px;
    top: 30px;
  }
  .account-link{
    right: 218px;
    &:last-child {
      right: 0;
    }
  }
  }
`;

const HeaderNav = styled.nav<{ position?: 'left' | 'right'; isForSmallScreen?: boolean }>`
  display: flex;
  flex-flow: row wrap;
  justify-content: ${({ position }) => (position === 'right' ? 'flex-end' : 'start')};
  margin: 0 25px;
  flex-grow: 1;
  ${({ isForSmallScreen }) =>
    isForSmallScreen &&
    `
     display: none;
    `};
  ${down('md')} {
    display: none;
    ${({ isForSmallScreen }) =>
      isForSmallScreen &&
      `
     display: flex;
    `};
    &.active {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      flex-direction: column;
      background: #fff;
      margin: 0;
      padding: 25px 10px;
    }
  }

  .button {
    ${BaseButtonStyle};
    ${SmallButtonStyle};
    margin: 10px;

    &.hidden {
      visibility: hidden;
    }

    &:last-child {
      margin-right: 0;
    }

    ${down('md')} {
      text-align: center;
    }
  }

  a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 500;
    margin-right: 16px;

    ${down('md')} {
      padding: 15px;
      font-size: 1rem;
    }
  }

  .mobile-pullup {
    ${down('sm')} {
      order: -1;
    }
  }
`;

const StyledDropdown = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  &:hover .dropdown-content {
    display: block;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    top: 45px;
    margin-left: -20px;
    background-color: #fff;

    a {
      text-transform: none;
      text-align: left;
      margin: 30px 25px;
      display: flex;
      flex-direction: row;

      span {
        margin: 0 15px;
        flex: 1;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        color: ${({ theme }) => theme.colors.primary};
        font-weight: 500;

        &:hover {
          color: rgba(111, 122, 130, 0.6);
        }
      }
    }

    ${down('md')} {
      display: block;
      position: relative;
      top: inherit;
      margin-left: 0;
      background-color: unset;
      font-size: 0.8rem;

      a {
        margin: 5px;
        color: black;
        text-align: center;
      }
      img {
        display: none;
      }
    }
  }

  @media screen and (max-width: 660px) {
    a {
      color: ${({ theme }) => theme.colors.primary};
    }

    .dropdown-content {
      z-index: 1;
      border-bottom: none;
    }

    .dropdown-content a {
      float: none;
      color: black;
      padding: 3px 16px;
      text-decoration: none;
      display: block;
      text-align: center;
    }

    .dropdown-content a:hover {
      color: black;
    }

    .dropdown:hover .dropdown-content {
      display: none;
    }

    &.active .dropdown-content {
      display: none;
    }
  }
`;

type HeaderNavLinkProps = {
  navLink: NavLink;
  className?: string;
  onClick: () => void;
};

export const HeaderNavLink: FC<HeaderNavLinkProps> = ({ onClick, navLink, className: extraClass = '' }) => {
  const { title, url, external, className = '', onClick: navLinkClick } = navLink;
  const { query, queryAsString } = useQueryParams();

  const onClickHandler = useCallback(
    (evt: any) => {
      if (navLinkClick) {
        navLinkClick(evt);
      } else if (external) {
        evt.preventDefault();
        hardRedirect(`${url}${queryAsString}`);
      }
      onClick();
    },
    [url, queryAsString, onClick, navLinkClick],
  );

  return external ? (
    <a href={`${url}${queryAsString}`} className={`${className} ${extraClass}`} onClick={onClickHandler}>
      {title}
    </a>
  ) : (
    <Link href={{ pathname: url, query }}>
      <a className={`${className} ${extraClass}`} onClick={onClick}>
        {title}
      </a>
    </Link>
  );
};

type HeaderNavDropdownItemProps = {
  navLink: NavLink;
};

const HeaderNavDropdownItem: FC<HeaderNavDropdownItemProps> = ({ navLink }) => {
  const { title, url, external } = navLink;
  const { query, queryAsString } = useQueryParams();

  const onClick = useCallback(
    (evt: any) => {
      evt.preventDefault();
      hardRedirect(`${url}${queryAsString}`);
    },
    [url, queryAsString],
  );

  return external ? (
    <a href={`${url}${queryAsString}`} onClick={onClick}>
      {title}
    </a>
  ) : (
    <Link href={{ pathname: url, query }}>
      <a>{title}</a>
    </Link>
  );
};

type HeaderNavDropdownProps = {
  navLink: NavLink;
};

const HeaderNavDropdown: FC<HeaderNavDropdownProps> = ({ navLink }) => {
  const { title, children } = navLink;
  const [isHeaderOpen, setHeaderOpen] = useState(false);

  return (
    <StyledDropdown
      className={`mobile-header caps navbar-link ${isHeaderOpen ? 'active' : ''}`}
      onClick={() => setHeaderOpen(prev => !prev)}
    >
      <a>{title}</a>
      <div className="dropdown-content">
        {children.map((child, index) => (
          <HeaderNavDropdownItem key={index} navLink={child} />
        ))}
      </div>
    </StyledDropdown>
  );
};

type HeaderNavItemProps = {
  navLink: NavLink;
  className?: string;
  onClick: () => void;
};

export const HeaderNavItem: FC<HeaderNavItemProps> = ({ onClick, navLink, className = '' }) => {
  if (Array.isArray(navLink.children)) {
    return <HeaderNavDropdown navLink={navLink} />;
  }

  return <HeaderNavLink navLink={navLink} className={`${className} caps navbar-link`} onClick={onClick} />;
};

const HeaderComponent = () => {
  const { currentUser } = useUser();
  const [isHeaderOpen, setHeaderOpen] = useState(false);
  const { query } = useQueryParams();
  const isMd = useBreakpoint(down('md'));
  const [loading, setLoading] = useState(false);

  const handleWishlistClick = async (e: React.MouseEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      const url = await getWishlistUrl();
      window.open(url, '_blank', 'noopener,noreferrer');
    } catch (error) {
      console.error('Error opening wishlist:', error);
    } finally {
      setLoading(false);
    }
  };

  const navLinks: NavLink[] = useMemo(
    () => [
      {
        url: '/dashboard/my-kiddos',
        title: 'Account',
        visible: user => user && isHeaderOpen,
      },
      {
        url: 'https://www.thedopple.com/how-it-works',
        title: 'How it Works',
        external: true,
      },
      {
        url: 'https://www.thedopple.com/new',
        title: 'New In',
        external: true,
      },
      {
        url: 'https://www.thedopple.com/gifts',
        title: 'Gifts',
        external: true,
      },
      {
        url: 'https://www.thedopple.com/stories',
        title: 'Stories',
        external: true,
      },
      {
        url: '#',
        title: (
          <span style={{ display: 'flex', alignItems: 'center' }}>
            {loading ? 'Loading...' : 'Wishlists'}
            <NewLabel />
          </span>
        ),
        visible: alwaysVisible,
        external: true,
        onClick: handleWishlistClick,
      },
      {
        url: '/dashboard/refer-a-friend',
        title: 'Refer A Friend',
        visible: requiresUserCanRefer,
      },
    ],
    [loading, isHeaderOpen],
  );

  useEffect(() => {
    if (!isMd) {
      setHeaderOpen(false);
    }
  }, [isMd]);

  const closeMenu = useCallback(() => {
    setHeaderOpen(false);
  }, []);

  const authLinks: NavLink[] = useMemo(
    () => [
      {
        url: '/sign-in',
        title: 'Sign In',
        visible: requiresAnon,
        className: 'mobile-pullup signin-link',
      },
      {
        url: 'https://www.thedopple.com/quiz',
        title: 'Get Started',
        visible: requiresAnon,
        className: `button header-button mobile-pullup ${SmallButtonStyle}`,
        external: true,
      },
      {
        url: '/dashboard/my-kiddos',
        title: 'Account',
        visible: requiresUser,
        className: 'mobile-pullup desktop-only signin-link account-link',
      },
      {
        url: '/request-a-drop',
        title: 'Request a drop',
        visible: user => user?.has_quiz_completed,
        className: `button header-button ${SmallButtonStyle}`,
      },
    ],
    [currentUser],
  );

  const visibleLinks = navLinks.filter(({ visible = alwaysVisible }) => visible(currentUser));
  const visibleAuthLinks = authLinks.filter(({ visible = alwaysVisible }) => visible(currentUser));

  return (
    <>
      <DisabledCookiesAlert />
      <StyledHeader canRefer={Boolean(currentUser?.can_refer)}>
        <MobileMenuTrigger onClick={() => setHeaderOpen(prev => !prev)} isOpen={isHeaderOpen} />
        <MobileMenu isOpen={isHeaderOpen} onClick={closeMenu} navLinks={navLinks} />
        <HeaderNav>
          {visibleLinks.map((navLink, index) => (
            <HeaderNavItem key={index} navLink={navLink} onClick={closeMenu} />
          ))}
        </HeaderNav>
        <Link href={{ pathname: 'https://www.thedopple.com', query }}>
          <a>
            <Logo />
          </a>
        </Link>
        <HeaderNav position="right">
          {visibleAuthLinks.map((navLink, index) => (
            <HeaderNavItem key={index} navLink={navLink} onClick={closeMenu} />
          ))}
        </HeaderNav>
        <HeaderNav position="right" isForSmallScreen>
          {[visibleAuthLinks[visibleAuthLinks.length - 1]].map((navLink, index) => (
            <HeaderNavItem key={index} navLink={navLink} onClick={closeMenu} />
          ))}
        </HeaderNav>
      </StyledHeader>
    </>
  );
};

export default HeaderComponent;
