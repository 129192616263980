interface Option {
  value: string;
  label: string;
}

const genders: Option[] = [
  {
    value: 'boy',
    label: 'Boy',
  },
  {
    value: 'girl',
    label: 'Girl',
  },
  {
    value: 'other',
    label: 'Other',
  },
  {
    value: 'unknown',
    label: 'Unknown',
  },
];

const genderPreferences: Option[] = [
  {
    value: 'boy',
    label: 'Boy',
  },
  {
    value: 'girl',
    label: 'Girl',
  },
  {
    value: 'unisex',
    label: 'Unisex',
  },
];

const allowsGenderPreference = ['other', 'unknown'];

const howManyKids = [
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4+',
  },
  {
    value: 0,
    label: 'None yet, but one on the way!',
  },
];

export { genders, howManyKids, genderPreferences, allowsGenderPreference };
