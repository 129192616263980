import { useEffect, useState } from 'react';
import { useUser } from '../../../utils/auth-client';
import { useRouter } from 'next/router';
import qs from 'query-string';

const DOPPLE_SHOP_URL = process.env.SHOP_QUIZ_URL;

/**
 * Sign-up in the Dopple Shop
 */
function useRedirectToShop(): { isRedirecting: boolean; isQuizRedirectionEnabled: boolean } {
  const { push } = useRouter();
  const { currentUser, isLoading } = useUser();
  const [isRedirecting, setIsRedirecting] = useState<boolean>(false);

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const queryStringValues = qs.parse(window.location.search);
    if (!isLoading && !currentUser && DOPPLE_SHOP_URL && queryStringValues.error !== 'INCOMPLETE_QUIZ') {
      setIsRedirecting(true);
      push(`${DOPPLE_SHOP_URL}${document && document.location ? document.location.search : ''}`).then(() =>
        setIsRedirecting(false),
      );
    }
  }, [isLoading, currentUser]);

  return { isRedirecting, isQuizRedirectionEnabled: Boolean(DOPPLE_SHOP_URL) };
}

export default useRedirectToShop;
