import { useMemo } from 'react';
import { GenderValues } from '../types';
import { filter } from 'ramda';
import { genderPreferences, genders } from '../../../constants/kid';
import { isFuture, parse } from 'date-fns';

function useGenderOptions({ gender, date_of_birth }) {
  const genderPreferenceOptions = useMemo(() => {
    if (gender === GenderValues.OTHER || gender === GenderValues.UNKNOWN) {
      return filter(item => item.value !== GenderValues.UNISEX, genderPreferences);
    }
    return genderPreferences;
  }, [gender]);

  const genderOptions = useMemo(() => {
    const birthDate =
      typeof date_of_birth === 'string' ? parse(date_of_birth, 'yyyy-MM-dd', new Date()) : date_of_birth;

    if (!isFuture(birthDate)) {
      return filter(item => item.value !== GenderValues.UNKNOWN, genders);
    }
    return genders;
  }, [date_of_birth]);

  return { genderPreferenceOptions, genderOptions };
}

export default useGenderOptions;
