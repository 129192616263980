import React from 'react';

type AddressTextProps = {
  address: Record<string, any>;
};

const AddressText = ({ address }: AddressTextProps) => {
  if (!address) return null;

  return (
    <p className="addressText">
      {address['address_1']}
      <br />
      {address['address_2'] !== '' ? (
        <>
          {address['address_2']}
          <br />
        </>
      ) : null}
      {address['city']}, {address['state']} {address['zip_code']}
    </p>
  );
};

export default AddressText;
