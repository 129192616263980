import { useMemo } from 'react';
import { useRouter } from 'next/router';

const useQuizType = () => {
  const { pathname } = useRouter();

  const isBonpointQuiz = useMemo(() => {
    return pathname === '/quiz/bonpoint';
  }, [pathname]);

  return {
    isBonpointQuiz,
  };
};

export default useQuizType;
