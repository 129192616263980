import styled from 'styled-components';
import React, { FC, ReactNode, ReactElement, PropsWithChildren, memo } from 'react';

export enum DirectionEnum {
  horizontal,
  vertical,
}

export enum SpaceSizesEnum {
  small = 5,
  medium = 14,
  large = 35,
}

export enum Align {
  center = 'center',
  none = 'unset',
}

const SpaceContainer = styled.div<{ direction: DirectionEnum; startWith: number; align?: Align }>`
  display: flex;
  flex-direction: ${({ direction }) => (direction === DirectionEnum.horizontal ? 'row' : 'column')};
  margin-left: ${({ direction, startWith }) => (direction === DirectionEnum.horizontal ? `${startWith}px` : '0')};
  margin-top: ${({ direction, startWith }) => (direction === DirectionEnum.vertical ? `${startWith}px` : '0')};
  align-items: ${({ align }) => align};
  justify-content: center;
  width: 100%;
`;

const SpaceItem = styled.div<{ direction: DirectionEnum; size: SpaceSizesEnum; style: object }>`
  margin-right: ${({ direction, size }) => (direction === DirectionEnum.horizontal ? `${size}px` : '0')};
  margin-bottom: ${({ direction, size }) => (direction === DirectionEnum.vertical ? `${size}px` : '0')};
`;

type SpaceProps = PropsWithChildren<ReactNode> & {
  direction?: DirectionEnum;
  size?: SpaceSizesEnum;
  style?: object;
  startWith?: number;
  align?: Align;
  className?: string;
};

const Space: FC<SpaceProps> = ({
  direction = DirectionEnum.horizontal,
  size = SpaceSizesEnum.small,
  children,
  style,
  startWith,
  align = Align.none,
  className,
}: SpaceProps): ReactElement => {
  if (!children) {
    return <SpaceContainer direction={direction} startWith={startWith} />;
  }

  return (
    <SpaceContainer direction={direction} startWith={startWith} align={align} className={className}>
      {Array.isArray(children)
        ? children.map((item, idx) => (
            <SpaceItem key={idx} direction={direction} size={idx === children.length - 1 ? 0 : size} style={style}>
              {item}
            </SpaceItem>
          ))
        : { children }}
    </SpaceContainer>
  );
};

export default memo(Space);
