export const validateRequiredString = (val: string) => {
  if (!val) {
    return 'Required';
  }
};

export const validateRequiredNumber = (val: number) => {
  if (typeof val !== 'number') {
    return 'Required';
  }
};
