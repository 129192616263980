import React, { FC, memo, useCallback } from 'react';
import { down } from 'styled-breakpoints';
import styled from 'styled-components';
import useQueryParams from '../../../hooks/useQueryParams';
import { useUser } from '../../../utils/auth-client';
import { hardRedirect } from '../../../utils/hard-redirect';
import { alwaysVisible, HeaderNavItem, NavLink } from '../index';

type Props = {
  isOpen: boolean;
  onClick: () => void;
  navLinks: NavLink[];
};

const StyledMobileMenu = styled.div<{
  isOpen: boolean;
}>`
  display: none;
  transform: translate3d(-100%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  transition: 0.5s transform;
  animation-timing-function: ease-in-out;
  ${({ isOpen }) =>
    isOpen &&
    `
  transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  `}
  ${down('md')} {
    display: flex;
  }
`;

const MobileMenu: FC<Props> = ({ onClick, isOpen, navLinks }) => {
  const { currentUser } = useUser();
  const { queryAsString } = useQueryParams();

  const onQuizClickHandler = useCallback(
    (evt: any) => {
      evt.preventDefault();
      hardRedirect(`https://www.thedopple.com/quiz${queryAsString}`);
    },
    [queryAsString],
  );

  const visibleLinks = navLinks.filter(({ visible = alwaysVisible }) => visible(currentUser));
  return (
    <StyledMobileMenu isOpen={isOpen} className="menu">
      <div className="menu-wrapper">
        {visibleLinks.map((navLink, index) => (
          <HeaderNavItem key={index} navLink={navLink} onClick={onClick} />
        ))}
      </div>
      {!currentUser ? (
        <>
          <a
            href={`https://www.thedopple.com/quiz${queryAsString}`}
            className="button mobile-only w-button"
            onClick={onQuizClickHandler}
          >
            Get Started
          </a>
          <a href="/sign-in" className="caps navbar-link mobile-only">
            Sign in
          </a>
        </>
      ) : (
        currentUser.has_quiz_completed && (
          <a href="/request-a-drop" className="button mobile-only w-button">
            Request a Drop
          </a>
        )
      )}
    </StyledMobileMenu>
  );
};

export default memo(MobileMenu);
