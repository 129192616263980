import beau from '../../public/static/images/brands/beau_resized.png';
import bonpoint from '../../public/static/images/brands/bonpoint_resized.png';
import carters from '../../public/static/images/brands/carters_resized.png';
import gap from '../../public/static/images/brands/gap_resized.png';
import gray from '../../public/static/images/brands/gray_resized.png';
import jacadi from '../../public/static/images/brands/jacadi_resized.png';
import janie from '../../public/static/images/brands/janie_resized.png';
import petite_bateau from '../../public/static/images/brands/petite_bateau.png';
import rylee from '../../public/static/images/brands/rylee_resized.png';
import tiny from '../../public/static/images/brands/tiny_resized.png';
import tutu from '../../public/static/images/brands/tutu_resized.png';

const brands = [
  {
    src: bonpoint,
    value: 'Bonpoint',
    key: 'bonpoint',
  },
  {
    src: petite_bateau,
    value: 'Petit Bateau',
    key: 'petitbateau',
  },
  {
    src: carters,
    value: "Carter's",
    key: 'carters',
  },
  {
    src: gap,
    value: 'Gap Kids',
    key: 'gap',
  },
  {
    src: gray,
    value: 'Gray Label',
    key: 'gray',
  },
  {
    src: jacadi,
    value: 'Jacadi',
    key: 'jacadi',
  },
  {
    src: janie,
    value: 'Janie and Jack',
    key: 'janie',
  },
  {
    src: rylee,
    value: 'Rylee + Cru',
    key: 'rylee',
  },
  {
    src: tiny,
    value: 'Tinycottons',
    key: 'tiny',
  },
  // tutu - girl brand only
  {
    src: tutu,
    key: 'tutu',
    value: 'Tutu du Monde',
  },
];

export { brands };
