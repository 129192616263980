import React, { createContext, FC, useContext, useState } from 'react';
import { Address } from '../../../../types';

type UpdateAddressContextValue = {
  error?: boolean;
  setError?: (val) => void;
  address?: Address;
  addressToChoose?: Address | any[];
  setAddressToChoose?: (value) => void;
};

const UpdateAddressContext = createContext<UpdateAddressContextValue>({
  error: false,
  setError: val => val,
  address: null,
  addressToChoose: null,
  setAddressToChoose: (value: Address) => value,
});

const ShippingAddressProvider: FC<{ children: any }> = ({ children }) => {
  const [error, setError] = useState<boolean>(false);
  const [addressToChoose, setAddressToChoose] = useState<Address>();

  return (
    <UpdateAddressContext.Provider
      value={{
        error,
        setError,
        addressToChoose,
        setAddressToChoose,
      }}
    >
      {children}
    </UpdateAddressContext.Provider>
  );
};

const useShippingAddress = () => {
  const context = useContext(UpdateAddressContext);
  if (context === undefined) {
    throw new Error('useShippingAddress must be used within a ShippingAddressProvider');
  }

  return context;
};

export { useShippingAddress, ShippingAddressProvider };
