import React from 'react';
import styled from 'styled-components';
import bonpointLogo from '../../../../../public/static/images/brands/bonpoint-logo.svg';
import doppleLogo from '../../../../../public/static/images/logo.png';
import { down, up } from 'styled-breakpoints';

const Container = styled.div`
  padding-top: 40px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  .bonpoint-logo {
    width: 180px;
  }
  .dopple-logo {
    width: 139px;
  }
  .join {
    margin: 0px 28px 19px 28px;
    font-size: 1.5rem;
  }
  ${down('sm')} {
    .bonpoint-logo {
      width: 149px;
    }
    .dopple-logo {
      width: 96px;
    }
    .join {
      margin: 0px 22px 19px 22px;
      font-size: 1.3rem;
    }
  }
  ${up('xl')} {
    padding-top: 50px;
    height: 240px;
    .bonpoint-logo {
      width: 250px;
    }
    .dopple-logo {
      width: 192px;
    }
    .join {
      margin: 0px 35px 25px;
      font-size: 1.8rem;
    }
  }
`;

const BonpointSignupHeader = () => (
  <Container>
    <img src={bonpointLogo} className="bonpoint-logo" />
    <div className="join">x</div>
    <img src={doppleLogo} className="dopple-logo" />
  </Container>
);

export default BonpointSignupHeader;
