import { FieldProps } from 'formik';
import React, { FC, HTMLAttributes, useCallback, useEffect, useMemo, useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import styled from 'styled-components';
import Label, { LabelProps } from '../Label';

export const Input = styled.input<{ showError?: boolean; width?: string }>`
  min-height: 60px;
  border-radius: 4px;
  border: solid 1px transparent;
  box-shadow: 0 2px 6px 0 rgba(94, 97, 100, 0.2);
  background-color: #ffffff;
  padding: 17px 16px;
  font-size: 16px;
  width: ${({ width }) => width || '100%'};
  border-color: ${({ showError, theme }) => (showError ? theme.colors.primaryDanger : theme.colors.white)};

  &:hover,
  &:focus {
    border-color: ${({ showError, theme }) => (showError ? theme.colors.primaryDanger : '#cccccc')};
  }

  // https://stackoverflow.com/questions/45592319/placeholder-style-not-working-in-chrome
  &::-webkit-input-placeholder {
    color: #787679;
    font-size: 16px !important;
  }
  &:-moz-placeholder {
    color: #787679;
    font-size: 16px !important;
  }
`;

const TextArea = styled.textarea<{ showError: boolean }>`
  min-height: 60px;
  border-radius: 4px;
  border: solid 1px transparent;
  box-shadow: 0 2px 6px 0 rgba(94, 97, 100, 0.2);
  background-color: #ffffff;
  padding: 17px 16px;
  font-size: 16px;
  width: 100%;
  resize: none;
  border-color: ${({ showError, theme }) => (showError ? theme.colors.primaryDanger : theme.colors.white)};

  &:hover,
  &:focus {
    border-color: ${({ showError, theme }) => (showError ? theme.colors.primaryDanger : '#cccccc')};
  }

  // https://stackoverflow.com/questions/45592319/placeholder-style-not-working-in-chrome
  &::-webkit-input-placeholder {
    color: #787679;
    font-size: 16px !important;
  }
  &:-moz-placeholder {
    color: #787679;
    font-size: 16px !important;
  }
`;

const Container = styled.div`
  &.input-wrapper {
    &.full {
      width: 100%;
    }
  }
`;

const Limit = styled.div`
  padding-top: 5px;
  text-align: right;
`;

const PwdInputWrapper = styled.div`
  position: relative;

  button {
    background: ${p => p.theme.colors.white};
    position: absolute;
    top: 50%;
    right: 0.75rem;
    transform: translateY(-50%);

    svg {
      vertical-align: middle;
      width: 1.25rem;
      height: 1.25rem;
      color: ${p => p.theme.colors.darkGray};
    }
  }
`;

type InputFieldProps = {
  wrapperClass: string;
  name: string;
  placeholder: string;
  showError: boolean;
  type?: 'text' | 'password' | 'textarea' | 'number';
  inputMode?: HTMLAttributes<HTMLInputElement>['inputMode'];
  value?: any;
  width?: string;
  min?: number;
  max?: number;
  rows?: number;
  limitText?: number;
  disabled?: boolean;
  isTouchable?: boolean;
  readOnly?: boolean;
  onBlur?: (evt) => void;
};

export const InputField: FC<InputFieldProps & FieldProps & LabelProps> = props => {
  // Formik properties
  const { form, field } = props;

  const inputId = `input-${field.name}`;

  // Label properties
  const {
    label,
    subLabel,
    labelClass,
    subLabelClass,
    labelWrapperClass,
    showError,
    width,
    isTouchable,
    readOnly,
  } = props;
  const labelProps: LabelProps = {
    label,
    subLabel,
    labelClass,
    subLabelClass,
    labelWrapperClass,
    showError,
    htmlFor: inputId,
  };

  // Input properties
  const {
    wrapperClass = '',
    placeholder,
    type = 'text',
    inputMode,
    value = '',
    min,
    rows,
    limitText,
    disabled,
    onBlur,
    max,
  } = props;

  // Touchable input
  const { setFieldTouched } = form;

  const [fieldValue, setFieldValue] = useState(value);

  useEffect(() => {
    setFieldValue(value);
  }, [value]);

  const handleChange = useCallback(
    (e: any) => {
      form.setFieldValue(field.name, e.target.value);
      setFieldValue(e.target.value);
      field.onChange(e);
    },
    [form, field.name],
  );

  let input = null;
  let wrapperDefaultClass = 'input-wrapper';

  const _onChange = (e: any) => {
    if (limitText && e?.target?.value?.length > limitText) {
      return;
    }
    handleChange(e);
  };
  const count = useMemo(() => value?.length, [value]);

  const fieldProps = {
    className: 'input',
    name: field.name,
    onChange: _onChange,
    placeholder,
    value: fieldValue,
    showError,
    min,
    rows: rows,
    disabled,
    readOnly,
    onBlur: e => {
      if (isTouchable && field.name) setFieldTouched(field.name);
      onBlur?.(e);
    },
    max,
    id: inputId,
  };
  const [isPwdVisible, setIsPwdVisible] = useState(false);

  if (type === 'textarea') {
    fieldProps.className += ' textbox';
    wrapperDefaultClass += ' textbox-wrapper';
    input = (
      <>
        <TextArea {...fieldProps} />
        {limitText && <Limit>{`${count || 0}/${limitText}`}</Limit>}
      </>
    );
  } else {
    input = (
      <Input
        inputMode={inputMode}
        {...fieldProps}
        type={type === 'password' && isPwdVisible ? 'text' : type}
        width={width}
      />
    );
    if (type === 'password')
      input = (
        <PwdInputWrapper>
          {input}
          <button type="button" onClick={() => setIsPwdVisible(!isPwdVisible)}>
            {isPwdVisible ? <FaEye /> : <FaEyeSlash />}
          </button>
        </PwdInputWrapper>
      );
  }

  return (
    <Container className={`${wrapperDefaultClass} ${wrapperClass}`}>
      <Label {...labelProps} />
      {input}
    </Container>
  );
};
