import { getWishlistToken } from '../services/wishlist';

export const getWishlistUrl = async (kidId?: string) => {
  const baseDomain = process.env.WISHLIST_URL;

  if (!baseDomain) {
    console.error('WISHLIST_URL environment variable is not set');
    return '#';
  }

  try {
    // Always get a new token and use login flow
    const { redirect_url } = await getWishlistToken();

    // Add utm_source and kidId to URL if provided
    const finalUrl = `${redirect_url}&utm_source=dc${kidId ? `&kidId=${kidId}` : ''}`;
    return finalUrl;
  } catch (error) {
    console.error('Error getting wishlist URL:', error);
    // Fallback to non-authenticated URL
    const fallbackUrl = `${baseDomain}/?utm_source=dc${kidId ? `&kidId=${kidId}` : ''}`;
    return fallbackUrl;
  }
};
