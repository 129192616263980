import { useEffect } from 'react';
import { useUser } from '../utils/auth-client';
import { useRouter } from 'next/router';
import useQueryParams from './useQueryParams';
import { loginRedirect } from '../utils/redirect';

const useRedirectIfLogged = () => {
  const router = useRouter();
  const { queryAsString } = useQueryParams(['rt', 'return', 'error', 'quiz', 'next'], true);
  const { currentUser } = useUser();

  useEffect(() => {
    if (currentUser) {
      loginRedirect(currentUser, router, queryAsString);
    }
  }, [currentUser, router, queryAsString]);
};

export default useRedirectIfLogged;
