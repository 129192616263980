import accessoriesGirl from '../../public/static/images/items/girl/accessories.png';
import coatGirl from '../../public/static/images/items/girl/coat.jpg';
import dressGirl from '../../public/static/images/items/girl/dress.jpg';
import leggingsGirl from '../../public/static/images/items/girl/leggings.jpg';
import occasionGirl from '../../public/static/images/items/girl/occasion.jpg';
import onesieGirl from '../../public/static/images/items/girl/onesie.jpg';
import pantsGirl from '../../public/static/images/items/girl/pants.jpg';
import shoesGirl from '../../public/static/images/items/girl/shoes.jpg';
import shortsGirl from '../../public/static/images/items/girl/shorts.jpg';
import socksGirl from '../../public/static/images/items/girl/socks.jpg';
import skirtGirl from '../../public/static/images/items/girl/skirt.jpg';
import sweaterGirl from '../../public/static/images/items/girl/sweater.jpg';
import swimGirl from '../../public/static/images/items/girl/swim.png';
import blouseGirl from '../../public/static/images/items/girl/top.jpg';
import teeGirl from '../../public/static/images/items/girl/tee.jpg';

import accessoriesBoy from '../../public/static/images/items/boy/accessories.jpg';
import coatBoy from '../../public/static/images/items/boy/coat.jpg';
import occasionBoy from '../../public/static/images/items/boy/occasion.jpg';
import onesieBoy from '../../public/static/images/items/boy/onesie.jpg';
import pantsBoy from '../../public/static/images/items/boy/pants.jpg';
import shoesBoy from '../../public/static/images/items/boy/shoes.jpg';
import shortsBoy from '../../public/static/images/items/boy/shorts.jpg';
import socksBoy from '../../public/static/images/items/boy/socks.jpg';
import sweaterBoy from '../../public/static/images/items/boy/sweater.jpg';
import buttonUpBoy from '../../public/static/images/items/boy/button_up.jpg';
import sweatpantsBoy from '../../public/static/images/items/boy/sweatpants.jpg';
import swimBoy from '../../public/static/images/items/boy/swim.jpg';
import teeBoy from '../../public/static/images/items/boy/tee.jpg';

import { Clothes } from '../types';

const boyClothes: Clothes[] = [
  {
    src: onesieBoy,
    label: 'Onesie',
    value: 'onesie',
  },
  {
    src: teeBoy,
    label: 'T-Shirt',
    value: 'tshirt',
  },
  {
    src: buttonUpBoy,
    label: 'Button up',
    value: 'button_up',
  },
  {
    src: sweaterBoy,
    label: 'Sweater',
    value: 'sweater',
  },
  {
    src: sweatpantsBoy,
    label: 'Sweatpants',
    value: 'sweatpants',
  },
  {
    src: pantsBoy,
    label: 'Pants',
    value: 'pants',
  },
  {
    src: shortsBoy,
    label: 'Shorts',
    value: 'shorts',
  },
  {
    src: coatBoy,
    label: 'Coat',
    value: 'coat',
  },
  {
    src: occasionBoy,
    label: 'Occasion',
    value: 'occasion',
  },
  {
    src: swimBoy,
    label: 'Swim',
    value: 'swim',
  },
  {
    src: socksBoy,
    label: 'Socks',
    value: 'socks',
  },
  {
    src: accessoriesBoy,
    label: 'Accessories',
    value: 'accessories',
  },
  {
    src: shoesBoy,
    label: 'Shoes',
    value: 'shoes',
  },
];

const girlClothes: Clothes[] = [
  {
    src: onesieGirl,
    label: 'Onesie',
    value: 'onesie',
  },
  {
    src: teeGirl,
    label: 'T-Shirt',
    value: 'tshirt',
  },
  {
    src: blouseGirl,
    label: 'Blouse',
    value: 'blouse',
  },
  {
    src: sweaterGirl,
    label: 'Sweater',
    value: 'sweater',
  },
  {
    src: leggingsGirl,
    label: 'Leggings',
    value: 'leggings',
  },
  {
    src: pantsGirl,
    label: 'Pants',
    value: 'pants',
  },
  {
    src: skirtGirl,
    label: 'Skirt',
    value: 'skirt',
  },
  {
    src: shortsGirl,
    label: 'Shorts',
    value: 'shorts',
  },
  {
    src: dressGirl,
    label: 'Dress',
    value: 'dress',
  },
  {
    src: occasionGirl,
    label: 'Occasion',
    value: 'occasion',
  },
  {
    src: coatGirl,
    label: 'Coat',
    value: 'coat',
  },
  {
    src: swimGirl,
    label: 'Swim',
    value: 'swim',
  },
  {
    src: socksGirl,
    label: 'Socks',
    value: 'socks',
  },
  {
    src: accessoriesGirl,
    label: 'Accessories',
    value: 'accessories',
  },
  {
    src: shoesGirl,
    label: 'Shoes',
    value: 'shoes',
  },
];

export { boyClothes, girlClothes };
