import React, { FC } from 'react';
import PageWithAuth from '../../../../components/PageWithAuth';
import styled from 'styled-components';
import SpecialBanner from '../../components/SpecialBanner';
import Squiggly from '../../../marketing/components/Squiggly';
import signinFigure from '../../../../../public/static/images/signin_figure.png';
import { down } from 'styled-breakpoints';

export const StyledSquiggly = styled(Squiggly)`
  margin: 15px 0 29px;
`;

export const SigninFigure = styled.img`
  position: relative;
  top: -167px;
  margin-bottom: -172px;
  right: -310px;
  ${down('lg')} {
    display: none;
  }
`;

export const PageContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.grayLighter};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;
  font-size: 14px;
  justify-content: center;
  h3 {
    font-weight: 700;
    margin: 50px 0 35px;
  }
`;

export const FormContainer = styled.div`
  width: 340px;
  padding: 0 20px;
  justify-content: center;
`;

export const Title = styled.h5.attrs({ className: 'heading-s' })`
  padding: 30px 0;
`;

const AuthPagesLayout: FC<{ title: string; description: string; pageTitle: string }> = ({
  title,
  children,
  description,
  pageTitle,
}) => (
  <PageWithAuth showWaitList title={pageTitle} description={description}>
    <PageContainer>
      <SpecialBanner />
      <Title>{title}</Title>
      <StyledSquiggly />
      <FormContainer>
        {/* Form */}
        {children[0]}
      </FormContainer>
      {/*Form footer*/}
      {children[1]}
      <SigninFigure src={signinFigure} alt="" />
    </PageContainer>
  </PageWithAuth>
);

export default AuthPagesLayout;
