export type StyleQuizStepsValues = {
  kid: {
    date_of_birth: string;
    name: string;
    top_size: string;
    bottom_size: string;
    shoes_size: string;
    top_price: string;
    bottom_price: string;
    shoes_price: string;
    outfit_price: string;
  };
  preppy: {
    preppy: string;
  };
  euro: {
    euro: string;
  };
  kiddie: {
    kiddie: string;
  };
  boho: {
    boho: string;
  };
  glam?: {
    glam: string;
  };
};

export type StyleQuizFormValues = {
  style_quizzes: StyleQuizStepsValues[];
  current_style_quiz: StyleQuizStepsValues;
};

export enum QuizStorageKey {
  LAST_KID_NAME = 'QUIZ_LAST_KID_NAME',
  LAST_SELECTED_KIDS = 'QUIZ_LAST_SELECTED_KIDS',
}

// TODO: replace all gender references in the code to use this enum
export enum GenderValues {
  BOY = 'boy',
  GIRL = 'girl',
  OTHER = 'other',
  UNKNOWN = 'unknown',
  UNISEX = 'unisex',
}

export enum QuizType {
  BONPOINT = 'BONPOINT',
}
