import React, { FC, memo, useCallback } from 'react';
import { FieldProps } from 'formik';
import styled from 'styled-components';
import { down } from 'styled-breakpoints';
import { LabelProps } from '../Label';

const CheckboxWrapper = styled.div<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  margin: 10px 0;
  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.3;
    pointer-events: none;
  `}
  ${down('lg')} {
    display: flex;
    align-items: center;
    .checkbox-text {
      margin-left: 15px;
      float: right;
      width: 95%;
      &::after {
        clear: both;
      }
    }
  }
`;

type CheckmarkProps = {
  active: boolean;
};

const Checkmark = styled.span<CheckmarkProps>`
  position: relative;
  cursor: pointer;
  display: block;
  border-radius: 6px;
  height: 25px;
  width: 25px;
  border: solid 1px ${({ theme }) => theme.colors.black};
  background-color: ${({ theme, active }) => (active ? theme.colors.black : theme.colors.white)};
  &:after {
    content: '';
    position: absolute;
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid ${({ theme }) => theme.colors.white};
    border-width: 0px 3px 3px 0px;
    transform: rotate(45deg);
    display: ${({ active }) => (active ? 'block' : 'none')};
  }
`;

const CheckboxText = styled.div`
  cursor: pointer;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.3px;
  line-height: 18px;
  color: $color-section-text;
  margin-left: 15px;
  float: right;
  width: 95%;
  &:after {
    clear: both;
  }
  ${down('lg')} {
    margin-left: 15px;
    float: right;
    width: 95%;
    &:after {
      clear: both;
    }
  }
  ${down('sm')} {
    width: 100%;
    margin-left: 0;
    padding-left: 10px;
    float: none;
  }
`;

type CheckboxProps = {
  onChange?: (newValue: boolean) => void;
  disabled?: boolean;
};

export const Checkbox: FC<CheckboxProps & FieldProps & LabelProps> = memo(props => {
  const toggleChecked = useCallback(() => {
    const initialValue = !!props.field.value;
    props.form.setFieldValue(props.field.name, !initialValue);
    if (props.onChange) {
      props.onChange(!initialValue);
    }
  }, [props.field]);

  return (
    <CheckboxWrapper
      onClick={toggleChecked}
      data-testid="CheckboxWrapper"
      area-hidden={true}
      role={'button'}
      disabled={props.disabled || false}
    >
      <Checkmark active={!!props.field.value} />
      {props.label && <CheckboxText>{props.label}</CheckboxText>}
    </CheckboxWrapper>
  );
});
