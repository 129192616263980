import { format, parse } from 'date-fns';
import { Field, useFormikContext } from 'formik';
import React, { FC } from 'react';
import styled from 'styled-components';
import { DatePicker } from '../../../../../components/DatePicker';
import SelectField from '../../../../../components/SelectField';
import { allowsGenderPreference } from '../../../../../constants/kid';
import { StepId } from '../../../constants/steps';
import { useOnCompleteHookProvider, useQuizValues } from '../../../context/QuizDataContext';
import useGenderOptions from '../../../hooks/useGenderOptions';
import hasErrorFactory from '../../../utils/hasErrorFactory';
import { StepBodyWrapper, StepHeader, StepSubHeader, StepWrapper } from '../../StepLayout';

export const FutureBirthDateNotice = styled.p`
  margin-top: 0.5rem;
  font-size: 12px;
  text-align: center;
  font-style: italic;
`;

export const KidInfoStep: FC = () => {
  const allStepsValues = useQuizValues();
  const kidName = allStepsValues[StepId.SelfInfo] && allStepsValues[StepId.SelfInfo].name;

  const { values, errors, submitCount, touched } = useFormikContext<any>();
  const hasError = hasErrorFactory(submitCount, touched, errors);

  const { genderOptions, genderPreferenceOptions } = useGenderOptions({
    gender: values.gender,
    date_of_birth: values.date_of_birth,
  });

  return (
    <StepWrapper>
      {kidName && <StepHeader>Tell us more about {kidName}</StepHeader>}
      <StepSubHeader>Basics</StepSubHeader>
      <StepBodyWrapper>
        <Field
          name="date_of_birth"
          label={`When is ${kidName}'s birthday? *`}
          labelWrapperClass="step-label"
          wrapperClass="step-input"
          placeholder="MM/DD/YYYY"
          component={DatePicker}
          value={values.date_of_birth}
          initialDate={
            values.date_of_birth
              ? typeof values.date_of_birth === 'string'
                ? parse(values.date_of_birth, 'yyyy-MM-dd', new Date())
                : values.date_of_birth
              : null
          }
          hasError={hasError('date_of_birth')}
        />

        <FutureBirthDateNotice>*You can select a date in the future if you're expecting!</FutureBirthDateNotice>

        <Field
          name="gender"
          label={`${kidName}'s gender:`}
          labelWrapperClass="step-label"
          wrapperClass="step-input"
          placeholder="Select gender"
          options={genderOptions}
          component={SelectField}
          value={values.gender}
          hasError={hasError('gender')}
        />
        {allowsGenderPreference.includes(values.gender) && (
          <Field
            name="gender_preference"
            label={`Would you like to see looks traditionally for girls or for boys?`}
            labelWrapperClass="step-label"
            wrapperClass="step-input"
            placeholder="Select gender"
            options={genderPreferenceOptions}
            component={SelectField}
            value={values.gender_preference}
            hasError={hasError('gender_preference')}
          />
        )}
      </StepBodyWrapper>
    </StepWrapper>
  );
};

export const useOnComplete = () => {
  const { updateKid, currentKidId, updateSelfInfo } = useOnCompleteHookProvider();
  return async (values: {
    date_of_birth: Date | string;
    gender: string;
    gender_preference: string;
    women_dress_size: string;
  }) => {
    const { women_dress_size, ...kids_values } = values;
    await updateSelfInfo({ women_dress_size: women_dress_size });
    await updateKid(currentKidId as string, {
      ...kids_values,
      date_of_birth:
        typeof kids_values.date_of_birth === 'string'
          ? kids_values.date_of_birth
          : format(kids_values.date_of_birth, 'yyyy-MM-dd'),
    });

    return 2; // Hard-coded next step
  };
};
