import { useCallback, useEffect, useRef, useState } from 'react';

type Props = {
  initStart: number;
};

function useCountDown({ initStart }: Props) {
  const [remaining, setRemaining] = useState(initStart);
  const remainingRef = useRef(initStart);
  const intervalId = useRef(undefined);

  const setup = useCallback(() => {
    intervalId.current = setInterval(() => {
      if (remainingRef.current > 0) {
        remainingRef.current--;
      }
      if (remainingRef.current === 0) {
        clearInterval(intervalId.current);
      }
      setRemaining(remainingRef.current);
    }, 1000);
  }, []);

  useEffect(() => {
    if (!intervalId) {
      setup();
    }
  }, []);

  const restart = useCallback(() => {
    clearInterval(intervalId.current);
    intervalId.current = undefined;
    setRemaining(initStart);
    remainingRef.current = initStart;
    setup();
  }, []);

  return { remaining, restart };
}

export default useCountDown;
