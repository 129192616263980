import styled from 'styled-components';

import logoImage from '../../../public/static/images/logo.png';

const Logo = styled.div`
  width: 96px;
  height: 60px;
  background: url(${logoImage}) no-repeat center center;
  background-size: contain;
`;

export default Logo;
