import { Field, useFormikContext } from 'formik';
import React, { FC, useMemo } from 'react';
import { down, up } from 'styled-breakpoints';
import styled from 'styled-components';
import { InputField } from '../../../../../components/InputField';
import { ChoiceWithImage } from '../../../../../components/ItemsCheckbox';
import { brands } from '../../../../../constants/brands';
import { StepId } from '../../../constants/steps';
import { useOnCompleteHookProvider, useQuizValues } from '../../../context/QuizDataContext';
import hasErrorFactory from '../../../utils/hasErrorFactory';
import ErrorAlert from '../../ErrorAlert';
import { GridWithImagesChoicesSelector } from '../../GridWithImagesChoicesSelector';
import { NoteWrapper, NoteWrapperBlock, StepBodyWrapper, StepHeader, StepWrapper } from '../../StepLayout';

const normalizedBrands: ChoiceWithImage[] = brands.map(b => ({
  value: b.key,
  label: b.value,
  img: {
    src: b.src,
    alt: b.value,
  },
}));

const DisclaimerText = styled(NoteWrapper)`
  font-size: 12px;
  width: 900px;
  text-align: center;
  margin: 0.5rem 0;

  ${down('md')} {
    width: unset;
  }
`;

const StyledStepBodyWrapper = styled(StepBodyWrapper)`
  .grid-wrapper .step-input {
    gap: 1rem;
    margin-top: 0;
    align-items: stretch;
    ${down('sm')} {
      column-gap: 0.125rem;
    }

    .checkbox-item {
      flex-basis: 10.5rem;
      min-width: unset;
      width: unset;
      padding: 0;
      margin: 0;
      ${down('sm')} {
        flex-basis: 6rem;
      }

      img {
        padding: 0;
        width: 100%;
        height: auto;
        aspect-ratio: 1/1;
      }
      .checkbox-item-value {
        padding: 0 0.25rem;
        ${down('sm')} {
          font-size: 0.75rem;
        }
      }
    }
  }

  .step-input input.input {
    min-height: unset;
    padding: 0 0.75rem;
    height: 3rem;
    ${up('sm')} {
      height: 3.5rem;
    }
  }

  ${down('sm')} {
    padding: 0;
    margin-top: -0.5rem;

    .grid-wrapper {
      width: 100%;
    }
  }
`;

export const LikedBrandsStep: FC = () => {
  const allStepsValues = useQuizValues();
  const kidGender = allStepsValues[StepId.Kid] && allStepsValues[StepId.Kid].gender;
  const kidGenderPref = allStepsValues[StepId.Kid] && allStepsValues[StepId.Kid].gender_preference;

  const availableBrandChioces = useMemo(
    () =>
      kidGender === 'boy' || kidGender === 'unknown' || (kidGender === 'other' && kidGenderPref === 'boy')
        ? normalizedBrands.filter(b => b.value !== 'tutu')
        : normalizedBrands,
    [kidGender],
  );

  const { values, errors, submitCount, touched } = useFormikContext<any>();
  const hasError = hasErrorFactory(submitCount, touched, errors);

  return (
    <StepWrapper>
      <StepHeader>
        What brands do you buy?
        <NoteWrapperBlock>(Select all that apply)</NoteWrapperBlock>
      </StepHeader>
      <StyledStepBodyWrapper>
        <GridWithImagesChoicesSelector
          name="preferred_brands"
          // hide labels
          items={availableBrandChioces.map(b => ({ ...b, label: '\u2008' }))}
          className="grid-wrapper"
          value={values.preferred_brands}
          hasError={hasError('preferred_brands')}
        />
        <Field
          name="custom_brands"
          label="Are there any other brands you love?"
          className="step-label"
          labelWrapperClass="step-label"
          wrapperClass="step-input"
          placeholder="..."
          value={values.custom_brands}
          component={InputField}
          showError={hasError('custom_brands')}
        />
        <DisclaimerText>
          This question is to help us understand your style...your Drop will include items from hundreds of amazing
          brands Dopple carries, not just the ones shown here!
        </DisclaimerText>
        <ErrorAlert fieldName="preferred_brands" message="You must select at least one brand." />
      </StyledStepBodyWrapper>
    </StepWrapper>
  );
};

export const useOnComplete = () => {
  // const updateKid = useUpdateKid();
  // const currentKidId = useCurrentKidId();
  const { updateKid, currentKidId } = useOnCompleteHookProvider();
  return async (values: { preferred_brands: string[]; custom_brands: string }) => {
    return updateKid(currentKidId as string, values);
  };
};
