import { useFormikContext } from 'formik';
import React, { FC, useMemo } from 'react';
import { down } from 'styled-breakpoints';
import styled from 'styled-components';
import { boyClothes, girlClothes } from '../../../../../constants/clothes';
import { StepId } from '../../../constants/steps';
import { useOnCompleteHookProvider, useQuizValues } from '../../../context/QuizDataContext';
import hasErrorFactory from '../../../utils/hasErrorFactory';
import ErrorAlert from '../../ErrorAlert';
import { GridWithImagesChoicesSelector } from '../../GridWithImagesChoicesSelector';
import { NoteWrapperBlock, StepBodyWrapper, StepHeader, StepWrapper } from '../../StepLayout';

const StyledStepBodyWrapper = styled(StepBodyWrapper)`
  .step-input {
    gap: 1rem;
    margin-top: 0;
    ${down('sm')} {
      column-gap: 0.125rem;
    }

    .checkbox-item {
      flex-basis: 10rem;
      min-width: unset;
      width: unset;
      padding: 0;
      margin: 0;
      ${down('sm')} {
        flex-basis: 6rem;
      }

      img {
        padding: 0;
        width: 100%;
        height: auto;
        aspect-ratio: 1/1;
      }
      .checkbox-item-value {
        padding: 0 0.25rem;
        ${down('sm')} {
          font-size: 0.75rem;
        }
      }
    }
  }

  ${down('sm')} {
    padding: 0;
    margin-top: -0.5rem;

    .grid-wrapper {
      width: 100%;
    }
  }
`;

export const ItemsStep: FC = () => {
  const allStepsValues = useQuizValues();
  const kidGender = allStepsValues[StepId.Kid] && allStepsValues[StepId.Kid].gender;
  const kidGenderPref = allStepsValues[StepId.Kid] && allStepsValues[StepId.Kid].gender_preference;
  const normalizedChioces = useMemo(
    () =>
      (kidGender === 'boy' || kidGender === 'unknown' || (kidGender === 'other' && kidGenderPref === 'boy')
        ? boyClothes
        : girlClothes
      ).map(choice => ({
        value: choice.value,
        label: choice.label,
        img: {
          src: choice.src,
          alt: choice.label,
        },
      })),
    [kidGender],
  );
  const { values, errors, submitCount, touched } = useFormikContext<any>();
  const hasError = hasErrorFactory(submitCount, touched, errors);
  return (
    <StepWrapper>
      <StepHeader>
        Which items would you like us to send you?
        <NoteWrapperBlock>(Select all that apply)</NoteWrapperBlock>
      </StepHeader>
      <StyledStepBodyWrapper>
        <GridWithImagesChoicesSelector
          name="items"
          items={normalizedChioces}
          value={values.items}
          className="grid-wrapper"
          hasError={hasError('items')}
        />
        <ErrorAlert fieldName="items" message="You must select at least one item." />
      </StyledStepBodyWrapper>
    </StepWrapper>
  );
};

export const useOnComplete = () => {
  const { updateKid, currentKidId } = useOnCompleteHookProvider();
  return async (values: { items: string[] }) => {
    return updateKid(currentKidId as string, values);
  };
};
