import { startsWith } from 'ramda';

function prefixPath(path: string) {
  const port = document.location.port === '80' ? '' : `:${document.location.port}`;
  return `${document.location.protocol}//${document.location.hostname}${port}${path}`;
}

export function hardRedirect(path: string) {
  const newUrl = startsWith('http', path) ? path : prefixPath(path);
  document.location.href = newUrl;
}
