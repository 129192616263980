import React from 'react';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import SignInForm from '../../components/SignInForm';
import { SimpleLinkButton } from '../../../../components/Buttons';
import Space, { DirectionEnum, SpaceSizesEnum } from '../../../../components/Space/Space';
import useEventRedirect from '../../hooks/useEventRedirect';
import TokenRedirection from '../../components/TokenRedirection';
import AuthPagesLayout from '../../components/AuthPagesLayout';
import pagesMeta from '../../../../constants/pages-meta';
import useQueryParams from '../../../../hooks/useQueryParams';
import storage from '../../../../utils/storage';

const StyledSpan = styled.span`
  line-height: 20px;
`;

export const ALL_URL_PARAMS_KEY = 'ALL_SIGNIN_PARAMS';

const SignInPage = () => {
  const router = useRouter();
  const uri = useEventRedirect();
  const { rt: token } = router.query;
  const { queryAsString } = useQueryParams(['rt'], true);

  !storage.retrieve(ALL_URL_PARAMS_KEY) && storage.set(ALL_URL_PARAMS_KEY, queryAsString);

  if (token) {
    return <TokenRedirection token={token as string} />;
  }

  return (
    <AuthPagesLayout title="Sign In" pageTitle={pagesMeta.signin.title} description={pagesMeta.signin.description}>
      <>
        <SignInForm />
        <Space startWith={SpaceSizesEnum.large} direction={DirectionEnum.vertical} />
      </>
      {uri.isEventUri ? (
        <>
          <SimpleLinkButton href={`/sign-up${decodeURIComponent(queryAsString)}`}>
            New to Dopple? Sign up here
          </SimpleLinkButton>
          <StyledSpan> or </StyledSpan>
          <SimpleLinkButton href="/forgot-password">Need help signing in?</SimpleLinkButton>
        </>
      ) : (
        <SimpleLinkButton href="/forgot-password">Need help signing in?</SimpleLinkButton>
      )}
    </AuthPagesLayout>
  );
};

export default SignInPage;
