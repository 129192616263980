import React, { FC } from 'react';
import BabyBoyLooks from '../../../../../../public/static/images/style-quiz/new-style-quiz/preppy-baby-boy.png';
import BabyGirlLooks from '../../../../../../public/static/images/style-quiz/new-style-quiz/preppy-baby-girl.png';
import BigBoyLooks from '../../../../../../public/static/images/style-quiz/new-style-quiz/preppy-big-boys.png';
import BigGirlLooks from '../../../../../../public/static/images/style-quiz/new-style-quiz/preppy-big-girls.png';
import { babyClothesSizes } from '../../../../../constants/sizes';
import { useKidGender, useKidSize, useOnCompleteHookProvider } from '../../../context/QuizDataContext';
import LikenessSelector from '../../LikenessSelector';

export const PreppyLikenessStep: FC = () => {
  const gender = useKidGender();
  const size = useKidSize();
  const GirlLooks = babyClothesSizes.includes(size) ? BabyGirlLooks : BigGirlLooks;
  const BoyLooks = babyClothesSizes.includes(size) ? BabyBoyLooks : BigBoyLooks;
  const looks = gender === 'girl' ? GirlLooks : BoyLooks;
  return (
    <LikenessSelector name="preppy" label="What do you think about this first look?" image={looks} submitOnChange />
  );
};

export const useOnComplete = () => {
  const { updateKid, currentKidId } = useOnCompleteHookProvider();
  return async (values: { preppy: string }) => {
    return updateKid(currentKidId as string, values);
  };
};
