import { FieldProps } from 'formik';
import React, { FC, useCallback } from 'react';
import Select from 'react-select';
import { down, up } from 'styled-breakpoints';
import styled from 'styled-components';
import Label, { LabelProps } from '../../components/Label';

export interface Choice {
  value: string;
  label: string;
}

const Container = styled.div`
  width: 100%;
  .dropdown__control {
    position: relative;
    margin: 0 auto;
    box-sizing: border-box;
    width: 100%;
    border: none;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0 2px 6px 0 rgba(94, 97, 100, 0.2);
    /*font-size: 16px;*/
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.76;
    letter-spacing: 0.3px;
    padding-left: 0.75rem;
    height: 3rem;
    ${up('sm')} {
      height: 3.75rem;
    }
    cursor: pointer;
    outline: none;

    .dropdown__indicator-separator {
      display: none;
    }
  }

  .error-border {
    border-radius: 4px;
    border: solid 1px #d8000c;
    background: #fff;
  }

  .dropdown__control {
    background: #fff;
  }

  .dropdown__placeholder {
    font-size: 16px;
    letter-spacing: 0.05em;
    color: #787679;
  }

  .dropdown__value-container {
    padding: 0px 2px;
    font-size: 16px;
  }

  .dropdown__menu {
    font-size: 16px;
  }

  &.select-wrapper-horizontal {
    .select-label-wrapper {
      width: 30%;
      float: left;
      text-align: right;
      padding: 1em 2em;
    }
    .select-element-wrapper {
      width: 70%;
      float: left;
      padding: 0.5em 1em;
    }
  }

  ${down('md')} {
    &.select-wrapper-horizontal {
      .select-label-wrapper {
        padding: 1em 0.2em;
      }
      .select-element-wrapper {
        padding: 0.5em 0.5em;
      }
    }
  }
  .sub-label {
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 1.41;
    font-weight: 300;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.colors.primaryLighter};
  }
`;

export type SelectProps = {
  placeholder: string;
  label: string;
  options: Choice[];
  value?: string;
  wrapperClass?: string;
  selectClass?: string;
  hasError?: boolean;
  id?: string;
  onChangeFromField?: Function;
  defaultValue?: string;
};

const SelectField: FC<SelectProps & LabelProps & FieldProps> = props => {
  // Label properties
  const {
    showError,
    hasError = false,
    label,
    options = [],
    selectClass = '',
    subLabel,
    labelClass,
    subLabelClass,
    labelWrapperClass,
    onChangeFromField,
  } = props;

  const labelProps = {
    label,
    subLabel,
    labelClass,
    subLabelClass,
    labelWrapperClass,
    showError,
    selectClass,
    hasError,
  };

  // Input properties
  const { wrapperClass = '', placeholder, value = '', id } = props;

  // Formik properties
  const { form, field } = props;

  const { name } = field;

  const onSelectChange = useCallback(
    (option: any) => {
      form.setFieldValue(name, option.value);
      onChangeFromField && onChangeFromField(option);
    },
    [form, name, onChangeFromField],
  );

  return (
    <Container
      className={`${showError || hasError ? 'field-error' : ''} select-wrapper ${wrapperClass}`}
      data-error-field={name}
    >
      <div className="select-label-wrapper">
        <Label {...labelProps} />
      </div>
      <div className="select-element-wrapper">
        <Select
          name={name}
          options={options}
          onChange={onSelectChange}
          onBlur={() => form.setFieldTouched(name)}
          placeholder={placeholder || label}
          defaultValue={options.find(option => option.value === value)}
          classNamePrefix="dropdown"
          className={`${showError || hasError ? 'error-border' : ''} ${selectClass}`}
          inputId={id}
          value={options.find(option => option.value === value)}
        />
      </div>
      <div className="clearfix" />
    </Container>
  );
};

export default SelectField;
