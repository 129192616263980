import { useRouter } from 'next/router';
import React, { FC, MouseEvent, useCallback, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import Button from '../../../../../components/Buttons/Button';
import useKiddosList from '../../../../Kids/hooks/useKiddosList';
import { StepId, stepIdToIndexMap } from '../../../constants/steps';
import { useStepInjectorSetBeforeSubmit } from '../../../containers/StepInjector/context';
import {
  useCurrentKidId,
  useLoadedKids,
  useOnCompleteHookProvider,
  useQuiz,
  useSetAllValues,
  useSetCurrentStepData,
  useSetLoadedKids,
} from '../../../context/QuizDataContext';
import { KidDataResponse, nestFlatKidDataToSteps } from '../../../services/KidService';
import { QuizStorageKey } from '../../../types';
import { retrieveStoredStepData, storageCurrentStepData } from '../../../utils';
import { StepBodyWrapper, StepWrapper } from '../../StepLayout';

const ButtonsWrapper = styled.div`
  width: 100%;
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;
  justify-content: space-around;

  button {
    width: 100%;
    padding: 12px 20px;
    margin: 15px;
  }
`;

const KidNamesButtonsWrapper = styled.div`
  width: 100%;
  padding: 15px 0 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;
  justify-content: space-around;

  button {
    width: 100%;
    padding: 10px 15px;
    margin: 7px;
  }
`;

const AddKidHeader = styled.h5`
  font-size: 18px;
  font-weight: 500;
`;

export const AddKidStep: FC = () => {
  const { wipeAllValues, goBackOrToIndex } = useOnCompleteHookProvider();
  const { selectKid, selectedKids, removeSelectedKid } = useQuiz();
  const loadedKids = useLoadedKids();
  const { lastKidName, lastSelectedKids } = retrieveStoredStepData();
  const setAllValues = useSetAllValues();
  const setCurrentStepData = useSetCurrentStepData();
  const currentKidId = useCurrentKidId();
  const { data: kiddosData } = useKiddosList();
  const setLoadedKids = useSetLoadedKids();

  useEffect(() => {
    if (kiddosData?.results) {
      setLoadedKids(kiddosData.results);
    }
  }, [kiddosData]);

  const alreadyStartedKids = useMemo(
    () =>
      loadedKids?.filter(kid => kid.id !== currentKidId && !selectedKids?.includes(kid.id) && !kid.is_quiz_completed) ||
      [],
    [loadedKids, lastKidName, lastSelectedKids],
  );

  const router = useRouter();

  const removeKidQueryParam = () => {
    const newUrl = new URL(location.href);
    if (newUrl.searchParams.has('kid')) {
      newUrl.searchParams.delete('kid');
      return router.push(newUrl, undefined, { shallow: true });
    }
  };

  const goToFirstStep = useCallback(
    async (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      storageCurrentStepData(QuizStorageKey.LAST_KID_NAME, '');
      await removeKidQueryParam();
      wipeAllValues();
      goBackOrToIndex(0);
    },
    [goBackOrToIndex, wipeAllValues, selectedKids],
  );

  const resumeKidQuiz = useCallback(
    async (kid: KidDataResponse) => {
      await removeKidQueryParam();
      wipeAllValues();
      storageCurrentStepData(QuizStorageKey.LAST_SELECTED_KIDS, [...selectedKids, kid.id]);
      storageCurrentStepData(QuizStorageKey.LAST_KID_NAME, kid.name);

      const quizValues = nestFlatKidDataToSteps({}, kid);
      const lastStep = Number(kid.last_quiz_step);
      const stepToGo = !lastStep || lastStep >= stepIdToIndexMap.get(StepId.AddKidStep) ? 1 : lastStep;

      setAllValues(quizValues).then(() => {
        setCurrentStepData(stepToGo, { kidId: kid.id, selectedKids: [...selectedKids, kid.id] || [] });
        selectKid(kid.id);
        goBackOrToIndex(stepToGo);
      });
    },
    [wipeAllValues, selectedKids, goBackOrToIndex],
  );

  /*
   Remove kids with incomplete steps before checkout
  */
  const removeIncompletedKid = useCallback(() => {
    const newSelectedKids = [...selectedKids];

    newSelectedKids.forEach(selectedKid => {
      const last_quiz_step = loadedKids?.find(kid => kid.id === selectedKid)?.last_quiz_step;
      if (!last_quiz_step || last_quiz_step < stepIdToIndexMap.get(StepId.AddKidStep) - 1)
        removeSelectedKid(selectedKid);
    });
    storageCurrentStepData(QuizStorageKey.LAST_SELECTED_KIDS, newSelectedKids);

    return Promise.resolve(true);
  }, [loadedKids, selectedKids]);
  useStepInjectorSetBeforeSubmit(removeIncompletedKid);

  return (
    <StepWrapper>
      {alreadyStartedKids.length > 0 && (
        <>
          <AddKidHeader> Do you want to finish one of your already started children?</AddKidHeader>
          <KidNamesButtonsWrapper>
            <StepBodyWrapper>
              {alreadyStartedKids.map(kid => (
                <Button key={kid.id} onClick={() => resumeKidQuiz(kid)}>
                  {kid.name}
                </Button>
              ))}
            </StepBodyWrapper>
          </KidNamesButtonsWrapper>
        </>
      )}
      <AddKidHeader>
        {alreadyStartedKids.length > 0 ? 'Add another child?' : 'Do you want to add another child?'}
      </AddKidHeader>
      <StepBodyWrapper>
        <ButtonsWrapper>
          <Button onClick={goToFirstStep} data-testid="add-new-kid-button">
            Yes
          </Button>
          <Button data-testid="go-to-checkout-button">No (Take me to checkout)</Button>
        </ButtonsWrapper>
      </StepBodyWrapper>
    </StepWrapper>
  );
};
export const useOnComplete = () => {
  // for preventing warning about rule of hooks
  useOnCompleteHookProvider();
  return async () => {
    // noop
  };
};
