import React, { memo, FC } from 'react';
import SimpleModal, { SimpleModalProps } from '../SimpleModal';
import { Button } from '../Buttons';
import styled from 'styled-components';

const Container = styled.div`
  .buttons {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
  button {
    margin: 0 20px;
  }
`;

const Title = styled.span`
  font-weight: bold;
`;

type ConfirmModalProps = SimpleModalProps & {
  message: any;
  onConfirm: () => void;
  confirmButtonText?: string;
};

const ConfirmModal: FC<ConfirmModalProps> = ({
  confirmButtonText,
  message,
  title,
  width,
  onClose,
  onConfirm,
  ...props
}) => {
  return (
    <SimpleModal {...props} title={<Title>{title}</Title>} width={width || '480px'}>
      <Container>
        <p>{message}</p>
        <div className={'buttons'}>
          <Button size="small" secondary type="button" onClick={onClose}>
            Cancel
          </Button>
          <Button size="small" type="button" onClick={onConfirm}>
            {confirmButtonText || 'OK'}
          </Button>
        </div>
      </Container>
    </SimpleModal>
  );
};

export default memo(ConfirmModal);
