import React, { FC } from 'react';
import { down, up } from 'styled-breakpoints';
import styled from 'styled-components';

export const StepWrapper = styled.div`
  padding: 10px 30px 0 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${down('sm')} {
    width: 100%;
    padding: 15px 15px 0 15px;
  }

  .step-label {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 1.76;
  }

  input::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder,
  select::-webkit-input-placeholder {
    font-size: 12px;
    font-style: normal;
  }

  .step-input {
    margin-top: 0.875rem;
    ${up('sm')} {
      margin-top: 1.5rem;
    }
    width: 100%;
    .dropdown__control,
    .checkbox-item {
      background-color: #fff;
    }
  }

  .mt-20 {
    margin-top: 20px;
  }

  ul {
    font-size: 0.85rem;
    list-style: unset;
    padding-left: 16px;
  }
  .list {
    width: 100%;
    padding-left: 18px;
  }
`;

const StepHeaderText = styled.h1.attrs({ className: 'heading-s' })`
  text-align: center;
`;

export const StepWavyLine = styled.img.attrs({
  src: require('../../../../../public/static/images/squiggly.svg'),
})`
  margin: 1rem 0;
`;

export const StepHeader: FC = ({ children }) => (
  <>
    <StepHeaderText>{children}</StepHeaderText>
    <StepWavyLine />
  </>
);

type StepBodyWrapperProps = { maxWidth?: number };

export const StepBodyWrapper = styled.div<StepBodyWrapperProps>`
  width: 320px;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${down('sm')} {
    width: 100%;
  }
`;

export const NoteWrapper = styled.span`
  font-style: italic;
  letter-spacing: 0.03em;
  opacity: 0.7;
  line-height: 1.4;
`;

export const NoteWrapperBlock = styled(NoteWrapper)`
  font-style: normal;
  display: block;
  font-weight: normal;
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
`;

export const StepSubHeader = styled.div.attrs({ className: 'heading-xs' })<{ hasDiscount?: boolean }>`
  text-align: center;
`;
