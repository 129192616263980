import { Field, Form, Formik } from 'formik';
import { useRouter } from 'next/router';
import React, { memo, useMemo } from 'react';
import styled from 'styled-components';
import Alert, { AlertType } from '../../../../components/Alert';
import { FacebookTextButton, GoogleTextButton } from '../../../../components/Buttons';
import ContainerWithLabel from '../../../../components/ContainerWithLabel';
import { InputField } from '../../../../components/InputField';
import Space, { DirectionEnum, SpaceSizesEnum } from '../../../../components/Space/Space';
import useRedirectIfLogged from '../../../../hooks/useRedirectIfLogged';
import { OrSeparator, REDIRECT_ERRORS } from '../../../style-quiz/components/SignUpForm';
import useSignInForm from '../../hooks/useSignInForm';
import { EmailButton } from '../EmailButton';

const API_URL = process.env.API_URL;

function getSocialLoginUrl(service, queryParams: string) {
  const root = API_URL + (API_URL[API_URL.length - 1] === '/' ? '' : '/');
  return `${root}login/${service}/${queryParams}`;
}

export const socialLoginButtonHandler = (service: string, queryParams: string) => {
  // @ts-ignore
  document.location = getSocialLoginUrl(service, queryParams);
};

export const StyledAlert = styled(Alert)`
  margin: 0 0 20px 0;
`;

const SignInForm = () => {
  const { validationSchema, submitHandler, error } = useSignInForm();
  const {
    query: { error: redirectError, return: returnUrl = '' },
  } = useRouter();

  useRedirectIfLogged();

  const socialQueryParams = useMemo(() => {
    return returnUrl ? `?return_to=${returnUrl}` : '';
  }, [returnUrl]);

  return (
    <Formik initialValues={{ email: '', password: '' }} onSubmit={submitHandler} validationSchema={validationSchema}>
      {({ dirty, isValid, errors }) => (
        <Form>
          {error && <StyledAlert type={AlertType.ERROR}>{error.message}</StyledAlert>}
          {redirectError && REDIRECT_ERRORS[redirectError as string] && (
            <StyledAlert type={AlertType.ERROR}>{REDIRECT_ERRORS[redirectError as string]}</StyledAlert>
          )}
          <Space direction={DirectionEnum.vertical} size={SpaceSizesEnum.large} startWith={10}>
            <ContainerWithLabel label="Email" isBlack hasError={Boolean(errors.email)}>
              <Field name="email" component={InputField} wrapperClass="full" showError={Boolean(errors.email)} />
            </ContainerWithLabel>
            <ContainerWithLabel label="Password" isBlack hasError={Boolean(errors.password)}>
              <Field
                name="password"
                type="password"
                component={InputField}
                wrapperClass="full"
                showError={Boolean(errors.password)}
              />
            </ContainerWithLabel>
          </Space>
          <Space direction={DirectionEnum.vertical} size={SpaceSizesEnum.small} startWith={SpaceSizesEnum.medium}>
            <EmailButton type="submit" disabled={!isValid || !dirty} />
            <OrSeparator>
              <div className="div">
                <hr />
              </div>
              <div>OR</div>
              <div className="div">
                <hr />
              </div>
            </OrSeparator>
            <GoogleTextButton
              type="button"
              onClick={() => socialLoginButtonHandler('google-oauth2', socialQueryParams)}
              text="Continue with Google"
            />
            <FacebookTextButton
              type="button"
              onClick={() => socialLoginButtonHandler('facebook', socialQueryParams)}
              text="Continue with Facebook"
            />
          </Space>
        </Form>
      )}
    </Formik>
  );
};

export default memo(SignInForm);
