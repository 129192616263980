import { useFormikContext } from 'formik';
import React, { FC, memo, useEffect } from 'react';
import scrollToElement from 'scroll-to-element';

export enum ScrollStrategy {
  NATIVE, // use the NATIVE strategy when the form scrolling is not controlled by the page body scroll, example in Modals
  NONNATIVE, // smoother option
}

type Props = {
  strategy?: ScrollStrategy;
  dispatchOnValidateForm?: boolean;
  dispatchAll?: boolean;
  disabled?: boolean;
};

export const dispatchScrollToError = (
  errors: Record<string, any>,
  strategy: ScrollStrategy = ScrollStrategy.NONNATIVE,
) => {
  const keys = Object.keys(errors);
  if (keys.length > 0) {
    let fieldName = keys[0];
    let secondaryFieldName = keys[0];
    if (Array.isArray(errors[fieldName])) {
      const errorIdx = errors[fieldName].findIndex(item => item && Object.keys(item).length > 0);
      if (errorIdx !== -1) {
        const nestedKeys = Object.keys(errors[fieldName][errorIdx]);
        fieldName = `${fieldName}[${errorIdx}].${nestedKeys[0]}`;
        secondaryFieldName = `${secondaryFieldName}.${errorIdx}.${nestedKeys[0]}`;
      }
    }

    const selector = `[name="${fieldName}"]:not([type="hidden"])`;
    const secondarySelector = `[data-error-field="${fieldName}"]`;
    const _selector = `[name="${secondaryFieldName}"]:not([type="hidden"])`;
    const _secondarySelector = `[data-error-field="${secondaryFieldName}"]`;

    const errorElement =
      (document.querySelector(selector) as HTMLElement) ||
      (document.querySelector(secondarySelector) as HTMLElement) ||
      (document.querySelector(_selector) as HTMLElement) ||
      (document.querySelector(_secondarySelector) as HTMLElement);

    if (errorElement) {
      strategy === ScrollStrategy.NATIVE
        ? errorElement.scrollIntoView({ behavior: 'smooth' })
        : scrollToElement(errorElement, {
            offset: -150,
            duration: 300,
          });
    }
  }
};

const FormikScrollToError: FC<Props> = ({
  strategy = ScrollStrategy.NONNATIVE,
  dispatchOnValidateForm,
  dispatchAll,
  disabled,
}) => {
  const { errors, isSubmitting, isValidating } = useFormikContext();

  useEffect(() => {
    if (disabled) {
      return;
    }

    if ((isSubmitting && !isValidating) || (isValidating && dispatchOnValidateForm) || dispatchAll) {
      dispatchScrollToError(errors, strategy);
    }
  }, [errors, isSubmitting, isValidating, dispatchAll, dispatchOnValidateForm, disabled]);

  return <></>;
};

export default memo(FormikScrollToError);
