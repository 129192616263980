import React from 'react';
import { SocialButton } from '../../../../components/Buttons/SocialButton';
import { FaEnvelope as EmailIcon } from 'react-icons/fa';
import useTheme from '../../../../hooks/useTheme';

export const EmailButton = props => {
  const { colors } = useTheme();

  return (
    <SocialButton
      text={'Continue with Email'}
      {...props}
      icon={props.hideIcon ? undefined : <EmailIcon size={18} />}
      color={colors.primary}
    />
  );
};
