import React, { FC } from 'react';
import styled from 'styled-components';

const Container = styled.label`
  display: block;

  .field-error {
    color: ${({ theme }) => theme.colors.primaryDanger} !important;
  }
`;

export type LabelProps = {
  label: string;
  subLabel?: string;
  labelClass?: string;
  subLabelClass?: string;
  labelWrapperClass?: string;
  showError?: boolean;
  htmlFor?: string;
};

const Label: FC<LabelProps> = props => {
  const {
    showError = false,
    label = '',
    subLabel = '',
    labelClass = '',
    subLabelClass = '',
    labelWrapperClass = '',
    htmlFor,
  } = props;

  return (
    <Container htmlFor={htmlFor} className={`label-wrapper ${labelWrapperClass}`}>
      {label && (
        <div className={`label ${showError && 'field-error'} ${labelClass} ${!subLabel && 'label-margin-bottom'}`}>
          {label}
        </div>
      )}
      {subLabel && <div className={`sub-label ${subLabelClass} label-margin-bottom`}>{subLabel}</div>}
    </Container>
  );
};

export default Label;
