import * as Sentry from '@sentry/browser';
import { Field, useFormikContext } from 'formik';
import { pathOr } from 'ramda';
import React, { FC, MouseEventHandler, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import Button from '../../../../../components/Buttons/Button';
import { Checkbox } from '../../../../../components/CheckboxField';
import { InputField } from '../../../../../components/InputField';
import Label from '../../../../../components/Label';
import { useGiftCode } from '../../../../../contexts/GiftCode';
import { usePromoCode } from '../../../../../contexts/PromoCode';
import {
  useBusy,
  useCheckableCodeContext,
  useClearError,
  useHasError,
  useRemoveCode,
  useType,
  useValidateCode,
  useValidated,
} from '../../../context/CheckableCode';
import { useFeeDiscount } from '../../../context/CreateOrder';
import { ConditionalDisabledArea } from '../../ConditionalDisabledArea';

type CheckableCodeProps = {
  label?: string;
  responseSetter: (response: any) => void;
  onError?: (msg: string) => void;
};

const InputAndButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.4rem;
  & > input {
    margin: 10px;
  }
`;
const StyledButton = styled(Button)`
  padding: 12px 20px;
  border: none;
  margin-top: 40px;
  margin-left: 10px;
`;
const LabelWrapper = styled.div`
  margin: 10px 0;
  white-space: pre-wrap;
`;

const tracePromocodeError = (code: string, type: string, error: Error) => {
  Sentry.configureScope(scope => {
    scope.setTag('QUIZ', 'PROMO_CODE_APPLY');
    scope.setExtra('code', code);
    scope.setExtra('type', type);
  });
  Sentry.captureException(error);
};

export const CheckableCode: FC<CheckableCodeProps> = ({ onError, ...props }) => {
  const { values, setFieldValue, errors, setErrors } = useFormikContext<any>();
  const {
    state: { errorMsg },
  } = useCheckableCodeContext();

  const type = useType();
  const busy = useBusy();
  const hasError = useHasError();
  const validated = useValidated();
  const validateCode = useValidateCode();
  const removeCode = useRemoveCode();
  const clearError = useClearError();

  const { feeDiscountError, cleanError: cleanFeeError, isLoadingFee } = useFeeDiscount();

  const { promoCodes, removePromoCodes } = usePromoCode();
  const { giftCode, removeGiftCode } = useGiftCode();

  const inputName = `${type}codeValue`;
  const checkboxName = `${type}inputAndButtonVisible`;
  const inputValue = values[inputName];
  const checkboxValue = values[checkboxName];

  const validate = useCallback(
    (promocode: string) => {
      validateCode(promocode, props.responseSetter).then(res => {
        if (pathOr(null, ['response', 'error'], res)) {
          res.response?.status === 500 && tracePromocodeError(promocode, type, res?.response?.error);
          setErrors({ ...errors, promo_code: res?.response?.error });
          onError && onError(res?.response?.error);
        } else {
          setErrors({ ...errors });
          clearError();
          // Clear the promocode error message shown on the form
          onError && onError('');
        }
      });
    },
    [props.responseSetter, type, validateCode, errors, onError],
  );

  const handleValidateCode: MouseEventHandler = useCallback(
    e => {
      e.preventDefault();
      validate(inputValue);
    },
    [inputValue, validate],
  );

  const handleRemoveCode: MouseEventHandler = useCallback(
    e => {
      e.preventDefault();
      removeCode(props.responseSetter);
      ({ coupons: removePromoCodes, giftdrops: removeGiftCode }[type]());
      setFieldValue(checkboxName, '');
    },
    [removeCode, removePromoCodes, removeGiftCode, type],
  );

  useEffect(() => {
    clearError();
    cleanFeeError();
  }, [inputValue]);

  // Map hardcoded values from `CheckableCodeContextProvider` to
  //  `PromoCodeProvider`, `GiftCodeProvider`
  // With the goal of seeding the field with the appropriate type of value
  let seededCode = '';

  if (type === 'coupons') {
    seededCode = promoCodes.quiz;
  } else if (type === 'giftdrops') {
    seededCode = giftCode;
  }

  useEffect(() => {
    if (seededCode) {
      setFieldValue(checkboxName, true);
      setFieldValue(inputName, seededCode);

      validate(seededCode);
    }
  }, [seededCode]);

  useEffect(() => {
    if (checkboxValue === false && giftCode) {
      removeGiftCode();
      setFieldValue(inputName, '');
    }

    if (checkboxValue === false && promoCodes) {
      removePromoCodes();
      setFieldValue(inputName, '');
    }

    if (!checkboxValue && onError) {
      onError('');
      return;
    }
    if (onError) {
      onError(errorMsg);
    }
  }, [checkboxValue, onError, errorMsg]);

  if (validated && !isLoadingFee && !feeDiscountError) {
    return (
      <InputAndButtonWrapper>
        <div style={{ display: 'flex', flexWrap: 'wrap', alignContent: 'center', marginRight: '10px' }}>
          <div>{type === 'giftdrops' ? 'Gift code applied:' : 'Promo code applied:'}</div>
          <div style={{ lineHeight: '2rem', maxWidth: '15ch', wordWrap: 'break-word' }}>
            <strong>{inputValue}</strong>
          </div>
        </div>
        <Button onClick={handleRemoveCode}>Remove</Button>
      </InputAndButtonWrapper>
    );
  }

  return (
    <>
      {props.label && (
        <Field
          labelWrapperClass="step-label"
          wrapperClass="step-input"
          component={Checkbox}
          name={checkboxName}
          value={checkboxValue}
          label={props.label}
        />
      )}
      {(checkboxValue || !props.label) && (
        <>
          <InputAndButtonWrapper>
            <Field
              labelWrapperClass="step-label"
              wrapperClass="step-input"
              placeholder={`Enter ${type === 'coupons' ? 'promo' : 'gift'} code`}
              component={InputField}
              name={inputName}
              value={inputValue}
              hasError={hasError || feeDiscountError}
              showError={hasError || feeDiscountError}
            />
            <ConditionalDisabledArea disabled={busy}>
              <StyledButton
                onClick={handleValidateCode}
                disabled={!inputValue || hasError || Boolean(feeDiscountError)}
                isLoading={isLoadingFee}
              >
                Apply
              </StyledButton>
            </ConditionalDisabledArea>
          </InputAndButtonWrapper>

          {(hasError || feeDiscountError) && (
            <LabelWrapper>
              <Label showError={true} label={errorMsg || feeDiscountError} />
            </LabelWrapper>
          )}
        </>
      )}
    </>
  );
};
