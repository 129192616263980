import { useLottie } from 'lottie-react';
import React, { FC, memo, useEffect } from 'react';
import { down } from 'styled-breakpoints';
import styled from 'styled-components';
import menuAnimation from '../../../lottie-animations/menu.lottie.json';

const LottieWrapper = styled.div`
  width: 28px;
  height: 28px;
  cursor: pointer;
  display: none;
  margin-left: 20px;
  position: absolute;
  z-index: 10;
  ${down('md')} {
    display: inherit;
  }
`;
type Props = {
  onClick: () => void;
  isOpen: boolean;
};

const MobileMenuTrigger: FC<Props> = ({ onClick, isOpen }) => {
  const { View, play, setDirection } = useLottie({
    autoplay: false,
    animationData: menuAnimation,
    loop: false,
  });
  useEffect(() => {
    setDirection(isOpen ? 1 : -1);
    play();
  }, [isOpen, play, setDirection]);

  return <LottieWrapper onClick={onClick}>{View}</LottieWrapper>;
};

export default memo(MobileMenuTrigger);
