import { captureException } from '@sentry/browser';
import { Field, useFormikContext } from 'formik';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import {
  CardCVCElement,
  CardExpiryElement,
  CardNumberElement,
  injectStripe,
  PaymentRequestButtonElement,
  ReactStripeElements,
} from 'react-stripe-elements';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { InferType, object, string } from 'yup';
import stripeLogoImg from '../../../../../../public/static/images/stripe.png';
import { InputField } from '../../../../../components/InputField';
import SelectField from '../../../../../components/SelectField';
import states from '../../../../../constants/states';
import theme from '../../../../../theme';
import { useStepInjectorSetBeforeSubmit } from '../../../containers/StepInjector/context';
import {
  useOnCompleteHookProvider,
  useSelfInfo,
  useSetSubmitting,
  useUpdateSelfInfo,
} from '../../../context/QuizDataContext';
import { decodeErrorResponse } from '../../../utils/decodeErrorResponse';
import { StepBodyWrapper, StepWrapper } from '../../StepLayout';
import { ErrorMsg, StepHeader } from '../../StepLayout/v2';
import { StripeInputContainer } from '../ShippingAndBillingAndConfirmStep/stripe-utils';

const StyledStepBodyWrapper = styled(StepBodyWrapper)`
  width: 100%;
  max-width: 32rem;
  align-items: stretch;
  font-size: 0.875rem;
  padding: 1rem 0.5rem 2rem;
  row-gap: 1.25rem;

  .step-input {
    margin-top: 0;
  }
  .step-label {
    font-weight: 600;
    line-height: normal;
  }
  .stripe-input {
    padding-top: 1.25rem;
  }
`;
const Row = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: inherit;

  ${up('lg')} {
    flex-direction: row;
    column-gap: 1.25rem;
  }

  & > * {
    flex: 1;
  }
`;
const CheckboxLabel = styled.label`
  margin: 1.5rem 0 0;
  font-weight: 800;

  input {
    margin-right: 0.5rem;
  }
`;
const CcRef = styled.div`
  ${up('lg')} {
    scroll-margin-top: 10rem;
  }
`;
const StripeLogoImg = styled.img.attrs({ src: stripeLogoImg, alt: 'powered by Stripe' })`
  align-self: center;
  filter: contrast(2) invert() grayscale() opacity(0.35);
  width: 7.5rem;
  border: solid 1px white;
  border-radius: 0.25rem;
`;

export const paymentInfoSchema = object({
  stripe_payment_source_address_line1: string().required('Address is required'),
  stripe_payment_source_address_line2: string().notRequired(),
  stripe_payment_source_address_city: string().required('City is required'),
  stripe_payment_source_address_state: string().required('State is required'),
  stripe_payment_source_address_zip: string()
    .required('Zip Code is required')
    .matches(/^\d{5}$/, 'Zip Code must be exactly 5 digits'),
});
type TSchema = InferType<typeof paymentInfoSchema>;
type TCcElementType = 'cardNumber' | 'cardExpiry' | 'cardCvc';

const stripeInputStyles: ReactStripeElements.ElementsOptions['style'] = {
  base: { fontFamily: '"Nunito Sans", Arial, sans-serif', '::placeholder': { color: theme.colors.borderColorDarker } },
};
const CARD_INCOMPLETE_ERRORS = {
  cardNumber: 'Your card number is incomplete.',
  cardExpiry: "Your card's expiration date is incomplete.",
  cardCvc: "Your card's security code is incomplete.",
} as const;
const PREPAID_CARD_ERROR = 'Prepaid cards are not applicable. Please use a credit or debit card.';

const _PaymentInfo = injectStripe(({ stripe }) => {
  const { values, errors, touched, setValues, submitForm } = useFormikContext<TSchema>();
  const getError = (fldName: keyof TSchema) => touched[fldName] && errors[fldName];

  const selfInfo = useSelfInfo();
  const [isSameAsShipping, setIsSameAsShipping] = useState(true);
  useEffect(() => {
    if (!isSameAsShipping || !selfInfo.address) return;

    setValues({
      stripe_payment_source_address_line1: selfInfo.address.address_1,
      stripe_payment_source_address_line2: selfInfo.address.address_2,
      stripe_payment_source_address_city: selfInfo.address.city,
      stripe_payment_source_address_state: selfInfo.address.state,
      stripe_payment_source_address_zip: selfInfo.address.zip_code,
    });
  }, [isSameAsShipping, selfInfo, setValues]);

  const [ccData, setCcData] = useState<Record<TCcElementType, { error: string; complete: boolean; touched: boolean }>>({
    cardNumber: { complete: false, error: '', touched: false },
    cardExpiry: { complete: false, error: '', touched: false },
    cardCvc: { complete: false, error: '', touched: false },
  });
  const validateCCData = (targetElType?: string, touch = false) => {
    let hasError = false;
    setCcData(_ccData => {
      for (const elType of (targetElType && _ccData[targetElType] ? [targetElType] : Object.keys(_ccData)) as Array<
        keyof typeof _ccData
      >) {
        if (touch) _ccData[elType].touched = true;
        _ccData[elType].error = _ccData[elType].complete ? '' : _ccData[elType].error || CARD_INCOMPLETE_ERRORS[elType];
        hasError = hasError || !!_ccData[elType].error;
      }
      return { ..._ccData };
    });

    return hasError;
  };
  const handleStripeChange = (e: ReactStripeElements.ElementChangeResponse) => {
    setCcData({
      ...ccData,
      [e.elementType]: {
        ...ccData[e.elementType],
        complete: e.complete,
        error: e.error?.message ?? '',
      },
    });
    validateCCData(e.elementType, !!e.error);
  };
  const handleStripeBlur = (e: Pick<ReactStripeElements.ElementChangeResponse, 'elementType'>) => {
    validateCCData(e.elementType, true);
  };
  const getStripeError = (elType: TCcElementType) => ccData[elType].touched && ccData[elType].error;

  const [usingWallet, setUsingWallet] = useState(false);
  const ccRef = useRef<HTMLDivElement>();
  const scrollCCIntoView = () => ccRef.current?.scrollIntoView({ behavior: 'smooth' });
  const fullName = [selfInfo.first_name, selfInfo.last_name].filter(Boolean).join(' ');
  const updateSelfInfo = useUpdateSelfInfo();
  const [tokenError, setTokenError] = useState('');
  const handleBeforeSubmit = useCallback(async () => {
    if (usingWallet) {
      setUsingWallet(false);
      return true;
    }

    const hasError = validateCCData(undefined, true);
    if (hasError) {
      scrollCCIntoView();
      return;
    }

    try {
      const response = await stripe.createToken({
        name: fullName,
        address_line1: values.stripe_payment_source_address_line1,
        address_line2: values.stripe_payment_source_address_line2,
        address_city: values.stripe_payment_source_address_city,
        address_state: values.stripe_payment_source_address_state,
        address_zip: values.stripe_payment_source_address_zip,
        address_country: 'US',
      });
      if (response.error) throw response.error;
      if (response.token.card.funding === 'prepaid') {
        setCcData(_ccData => ({
          ..._ccData,
          cardNumber: {
            ..._ccData.cardNumber,
            error: PREPAID_CARD_ERROR,
          },
        }));
        scrollCCIntoView();
        return;
      }

      await updateSelfInfo({
        stripe_token: response.token.id,
      });
    } catch (error) {
      const errorMsg = await decodeErrorResponse(error.response, error.message, 'stripe_token');
      setTokenError(errorMsg);
      captureException(error, s => s.setExtra('errorMsg', errorMsg));

      return;
    }

    return true;
  }, [stripe, fullName, values, setCcData, setTokenError, usingWallet, setUsingWallet]);
  useStepInjectorSetBeforeSubmit(handleBeforeSubmit);

  const setSubmitting = useSetSubmitting();
  const [payReq, setPayReq] = useState<stripe.paymentRequest.StripePaymentRequest>();
  useEffect(() => {
    if (!stripe) return;

    const paymentRequest = stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: 'Dopple',
        amount: 0,
        pending: true,
      },
      requestPayerEmail: true,
      requestPayerName: true,
    });

    paymentRequest.on('token', async ({ complete, token }) => {
      if (token.card?.funding === 'prepaid') {
        setTokenError(PREPAID_CARD_ERROR);
        complete('fail');
        return;
      }

      setSubmitting(true);
      try {
        await updateSelfInfo({
          stripe_token: token.id,
        });
        complete('success');
        setUsingWallet(true);
        setValues({
          stripe_payment_source_address_city: ' ',
          stripe_payment_source_address_line1: ' ',
          stripe_payment_source_address_state: ' ',
          stripe_payment_source_address_zip: '00000',
        }); // set dummy address to bypass form validation
        submitForm();
      } catch (error) {
        complete('fail');
        setSubmitting(false);
        const errorMsg = await decodeErrorResponse(error.response, error.message, 'stripe_token');
        setTokenError(errorMsg);
        captureException(error, s => s.setExtra('errorMsg', errorMsg));
      }
    });

    paymentRequest.canMakePayment().then(result => {
      setPayReq(result ? paymentRequest : undefined);
    });
  }, [stripe, setPayReq]);

  return (
    <StepWrapper className="font-nunito">
      <StepHeader>Add payment</StepHeader>
      <StyledStepBodyWrapper>
        <CcRef ref={ccRef} />
        {payReq && <PaymentRequestButtonElement paymentRequest={payReq} />}
        <div>
          <StripeInputContainer label="Credit Card Number" isBlack hasError={Boolean(getStripeError('cardNumber'))}>
            <CardNumberElement
              style={stripeInputStyles}
              className="stripe-input"
              onChange={handleStripeChange}
              onBlur={handleStripeBlur}
            />
          </StripeInputContainer>
          <ErrorMsg>{getStripeError('cardNumber')}</ErrorMsg>
        </div>
        <Row>
          <div>
            <StripeInputContainer label="Expiration" isBlack hasError={Boolean(getStripeError('cardExpiry'))}>
              <CardExpiryElement
                style={stripeInputStyles}
                className="stripe-input"
                onChange={handleStripeChange}
                onBlur={handleStripeBlur}
              />
            </StripeInputContainer>
            <ErrorMsg>{getStripeError('cardExpiry')}</ErrorMsg>
          </div>
          <div>
            <StripeInputContainer label="CVC" isBlack hasError={Boolean(getStripeError('cardCvc'))}>
              <CardCVCElement
                style={stripeInputStyles}
                className="stripe-input"
                onChange={handleStripeChange}
                onBlur={handleStripeBlur}
              />
            </StripeInputContainer>
            <ErrorMsg>{getStripeError('cardCvc')}</ErrorMsg>
          </div>
        </Row>
        <StripeLogoImg />

        <CheckboxLabel>
          <input type="checkbox" checked={isSameAsShipping} onChange={e => setIsSameAsShipping(e.target.checked)} />
          Use shipping address as billing address
        </CheckboxLabel>
        {!isSameAsShipping && (
          <>
            <div>
              <Field
                name="stripe_payment_source_address_line1"
                label="Billing Address"
                labelWrapperClass="step-label"
                placeholder="Enter billing address"
                value={values.stripe_payment_source_address_line1}
                component={InputField}
                showError={getError('stripe_payment_source_address_line1')}
                isTouchable
              />
              <ErrorMsg>{getError('stripe_payment_source_address_line1')}</ErrorMsg>
            </div>
            <div>
              <Field
                name="stripe_payment_source_address_line2"
                label="Billing Address (Line 2)"
                labelWrapperClass="step-label"
                wrapperClass="step-input"
                placeholder="Enter billing address line 2"
                component={InputField}
                value={values.stripe_payment_source_address_line2}
                showError={getError('stripe_payment_source_address_line2')}
              />
              <ErrorMsg>{getError('stripe_payment_source_address_line2')}</ErrorMsg>
            </div>
            <div>
              <Field
                name="stripe_payment_source_address_city"
                label="City"
                labelWrapperClass="step-label"
                wrapperClass="step-input"
                placeholder="Enter city"
                component={InputField}
                value={values.stripe_payment_source_address_city}
                showError={getError('stripe_payment_source_address_city')}
                isTouchable
              />
              <ErrorMsg>{getError('stripe_payment_source_address_city')}</ErrorMsg>
            </div>
            <Row>
              <div>
                <Field
                  name="stripe_payment_source_address_state"
                  label="State"
                  labelWrapperClass="step-label"
                  wrapperClass="step-input"
                  placeholder="Enter state"
                  component={SelectField}
                  options={states}
                  value={values.stripe_payment_source_address_state}
                  showError={getError('stripe_payment_source_address_state')}
                  isTouchable
                />
                <ErrorMsg>{getError('stripe_payment_source_address_state')}</ErrorMsg>
              </div>
              <div>
                <Field
                  name="stripe_payment_source_address_zip"
                  label="Zip"
                  labelWrapperClass="step-label"
                  wrapperClass="step-input"
                  placeholder="Enter zip"
                  component={InputField}
                  inputMode="numeric"
                  value={values.stripe_payment_source_address_zip}
                  showError={getError('stripe_payment_source_address_zip')}
                  isTouchable
                />
                <ErrorMsg>{getError('stripe_payment_source_address_zip')}</ErrorMsg>
              </div>
            </Row>
          </>
        )}

        <ErrorMsg>{tokenError}</ErrorMsg>
      </StyledStepBodyWrapper>
    </StepWrapper>
  );
});
export const PaymentInfo: FC = () => <_PaymentInfo />;

export const useOnPaymentInfoComplete = () => {
  const { currentKidId, updateKid } = useOnCompleteHookProvider();

  return async () => {
    return updateKid(currentKidId, {});
  };
};
