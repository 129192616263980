import React, { FC } from 'react';
import BabyGirlLooks from '../../../../../../public/static/images/style-quiz/new-style-quiz/glam-baby-girl.png';
import BigGirlLooks from '../../../../../../public/static/images/style-quiz/new-style-quiz/glam-big-girls.png';
import { babyClothesSizes } from '../../../../../constants/sizes';
import { useKidSize, useOnCompleteHookProvider } from '../../../context/QuizDataContext';
import LikenessSelector from '../../LikenessSelector';

export const GlamLikenessStep: FC = () => {
  const size = useKidSize();
  const GirlLooks = babyClothesSizes.includes(size) ? BabyGirlLooks : BigGirlLooks;
  return (
    <LikenessSelector name="glam" label="What do you think about this sixth look?" image={GirlLooks} submitOnChange />
  );
};

export const useOnComplete = () => {
  const { updateKid, currentKidId } = useOnCompleteHookProvider();
  return async (values: { glam: string }) => {
    return updateKid(currentKidId as string, values);
  };
};
