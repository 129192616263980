import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { queryFromUrl } from '../features/QueryStringArgsIntegration';
import { queryParamsToString } from '../utils/redirect';

type QueryParam = {
  name: string;
  value: string;
};

type ReturnType = {
  query: Record<string, string>;
  queryAsString: string;
  queryAsArray: QueryParam[];
};

const initialBlackList: string[] = ['quiz_type', 'price'];

const useQueryParams = (
  blacklistParam: string[] = initialBlackList,
  includeGiftsParams: boolean = false,
): ReturnType => {
  const router = useRouter();
  let blacklist = [...blacklistParam];
  const query = useMemo(() => {
    if (!blacklist || blacklist.length === 0) {
      blacklist = [...initialBlackList];
    }

    //avoid resend gifts curl params on route change
    if (!includeGiftsParams) {
      const queryKeys = Object.keys(router.query);

      if (Array.isArray(queryKeys)) {
        queryKeys.forEach(q => {
          if (q.includes('_price') || q.includes('_size') || q.includes('_gender')) {
            blacklist.push(q);
          }
        });
      }
    }

    const queryObj = { ...router.query };
    blacklist &&
      blacklist.forEach(item => {
        delete queryObj[item];
      });
    return {
      ...queryObj,
      ...queryFromUrl(router.asPath, blacklist),
    };
  }, [router.query, router.asPath, blacklist, includeGiftsParams]);

  return {
    query,
    queryAsString: queryParamsToString(query),
    queryAsArray: Object.keys(query).map(key => ({ name: key, value: query[key] })),
  };
};

export default useQueryParams;
