import React, { FC, createContext, useContext, useReducer } from 'react';
import client from '../../../../utils/api-client';
import { decodeErrorResponse } from '../../utils/decodeErrorResponse';

type IState = {
  busy: boolean;
  hasError: boolean;
  validated: boolean;
  errorMsg: string;
};

enum ActionTypes {
  SetValidatedStart,
  SetValidatedSuccess,
  SetValidatedFailed,
  RemoveCode,
  ClearError,
}

type Action = { type: ActionTypes; payload?: any };

const initialState: IState = {
  busy: false,
  hasError: false,
  validated: false,
  errorMsg: '',
};

const reducer = (state: IState, action: Action) => {
  const { type, payload } = action;
  if (type === ActionTypes.SetValidatedStart) {
    return {
      ...state,
      busy: true,
      hasError: false,
      validated: false,
      errorMsg: '',
    };
  }
  if (type === ActionTypes.SetValidatedSuccess) {
    return {
      ...state,
      busy: false,
      hasError: false,
      validated: true,
      errorMsg: '',
    };
  }
  if (type === ActionTypes.SetValidatedFailed) {
    return {
      ...state,
      validated: false,
      busy: false,
      hasError: true,
      errorMsg: payload?.errorMessage || 'Invalid code.',
    };
  }
  if (type === ActionTypes.RemoveCode) {
    return {
      ...state,
      validated: false,
      hasError: false,
      errorMsg: '',
    };
  }
  if (type === ActionTypes.ClearError) {
    return {
      ...state,
      hasError: false,
      errorMsg: '',
    };
  }
  return state;
};

const CheckableCodeContext = createContext<{ state: IState; dispatch: any; type: 'coupons' | 'giftdrops' }>({
  state: initialState,
  type: 'coupons',
  dispatch: () => null,
});

export const CheckableCodeContextProvider: FC<{ children: any; type: 'coupons' | 'giftdrops' }> = ({
  children,
  type,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return <CheckableCodeContext.Provider value={{ state, dispatch, type }}>{children}</CheckableCodeContext.Provider>;
};

export const useCheckableCodeContext = () => {
  const checkableCodeContext = useContext(CheckableCodeContext);
  return checkableCodeContext;
};

export const useBusy = () => {
  const {
    state: { busy },
  } = useCheckableCodeContext();
  return busy;
};

export const useHasError = () => {
  const {
    state: { hasError },
  } = useCheckableCodeContext();
  return hasError;
};
export const useClearError = () => {
  const { dispatch } = useCheckableCodeContext();
  return () => {
    dispatch({ type: ActionTypes.ClearError });
  };
};
export const useValidated = () => {
  const {
    state: { validated },
  } = useCheckableCodeContext();
  return validated;
};

export const useType = () => {
  const { type } = useCheckableCodeContext();
  return type;
};

export const useValidateCode = () => {
  const { dispatch, type } = useCheckableCodeContext();
  return async (codeValue: string, responseSetter: (response: any) => void) => {
    dispatch({ type: ActionTypes.SetValidatedStart });
    try {
      const response = await client<any>(`api/${type}/${codeValue}/`);
      responseSetter(response);
      dispatch({ type: ActionTypes.SetValidatedSuccess });
      return response;
    } catch (e) {
      const errorMessage = await decodeErrorResponse(e.response);
      dispatch({ type: ActionTypes.SetValidatedFailed, payload: { errorMessage } });
      return { response: { error: errorMessage, status: e?.response?.status } };
    }
  };
};

export const useRemoveCode = () => {
  const { dispatch } = useContext(CheckableCodeContext);
  return (responseSetter: (response: any) => void) => {
    responseSetter(null);
    dispatch({ type: ActionTypes.RemoveCode });
  };
};
