import React, { createContext, FC, useCallback, useContext, useEffect, useReducer } from 'react';
import { useUser } from '../../../../utils/auth-client';
import { StepId, stepIdToIndexMap } from '../../constants/steps';
import {
  createKid as createKidApi,
  KidDataResponse,
  loadAllKidsData,
  updateKid as updateKidApi,
} from '../../services/KidService';
import {
  Address,
  createAddress as createAddressApi,
  MeDataResponse,
  nestFlatMeDataToSteps,
  updateSelfInfo as updateSelfInfoApi,
} from '../../services/MeService';
import { GenderValues } from '../../types';
import { reducer } from './QuizReducer';

export type IQuizValues = {
  [stepId: string]: {
    [fieldName: string]: any;
  };
};

export type QuizStateContextValues = {
  values: IQuizValues;
  prevValues?: IQuizValues;
  currentStepId: StepId;
  loadedKids: KidDataResponse[];
  currentKidId: string | null;
  selfInfo: MeDataResponse | undefined;
  isLoading: boolean;
  isSubmitting?: boolean;
  selectedKids: string[];
};

const quizDataDefaultState: QuizStateContextValues = {
  values: {},
  prevValues: {},
  currentStepId: StepId.SelfInfo,
  loadedKids: [],
  currentKidId: null,
  selfInfo: undefined,
  isLoading: true,
  selectedKids: [],
};

export const QuizContext = createContext<{ state: QuizStateContextValues; dispatch: any }>({
  state: quizDataDefaultState,
  dispatch: () => null,
});

export enum ActionTypes {
  SetValuesForStep = 'SetValuesForStep',
  SetAllValues = 'SetAllValues',
  WipeAllValues = 'WipeAllValues',
  GoBackOrToIndex = 'GoBackOrToIndex',
  UpdateKidInfo = 'UpdateKidInfo',
  AllKidsLoaded = 'AllKidsLoaded',
  UpdateSelfInfo = 'UpdateSelfInfo',
  SetLoading = 'SetLoading',
  CreateKid = 'CreateKid',
  SetSubmitting = 'SetSubmitting',
  SelectKid = 'SelectKid',
  RemoveFromSelectedKids = 'RemoveFromSelectedKids',
  SetCurrentStepData = 'SetCurrentStepData',
}

export type ShippingFormValues = {
  address_1: string;
  address_2: string;
  city: string;
  state: string;
  zip_code: string;
  addressErrors?: boolean;
};

export const QuizContextProvider: FC<{ children: any }> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, quizDataDefaultState);
  const { currentUser, isLoading } = useUser();

  // load initial info
  useEffect(() => {
    if (!isLoading && currentUser) {
      loadAllKidsData()
        .then(kidsInfo => {
          dispatch({ type: ActionTypes.UpdateSelfInfo, payload: { data: currentUser } });
          dispatch({ type: ActionTypes.AllKidsLoaded, payload: { data: kidsInfo } });
        })
        .finally(() => {
          dispatch({ type: ActionTypes.SetLoading });
        });
    }
  }, [isLoading, currentUser]);

  return <QuizContext.Provider value={{ state, dispatch }}>{children}</QuizContext.Provider>;
};

// TODO: All these hooks defined in this module could be replaced for this one hook to have a cleaner API.
type UseQuizReturnType = Pick<QuizStateContextValues, 'selectedKids'> & {
  selectKid: (kidId: string) => void;
  removeSelectedKid: (kidId: string) => void;
};

export function useQuiz(): UseQuizReturnType {
  const context = useContext(QuizContext);
  if (context === undefined) {
    throw new Error('useQuiz must be used within a QuizContextProvider');
  }

  const { state, dispatch } = context;
  const { selectedKids } = state;
  const selectKid = useCallback((kidId: string) => dispatch({ type: ActionTypes.SelectKid, kidId }), []);
  const removeSelectedKid = useCallback(
    (kidId: string) => dispatch({ type: ActionTypes.RemoveFromSelectedKids, kidId }),
    [],
  );
  return {
    selectKid,
    selectedKids,
    removeSelectedKid,
  };
}

export const useIsLoading = () => {
  const context = useContext(QuizContext);
  if (context === undefined) {
    throw new Error('useIsLoading must be used within a QuizContextProvider');
  }
  return context.state.isLoading;
};
export const useSetSubmitting = () => {
  const { dispatch } = useContext(QuizContext);
  return (isSubmitting: boolean) => {
    dispatch({ type: ActionTypes.SetSubmitting, isSubmitting });
  };
};
export const useSetCurrentStepData = () => {
  const { dispatch } = useContext(QuizContext);
  return (stepIndex: number, values) => {
    dispatch({ type: ActionTypes.SetCurrentStepData, payload: { stepIndex, data: values } });
  };
};
export const useIsSubmitting = () => {
  const context = useContext(QuizContext);
  if (context === undefined) {
    throw new Error('useIsSubmitting must be used within a QuizContextProvider');
  }
  return context.state.isSubmitting;
};

export const useQuizValues = () => {
  const context = useContext(QuizContext);
  if (context === undefined) {
    throw new Error('useQuizValues must be used within a QuizContextProvider');
  }
  return context.state.values;
};

export const useSelfInfo = () => {
  const context = useContext(QuizContext);
  if (context === undefined) {
    throw new Error('useSelfInfo must be used within a QuizContextProvider');
  }
  return context.state.selfInfo;
};

export const useLoadedKids = () => {
  const context = useContext(QuizContext);
  if (context === undefined) {
    throw new Error('useLoadedKids must be used within a QuizContextProvider');
  }
  return context.state.loadedKids;
};

export const useCurrentKidId = () => {
  const context = useContext(QuizContext);
  if (context === undefined) {
    throw new Error('useCurrentKidId must be used within a QuizContextProvider');
  }
  return context.state.currentKidId;
};

export const useGoBackOrToIndex = () => {
  const context = useContext(QuizContext);
  if (context === undefined) {
    throw new Error('useGoBackOrToIndex must be used within a QuizContextProvider');
  }
  return (index?: number) => {
    context.dispatch({ type: ActionTypes.GoBackOrToIndex, payload: { index } });
  };
};

export const useSetValuesForStep = () => {
  const context = useContext(QuizContext);
  if (context === undefined) {
    throw new Error('useSetValuesForStep must be used within a QuizContextProvider');
  }
  return (stepId: string, data: { [fieldName: string]: any }) => {
    context.dispatch({ type: ActionTypes.SetValuesForStep, payload: { stepId, data } });
  };
};

export const useUpdateSelfInfo = () => {
  const context = useContext(QuizContext);
  if (context === undefined) {
    throw new Error('useUpdateSelfInfo must be used within a QuizContextProvider');
  }
  return async (data: MeDataResponse) => {
    context.dispatch({ type: ActionTypes.SetSubmitting, isSubmitting: true });
    const updatedInfo = await updateSelfInfoApi(data);
    context.dispatch({ type: ActionTypes.SetSubmitting, isSubmitting: false });
    context.dispatch({ type: ActionTypes.UpdateSelfInfo, payload: { data: updatedInfo } });
    return updatedInfo;
  };
};

export const useCreateKid = () => {
  const context = useContext(QuizContext);
  if (context === undefined) {
    throw new Error('useCreateKid must be used within a QuizContextProvider');
  }
  return async (name: string) => {
    context.dispatch({ type: ActionTypes.SetSubmitting, isSubmitting: true });
    const createdKid = await createKidApi(name);
    context.dispatch({ type: ActionTypes.SetSubmitting, isSubmitting: false });
    context.dispatch({ type: ActionTypes.CreateKid, payload: { data: createdKid } });
    return createdKid;
  };
};

export const useUpdateKid = () => {
  const context = useContext(QuizContext);
  if (context === undefined) {
    throw new Error('useUpdateKid must be used within a QuizContextProvider');
  }
  return async (id: string, data: KidDataResponse, skipApiCall: boolean = false) => {
    if (!skipApiCall) {
      context.dispatch({ type: ActionTypes.SetSubmitting, isSubmitting: true });
      await updateKidApi(id, { last_quiz_step: stepIdToIndexMap.get(context.state.currentStepId), ...data });
    }
    context.dispatch({ type: ActionTypes.UpdateKidInfo, payload: { id, data } });
    context.dispatch({ type: ActionTypes.SetSubmitting, isSubmitting: false });
  };
};

export const useSetAllValues = () => {
  const context = useContext(QuizContext);
  if (context === undefined) {
    throw new Error('useSetAllValues must be used within a QuizContextProvider');
  }
  return async (values: IQuizValues) => {
    context.dispatch({ type: ActionTypes.SetAllValues, payload: { values } });
  };
};

export const useSetLoadedKids = () => {
  const context = useContext(QuizContext);
  if (context === undefined) {
    throw new Error('useSetAllValues must be used within a QuizContextProvider');
  }
  return (data: KidDataResponse[]) => {
    context.dispatch({ type: ActionTypes.AllKidsLoaded, payload: { data } });
  };
};

export const useCreateAddress = () => {
  const context = useContext(QuizContext);
  if (context === undefined) {
    throw new Error('useCreateAddress must be used within a QuizContextProvider');
  }
  return async (address: Address) => {
    context.dispatch({ type: ActionTypes.SetSubmitting, isSubmitting: true });
    const createdAddress = await createAddressApi(address);
    context.dispatch({ type: ActionTypes.SetSubmitting, isSubmitting: false });
    const data = { ...context.state.selfInfo, address: createdAddress };
    context.dispatch({ type: ActionTypes.UpdateSelfInfo, payload: { data } });
    context.dispatch({
      type: ActionTypes.SetAllValues,
      payload: {
        values: nestFlatMeDataToSteps(context.state.values, context.state.selfInfo as MeDataResponse),
      },
    });
  };
};

export const useWipeAllValues = () => {
  const context = useContext(QuizContext);
  if (context === undefined) {
    throw new Error('useWipeAllValues must be used within a QuizContextProvider');
  }
  return () => {
    context.dispatch({ type: ActionTypes.WipeAllValues });
  };
};

export const useKidGender = () => {
  const values = useQuizValues();
  const gender = values.kid && values.kid.gender;
  if (gender === GenderValues.OTHER || gender === GenderValues.UNKNOWN) {
    return values.kid && values.kid.gender_preference;
  }
  return gender;
};

export const useKidSize = () => {
  const values = useQuizValues();
  const size = values.kid && values.kid.top_size;
  return size;
};

export const useCurrentStepId = () => {
  const context = useContext(QuizContext);
  if (context === undefined) {
    throw new Error('useCurrentStepId must be used within a QuizContextProvider');
  }
  return context.state.currentStepId;
};

export const useOnCompleteHookProvider = () => {
  const isLoading = useIsLoading();
  const setSubmitting = useSetSubmitting();
  const quizValues = useQuizValues();
  const selfInfo = useSelfInfo();
  const loadedKids = useLoadedKids();
  const currentKidId = useCurrentKidId();
  const goBackOrToIndex = useGoBackOrToIndex();
  const setValuesForStep = useSetValuesForStep();
  const updateSelfInfo = useUpdateSelfInfo();
  const createKid = useCreateKid();
  const updateKid = useUpdateKid();
  const setAllValues = useSetAllValues();
  const createAddress = useCreateAddress();
  const wipeAllValues = useWipeAllValues();
  const kidGender = useKidGender();
  const currentStepId = useCurrentStepId();
  const { selectKid, selectedKids } = useQuiz();

  return {
    isLoading,
    setSubmitting,
    quizValues,
    selfInfo,
    loadedKids,
    currentKidId,
    goBackOrToIndex,
    setValuesForStep,
    updateSelfInfo,
    createKid,
    updateKid,
    setAllValues,
    createAddress,
    wipeAllValues,
    kidGender,
    currentStepId,
    selectKid,
    selectedKids,
  };
};
