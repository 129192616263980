import { useFormikContext } from 'formik';
import React, { FC, memo, MouseEvent, useCallback, useEffect, useMemo } from 'react';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import arrowLeftDisabledImg from '../../../../../public/static/images/arrow-left-disabled.svg';
import arrowLeftImg from '../../../../../public/static/images/arrow-left.svg';
import checked from '../../../../../public/static/images/checkmark.svg';
import { SectionType, StepId, stepIdToIndexMap, stepSequence } from '../../constants/steps';
import { useCurrentStepId, useGoBackOrToIndex } from '../../context/QuizDataContext';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 81px;
  background-color: #fff;
  box-shadow: 0 8px 8px 0 rgba(241, 241, 241, 0.25);
  z-index: 1;

  &.sticky {
    ${up('xl')} {
      position: sticky;
      top: 80px;
      width: 100%;
      -webkit-transition: top 5s;
      -moz-transition: top 5s;
      transition: top 5s;
    }
  }
`;

const Bar = styled.div`
  height: 10px;
  background-color: #efefef;
  margin: 0 10px;
  width: 100%;
  border-radius: 8px;
  .bar-fill {
    border-radius: 8px;
    height: 100%;
    background-color: #345a82;
  }
`;

const SectionText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const DoneImage = styled.img`
  margin-left: 5px;
`;

const ProgressSectionWrapper = styled.div<{ isActive: boolean }>`
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: ${props => (!props.isActive ? 'none' : 'flex')};

  @media screen and (min-width: 500px) {
    display: flex;
  }

  .inner {
    ${props => (props.isActive ? '' : 'opacity: .4;')}
  }
`;

const ProgressSectionsWrapper = styled.div`
  height: 81px;
  width: 100%;
  display: flex;
  align-items: center;

  .go-back-image {
    margin-left: 5px;
    max-height: 80px;
    cursor: pointer;
    height: fit-content;
  }

  .go-back-image-disabled {
    cursor: auto;
  }

  .go-forward-image {
    transform: rotateY(180deg);
    margin-right: 5px;
  }
`;

type ProgressSectionProps = {
  section: SectionType;
  currentStep: number;
};

const ProgressSection: FC<ProgressSectionProps> = memo(({ section, currentStep }) => {
  const isActive = currentStep >= section.start && currentStep <= section.end;
  return (
    <ProgressSectionWrapper isActive={isActive}>
      <div className={'inner'}>
        <SectionText>
          {section.text}
          {isActive ? <img alt={section.text} src={section.image.icon} /> : ''}
        </SectionText>
        {isActive ? <img alt={section.text} src={section.image.underline} /> : ''}
      </div>
      {currentStep > section.end ? <DoneImage alt="done" src={checked} width="20px" /> : ''}
    </ProgressSectionWrapper>
  );
});

export const ProgressBar: FC<{
  stepSections: SectionType[];
  navDisabled: boolean;
}> = memo(({ stepSections, navDisabled }) => {
  const goBackOrToIndex = useGoBackOrToIndex();
  const { submitForm, isSubmitting } = useFormikContext();
  const currentStepId = useCurrentStepId();
  const currentStepIndex = stepIdToIndexMap.get(currentStepId);

  const handleGoBack = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      goBackOrToIndex();
    },
    [goBackOrToIndex],
  );

  const handleGoForward = useCallback(
    async (e: MouseEvent) => {
      if (isSubmitting) {
        return;
      }
      e.preventDefault();
      e.stopPropagation();
      await submitForm();
    },
    [isSubmitting],
  );

  const isCheckoutOrLastStep = useMemo(() => {
    return stepIdToIndexMap.get(currentStepId) >= stepIdToIndexMap.get(StepId.ConfirmOrder);
  }, [currentStepId]);

  useEffect(() => {
    const header = document.getElementById('progress-bar-quiz');
    const sticky = header.offsetTop;
    window.addEventListener('scroll', () => {
      if (window.pageYOffset > sticky) {
        header.classList.add('sticky');
      } else {
        header.classList.remove('sticky');
      }
    });
  }, []);

  return (
    <Container id="progress-bar-quiz">
      <ProgressSectionsWrapper data-testid="progress-sections">
        {currentStepId !== StepId.ThankYouStep &&
          (navDisabled ? (
            <img src={arrowLeftDisabledImg} alt="Go back" className="go-back-image go-back-image-disabled" />
          ) : (
            <img src={arrowLeftImg} onClick={handleGoBack} alt="Go back" className="go-back-image" />
          ))}
        {stepSections.map(section => (
          <ProgressSection section={section} key={section.text} currentStep={currentStepIndex} />
        ))}
        {!isCheckoutOrLastStep && (
          <img
            src={isSubmitting ? arrowLeftDisabledImg : arrowLeftImg}
            onClick={handleGoForward}
            alt="Go forward"
            className={`go-back-image go-forward-image ${isSubmitting ? 'go-back-image-disabled' : ''}`}
          />
        )}
      </ProgressSectionsWrapper>
      <Bar>
        <div
          className={'bar-fill'}
          data-testid="bar-fill"
          style={{ width: `${(currentStepIndex / stepSequence.length) * 100}%` }}
        />
      </Bar>
    </Container>
  );
});
