import client from '../utils/api-client';
import { User } from '../types';

type PasswordChange = {
  old_password: string;
  password: string;
  password_confirmation: string;
};

export const patch = async (values: Partial<User & PasswordChange>) => {
  return client('api/users/me/', { body: values, method: 'PATCH' });
};

/**
 * Decodes a JWT token without verification
 */
function decodeToken(token: string) {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(c => {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );
    return JSON.parse(jsonPayload);
  } catch (e) {
    console.error('Error decoding token:', e);
    return null;
  }
}

/**
 * Validates a token using the appropriate endpoint based on its scope
 * @param token The request token to validate
 * @returns Promise with the validation result
 */
export async function validateToken(token: string) {
  try {
    // Decode token to check scope
    const decodedToken = decodeToken(token);
    const scope = decodedToken?.sub || 'social_auth';

    // Choose endpoint based on token scope
    let endpoint;
    if (scope === 'wishlist_auth') {
      endpoint = 'api/v1/auth/wishlist/validate/';
    } else if (scope === 'social_auth') {
      endpoint = 'api/v1/auth/social/validate/';
    } else {
      throw new Error(`Unknown token scope: ${scope}`);
    }

    // Use direct fetch with no credentials for both endpoints
    const apiUrl = process.env.API_URL;
    if (!apiUrl) {
      throw new Error('API URL is not configured');
    }

    const response = await fetch(`${apiUrl}/${endpoint}?rt=${token}`, {
      method: 'GET',
      credentials: 'omit', // Don't send credentials
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`Token validation failed: ${response.status}`);
    }

    const userData = await response.json();
    return { valid: true, user: userData };
  } catch (error) {
    console.error('Token validation failed:', error);
    return { valid: false, error };
  }
}
