import Button from './Button';
import styled from 'styled-components';
import React, { FC, ReactNode } from 'react';
import Space, { Align, SpaceSizesEnum } from '../Space/Space';

export const StyledButton = styled(Button)<{ color: string; disabled: boolean }>`
  width: 100%;
  font-weight: normal;
  background: ${({ color }) => color};
  border-color: ${({ color, disabled }) => (disabled ? '#f3f2f3' : color)};
  font-size: 0.61rem;
`;

type SocialButtonProps = {
  icon?: ReactNode;
  text: string;
  color?: string;
  fontColor?: string;
  disabled?: boolean;
  isLoading?: boolean;
  type?: 'button' | 'submit' | 'reset';
};

export const SocialButton: FC<SocialButtonProps> = ({
  fontColor,
  icon,
  text,
  color,
  disabled,
  ...rest
}): JSX.Element => {
  return (
    <StyledButton color={color} disabled={disabled} {...rest}>
      <Space style={{ color: fontColor || 'white' }} size={SpaceSizesEnum.medium} align={Align.center}>
        {icon}
        {text}
      </Space>
    </StyledButton>
  );
};

export default SocialButton;
