interface Option {
  value: string;
  label: string;
}

const clothesBudget: Option[] = [
  { value: 'Under $20', label: 'Under $20' },
  { value: '$20-$35', label: '$20-$35' },
  { value: '$36-$49', label: '$36-$49' },
  { value: '$50 & up', label: '$50 & up' },
];

const shoesBudget: Option[] = [
  { value: 'Under $25', label: 'Under $25' },
  { value: '$25-$39', label: '$25-$39' },
  { value: '$40 & up', label: '$40 & up' },
];

const outfitBudget: Option[] = [
  { value: 'Under $40', label: 'Under $40' },
  { value: '$40-$59', label: '$40-$59' },
  { value: '$60 & up', label: '$60 & up' },
];

const numItems: Option[] = [
  { value: '2-4', label: '2-4 items' },
  { value: '6-8', label: '6-8 items' },
  { value: '8+', label: '8+ items' },
];

export { clothesBudget, shoesBudget, outfitBudget, numItems };
