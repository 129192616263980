import { Choice } from '../types';

export const babyClothesSizes: Array<string> = [
  '0-3 months',
  '3-6 months',
  '6-9 months',
  '9-12 months',
  '12-18 months',
  '18-24 months',
  '2T',
  '3T',
];

const allClothesSizes: Choice[] = [
  { value: '0-3 months', label: '0-3 months' },
  { value: '3-6 months', label: '3-6 months' },
  { value: '6-9 months', label: '6-9 months' },
  { value: '9-12 months', label: '9-12 months' },
  { value: '12-18 months', label: '12-18 months' },
  { value: '18-24 months', label: '18-24 months' },
  { value: '2T', label: '2T' },
  { value: '3T', label: '3T' },
  { value: '4T', label: '4T' },
  { value: '5T', label: '5T' },
  { value: '6T', label: '6Y' },
  { value: '7T', label: '7Y' },
  { value: '8T', label: '8Y' },
  { value: '9T', label: '9Y' },
  { value: '10T', label: '10Y' },
  { value: '11Y', label: '11Y' },
  { value: '12Y', label: '12Y' },
  { value: '13Y', label: '13Y' },
  { value: '14Y', label: '14Y' },
  // This option is temporarily unsupported.
  // { value: 'other', label: 'Other ' },
];

const notAvailableClothesSizes = ['11Y', '12Y', '13Y', '14Y'];

export const clothesSizes = allClothesSizes.filter(size => !notAvailableClothesSizes.includes(size.value));

const allShoesSizes: Choice[] = [
  { value: '0', label: 'Baby 0' },
  { value: '0.5', label: 'Baby 0.5' },
  { value: '1', label: 'Baby 1' },
  { value: '1.5', label: 'Baby 1.5' },
  { value: '2', label: 'Baby 2' },
  { value: '2.5', label: 'Baby 2.5' },
  { value: '3', label: 'Toddler 3' },
  { value: '3.5', label: 'Toddler 3.5' },
  { value: '4', label: 'Toddler 4' },
  { value: '4.5', label: 'Toddler 4.5' },
  { value: '5', label: 'Toddler 5' },
  { value: '5.5', label: 'Toddler 5.5' },
  { value: '6', label: 'Toddler 6' },
  { value: '6.5', label: 'Toddler 6.5' },
  { value: '7', label: 'Toddler 7' },
  { value: '7.5', label: 'Toddler 7.5' },
  { value: '8', label: 'Toddler 8' },
  { value: '8.5', label: 'Toddler 8.5' },
  { value: '9', label: 'Toddler 9' },
  { value: '9.5', label: 'Toddler 9.5' },
  { value: '10', label: 'Toddler 10' },
  { value: '10.5', label: 'Little Kids 10.5' },
  { value: '11', label: 'Little Kids 11' },
  { value: '11.5', label: 'Little Kids 11.5' },
  { value: '12', label: 'Little Kids 12' },
  { value: '12.5', label: 'Little Kids 12.5' },
  { value: '13', label: 'Little Kids 13' },
  { value: '13.5', label: 'Little Kids 13.5' },
  { value: 'K1', label: 'Little Kids 1' },
  { value: 'K1.5', label: 'Little Kids 1.5' },
  { value: 'K2', label: 'Little Kids 2' },
  { value: 'K2.5', label: 'Little Kids 2.5' },
  { value: 'K3', label: 'Little Kids 3' },
  { value: 'K3.5', label: 'Big Kids 3.5' },
  { value: 'K4', label: 'Big Kids 4' },
  { value: 'K4.5', label: 'Big Kids 4.5' },
  { value: 'K5', label: 'Big Kids 5' },
  { value: 'K5.5', label: 'Big Kids 5.5' },
  { value: 'K6', label: 'Big Kids 6' },
  { value: 'K6.5', label: 'Big Kids 6.5' },
  { value: 'K7', label: 'Big Kids 7' },

  // This option is temporarily unsupported.
  // { value: 'other', label: 'Other ' },
];

const notAvailableShoesSizes = ['K2.5', 'K3', 'K3.5', 'K4', 'K4.5', 'K5', 'K5.5', 'K6', 'K6.5', 'K7'];

export const shoesSizes = allShoesSizes.filter(size => !notAvailableShoesSizes.includes(size.value));

export const womenDressSizes: Choice[] = [
  { value: 'NA', label: 'N/A' },
  // { value: '0', label: '0' },
  // { value: '2', label: '2' },
  // { value: '4', label: '4' },
  // { value: '6', label: '6' },
  // { value: '8', label: '8' },
  // { value: '10', label: '10' },
  // { value: '12', label: '12' },
  // { value: '14', label: '14' },
  // { value: '16', label: '16' },
  // { value: '18', label: '18' },
  // { value: '20', label: '20' },
  { value: 'XS', label: 'XS' },
  { value: 'S', label: 'S' },
  { value: 'M', label: 'M' },
  { value: 'L', label: 'L' },
  { value: 'Other', label: 'Other' },
];
