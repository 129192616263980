import React, { memo } from 'react';
import styled from 'styled-components';

import SquigglyImage from '../../../../../public/static/images/home/squiggly/squiggly.svg';

const SquigglyImg = styled.img`
  height: 10px;
  margin-bottom: 39px;
`;

const Squiggly = props => <SquigglyImg src={SquigglyImage} alt="" {...props} />;

export default memo(Squiggly);
