import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { compose, not, isEmpty, filter, values, contains } from 'ramda';
import { eventBanners } from '../constants';

type EventBanner = {
  name: string;
  route: string;
  url: string;
};

type EventRedirectState = {
  isEventUri: boolean;
  eventBanner?: EventBanner;
};

const queryContains = (queryObj: object, criteria: string): boolean => {
  return compose<object, Array<string>, Array<string>, boolean, boolean>(
    not,
    isEmpty,
    filter(contains(criteria)),
    values,
  )(queryObj);
};

const useEventRedirect = (): EventRedirectState => {
  const router = useRouter();

  const uriObj = useMemo(() => {
    const eventBanner = eventBanners.find(event => queryContains(router.query, event.route));
    return {
      isEventUri: queryContains(router.query, '/event/'),
      eventBanner,
    };
  }, [router.query]);

  return { ...uriObj };
};

export default useEventRedirect;
