import { User } from '../types';
import { NextRouter } from 'next/router';
import { pathOr } from 'ramda';
import queryString from 'query-string';
import storage from './storage';
import { QUIZ_TYPE } from '../features/style-quiz/containers/SignUpPage';
import { ALL_URL_PARAMS_KEY } from '../features/Auth/containers/SignInPage';

export function queryParamsToString(
  queryParams: { [key: string]: string | string[] | undefined },
  blacklist?: string[],
) {
  const query = { ...queryParams };
  if (Array.isArray(blacklist)) {
    blacklist.forEach(key => {
      delete query[key];
    });
  }
  const qsRest = queryString.stringify(query);
  return qsRest.length > 0 ? `?${qsRest}` : '';
}

export function returnUrlWithAllQueryParams(url: string, query: { [key: string]: string | string[] | undefined }) {
  const qs = queryParamsToString(query);
  return `${url}${qs}`;
}

export function loginRedirect(user: User, router: NextRouter, additionalQuery: string) {
  const { next, return: returnUrl } = router.query;
  let allStoredParams = decodeURIComponent(storage.retrieve(ALL_URL_PARAMS_KEY) || '');
  allStoredParams = allStoredParams.replace(decodeURIComponent(`?return=${returnUrl}`), '&');
  const returnTo = returnUrl || storage.retrieve(QUIZ_TYPE);

  if (returnTo) {
    let fullUrl = `${returnTo}${additionalQuery.replace('?', '&')}${allStoredParams}`;
    if (fullUrl.indexOf('&') !== -1 && fullUrl.indexOf('?') === -1) {
      fullUrl = fullUrl.replace('&', '?');
    }
    router.push(fullUrl);
    storage.remove(QUIZ_TYPE);
    storage.remove(ALL_URL_PARAMS_KEY);
  } else {
    const returnUrl_ = (next || returnUrl) as string;
    let finalReturnUrl = returnUrl_
      ? `${returnUrl_.replace('&', '?')}${additionalQuery.replace('?', '&')}`
      : pathOr(false, ['can_refer'], user)
      ? '/dashboard/refer-a-friend'
      : '/dashboard/my-kiddos';
    if (finalReturnUrl.indexOf('&') !== -1 && finalReturnUrl.indexOf('?') === -1) {
      finalReturnUrl = finalReturnUrl.replace('&', '?');
    }
    router.push(finalReturnUrl);
    storage.remove(ALL_URL_PARAMS_KEY);
  }
}
