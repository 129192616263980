import { Field, Formik, FormikProps } from 'formik';
import jsCookie from 'js-cookie';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { down } from 'styled-breakpoints';
import styled from 'styled-components';
import url from 'url';
import * as Yup from 'yup';
import Alert, { AlertType } from '../../../../components/Alert';
import { FacebookTextButton } from '../../../../components/Buttons';
import GoogleTextButton from '../../../../components/Buttons/GoogleTextButton';
import { InputField } from '../../../../components/InputField';
import { cookieDomain, tokenCookieName } from '../../../../constants';
import { usePromoCode } from '../../../../contexts/PromoCode';
import { useRetainedUrlParams } from '../../../../contexts/RetainedUrlParamsContext';
import useQueryParams from '../../../../hooks/useQueryParams';
import useQuizType from '../../../../hooks/useQuizType';
import { createByEmail } from '../../../../services/auth';
import { logFBEvent } from '../../../../utils/analytics';
import { EmailButton } from '../../../Auth/components/EmailButton';

const API_URL = `${process.env.API_URL}`;

const EMAIL_LOGIN_URL = url.resolve(API_URL, '/login/email/');
export const FACEBOOK_LOGIN_URL = url.resolve(API_URL, '/login/facebook/');
export const GOOGLE_LOGIN_URL = url.resolve(API_URL, '/login/google-oauth2/');

export const REDIRECT_ERRORS = {
  INVALID_EMAIL: 'Invalid email address.',
  INVALID_SOCIAL_PROFILE: 'There was an issue connecting to your social account. Please try again.',
  SIGNIN_REQUIRED: 'You already have an account. Please login here.',
};

const FormContainer = styled.div`
  max-width: 300px;
  margin: 0 auto;
`;

const StyledAlert = styled(Alert)`
  width: inherit;
`;

const Container = styled.div`
  .step-header {
    display: none;
  }

  .quiz-step-header-container {
    width: 90%;
    margin: 30px auto;
    display: flex;
    flex-flow: column;
    align-content: center;
    justify-content: center;
    text-align: center;
    align-items: center;
    }
  }

  .quiz-step-header {
    font-size: 30px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: 0.6px;
    margin-bottom: 10px;
    ${down('sm')} {
      font-size: 18.5px;
    }
  }

  .quiz-step-subheader {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.76;
    letter-spacing: 0.28px;
    max-width: 32ch;
    ${down('sm')} {
      font-size: 12px;
    }
  }

  .underlined {
    text-decoration: underline;
  }

  .style-quiz {
    margin: 2rem auto 3rem auto;

    hr {
      margin: 1rem 0;
      border-color: rgba(94, 97, 100, 0.1);
      border-width: 1px;
    }

    .social-header {
      font-size: small;
    }

    button[type='submit'],
    button.social {
      margin: 1em 0;
      width: 100%;
    }
  }
  .dopple-how-it-works{
    .dopple-how-it-works-container{
      margin: auto;
      width: 60%;
      max-width: 525px;
      ${down('sm')} {
        width: 96%;
        max-width: 400px;
        font-size: 0.6rem;
      }
    }
     text-align: center;
    p{
      padding: 10px 0;
      ${down('sm')} {
        font-size: 0.8rem;
      }
    }
    i{
      color: black;
    }
   }
`;

export const OrSeparator = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8rem;
  opacity: 0.7;
  margin: 20px 0 18px 0;
  .div {
    width: 38%;
  }
`;

export const ShowError = styled.div`
  padding: 0.8rem 0;
  color: ${({ theme }) => theme.colors.primaryDanger};
  font-size: 14px;
`;

type FormValues = {
  email: string;
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Enter a valid email address')
    .required(),
});

const afterSubmit = () => {
  logFBEvent('track', 'Lead');
};

const SignUpForm = () => {
  const router = useRouter();
  const [returnToPrefix, setReturnToPrefix] = useState<string>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { queryAsString } = useQueryParams(['error', 'quiz_type', 'return']);
  const { isBonpointQuiz } = useQuizType();
  const { state: storedParams } = useRetainedUrlParams();
  const { promoCodes } = usePromoCode();

  // thanks to https://github.com/jaredpalmer/formik/issues/556#issuecomment-472158007
  const formEl = useRef(null);

  const doHtmlFormPost = () => {
    afterSubmit();
    formEl.current.submit();
  };

  const params = {
    ...router.query,
    ...storedParams,
    d_coupon: router.query.d_coupon || promoCodes.quiz,
  };
  const {
    error: redirectError = false,
    fbuy_ref_code: referralCode = '',
    utm_source: utmSource = '',
    utm_medium: utmMedium = '',
    utm_campaign: utmCampaign = '',
    utm_term: utmTerm = '',
    utm_content: utmContent = '',
    d_coupon: couponCode = '',
    quiz,
    return: returnParam = '',
  } = params as any;

  useEffect(() => {
    const prefix =
      document && document.location
        ? (function() {
            const location = document.location;
            const port = location.port === '80' ? '' : `:${location.port}`;
            return `${location.protocol}//${location.hostname}${port}`;
          })()
        : '';

    setReturnToPrefix(prefix);
  }, []);

  const isQuizDisabled = useMemo(() => {
    return (quiz as string) === 'false';
  }, [quiz]);

  let returnTo = !isBonpointQuiz
    ? `${returnToPrefix}/quiz${queryAsString}`
    : `${returnToPrefix}/quiz/bonpoint/${queryAsString}`;

  if (isQuizDisabled && returnParam) {
    returnTo = `${returnParam}${queryAsString}`;
  }

  const loginHandler = useCallback(
    async (email, setIsSubmitting) => {
      setIsSubmitting(true);
      try {
        const user = await createByEmail(email);
        const cookieOptions = cookieDomain ? { domain: cookieDomain } : undefined;
        jsCookie.set(tokenCookieName, user.auth_token, cookieOptions);
        afterSubmit();
        setIsSubmitting(false);
        router.push(returnTo);
      } catch (e) {
        setIsSubmitting(false);
        if (e.response?.status === 400) {
          router.push(`/sign-in?return=${returnTo}&error=SIGNIN_REQUIRED`);
        }
      }
    },
    [createByEmail, afterSubmit, returnTo],
  );

  const campaignRelatedFields = (
    <>
      <input type="hidden" name="referral_code" value={referralCode} />
      <input type="hidden" name="coupon_code" value={couponCode} />
      <input type="hidden" name="utm_source" value={utmSource} />
      <input type="hidden" name="utm_medium" value={utmMedium} />
      <input type="hidden" name="utm_campaign" value={utmCampaign} />
      <input type="hidden" name="utm_term" value={utmTerm} />
      <input type="hidden" name="utm_content" value={utmContent} />
      <input type="hidden" name="return_to" value={returnTo} />
    </>
  );

  return (
    <Container>
      <div className="quiz-step-header-container">
        <div className="quiz-step-header">Your Dopple Journey Starts Here</div>
      </div>

      <div className="style-quiz">
        <FormContainer>
          {redirectError && redirectError !== 'SIGNIN_REQUIRED' && (
            <StyledAlert type={AlertType.ERROR}>{REDIRECT_ERRORS[redirectError as string]}</StyledAlert>
          )}

          <Formik
            initialValues={{ email: '' }}
            validationSchema={validationSchema}
            isInitialValid={false}
            onSubmit={doHtmlFormPost}
            enableReinitialize
          >
            {({ values: { email }, isValid, errors }: FormikProps<FormValues>) => (
              <form method="POST" ref={formEl} action={EMAIL_LOGIN_URL} onSubmit={() => setIsSubmitting(true)}>
                <Field
                  name="email"
                  component={InputField}
                  type="email"
                  label=""
                  placeholder="YOUR EMAIL HERE"
                  value={email?.toLowerCase()}
                  wrapperClass="full"
                  showError={Boolean(errors.email)}
                />
                {errors.email && <ShowError>{errors.email}</ShowError>}
                <br />
                {campaignRelatedFields}
                <EmailButton
                  name="submit"
                  text={isQuizDisabled ? 'SIGNUP' : 'Take the Quiz'}
                  type={isQuizDisabled ? 'button' : 'submit'}
                  disabled={!isValid || isSubmitting}
                  isLoading={isSubmitting}
                  onClick={isQuizDisabled ? () => loginHandler(email, setIsSubmitting) : undefined}
                  hideIcon
                />
              </form>
            )}
          </Formik>
          <OrSeparator>
            <div className="div">
              <hr />
            </div>
            <div>OR</div>
            <div className="div">
              <hr />
            </div>
          </OrSeparator>
          <form className="step-form" action={GOOGLE_LOGIN_URL} method="POST" onSubmit={afterSubmit}>
            {campaignRelatedFields}
            <GoogleTextButton type="submit" text="Join with Google" />
          </form>
          <form className="step-form" action={FACEBOOK_LOGIN_URL} method="POST" onSubmit={afterSubmit}>
            {campaignRelatedFields}
            <FacebookTextButton type="submit" text="Join with Facebook" />
          </form>
        </FormContainer>
      </div>
      <div className="dopple-how-it-works">
        <div className="dopple-how-it-works-container">
          <h5>How Dopple Works:</h5>
          <p>
            <i>Take the Quiz</i>
            <br />
            You: Share style, sizes (0-14Y), brand preferences, and budget. <br />
            Dopple Stylists: Curate a customized Drop just for you.
          </p>
          <p>
            <i>Get Your Drop</i>
            <br />
            Receive 4 to 15 pieces from brands you love (and some you don’t know you love yet) for your little ones to
            try on at home.
          </p>
          <p>
            <i>Keep What You Want</i>
            <br />
            Only pay for the keepers and send the rest back for free. Or buy it all and save an extra 25% off our
            already discounted prices.
          </p>
        </div>
      </div>
    </Container>
  );
};

export default SignUpForm;
