import animalPrint from '../../public/static/images/motifs/animal_print.jpg';
import dinos from '../../public/static/images/motifs/dinos.png';
import farm from '../../public/static/images/motifs/farm.png';
import florals from '../../public/static/images/motifs/florals.jpg';
import plaid from '../../public/static/images/motifs/plaid.jpg';
import polkaDots from '../../public/static/images/motifs/polka_dots.png';
import stars from '../../public/static/images/motifs/stars.jpg';
import stripes from '../../public/static/images/motifs/stripes.png';
import wildAnimals from '../../public/static/images/motifs/wild_animals.jpg';
import words from '../../public/static/images/motifs/words.jpg';

const motifs = [
  {
    src: words,
    value: 'Words',
    key: 'words',
  },
  {
    src: animalPrint,
    value: 'Leopard',
    key: 'animal_print',
  },
  {
    src: dinos,
    value: 'Dinos',
    key: 'dinos',
  },
  {
    src: farm,
    value: 'Farm',
    key: 'farm',
  },
  {
    src: florals,
    value: 'Florals',
    key: 'florals',
  },
  {
    src: plaid,
    value: 'Plaid',
    key: 'plaid',
  },
  {
    src: polkaDots,
    value: 'Polka dots',
    key: 'polka_dots',
  },
  {
    src: stars,
    value: 'Stars',
    key: 'stars',
  },
  {
    src: stripes,
    value: 'Stripes',
    key: 'stripes',
  },
  {
    src: wildAnimals,
    value: 'Animals',
    key: 'animals',
  },
];

export { motifs };
