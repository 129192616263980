import black from '../../public/static/images/colors/000000.png';
import brown from '../../public/static/images/colors/40312e.png';
import blue from '../../public/static/images/colors/4e658f.png';
import purple from '../../public/static/images/colors/644a6f.png';
import gray from '../../public/static/images/colors/808080.png';
import green from '../../public/static/images/colors/90a681.png';
import red from '../../public/static/images/colors/ad1e10.png';
import orange from '../../public/static/images/colors/c56638.png';
import cream from '../../public/static/images/colors/F4EAD5.png';
import pink from '../../public/static/images/colors/fab0af.png';
import yellow from '../../public/static/images/colors/ffec7b.png';
import white from '../../public/static/images/colors/ffffff.png';

export const colors = [
  {
    src: black,
    value: 'Black',
    key: 'black',
  },
  {
    src: brown,
    value: 'Brown',
    key: 'brown',
  },
  {
    src: blue,
    value: 'Blue',
    key: 'blue',
  },
  {
    src: purple,
    value: 'Purple',
    key: 'purple',
  },
  {
    src: gray,
    value: 'Gray',
    key: 'gray',
  },
  {
    src: green,
    value: 'Green',
    key: 'green',
  },
  {
    src: red,
    value: 'Red',
    key: 'red',
  },
  {
    src: orange,
    value: 'Orange',
    key: 'orange',
  },
  {
    src: yellow,
    value: 'Yellow',
    key: 'yellow',
  },
  {
    src: cream,
    value: 'Cream',
    key: 'cream',
  },
  {
    src: pink,
    value: 'Pink',
    key: 'pink',
  },
  {
    src: white,
    value: 'White',
    key: 'white',
  },
];

export default colors;
