import React, { FC } from 'react';
import { useFormikContext } from 'formik';
import { VerticalChoicesSelector } from '../../VerticalChoicesSelector';
import hasErrorFactory from '../../../utils/hasErrorFactory';
import { useOnCompleteHookProvider } from '../../../context/QuizDataContext';
import { StepWrapper, StepHeader, StepBodyWrapper, NoteWrapperBlock } from '../../StepLayout';
import { Choice } from '../../../../../types';
import ErrorAlert from '../../ErrorAlert';

const choices: Choice[] = [
  {
    value: 'warm',
    label: 'Warm weather clothes ☀️⛱️',
  },
  {
    value: 'cold',
    label: 'Cold weather clothes ☃️❄️',
  },
];

export const SeasonsStep: FC = () => {
  const { values, errors, submitCount, touched } = useFormikContext<any>();
  const hasError = hasErrorFactory(submitCount, touched, errors);
  return (
    <StepWrapper>
      <StepHeader>
        What weather are we styling for?
        <NoteWrapperBlock>(Select all that apply)</NoteWrapperBlock>
      </StepHeader>
      <StepBodyWrapper>
        <VerticalChoicesSelector name="seasons" items={choices} value={values.seasons} hasError={hasError('seasons')} />
        <ErrorAlert fieldName="seasons" message={'You must select at least one season'} />
      </StepBodyWrapper>
    </StepWrapper>
  );
};

export const useOnComplete = () => {
  // const updateKid = useUpdateKid();
  // const currentKidId = useCurrentKidId();
  const { updateKid, currentKidId } = useOnCompleteHookProvider();
  return async (values: { seasons: string[] }) => {
    return updateKid(currentKidId as string, values);
  };
};
