import React from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import { down } from 'styled-breakpoints';

import footerDoppleLogo from '../../../public/static/images/logo-white.svg';

import useQueryParams from '../../hooks/useQueryParams';

const year = new Date().getFullYear();

const StyledLogoImg = styled.img`
  width: 125px;
  height: 80px;
  ${down('md')} {
    width: 100px;
  }
  ${down('sm')} {
    width: 80px;
  }
`;

const Footer = ({ showWaitList }: { showWaitList?: boolean }) => {
  const { query } = useQueryParams();

  return (
    <div className="footer wf-section">
      <div className="container-full flex-edges">
        <a href="https://www.thedopple.com" className="footer-logo margin-bottom-xl w-inline-block">
          <StyledLogoImg src={footerDoppleLogo} alt="logo" title="dopple-logo-white" srcSet="" />
        </a>
        <div className="footer-menu">
          <Link href={{ pathname: 'https://www.instagram.com/thedopple', query }}>
            <a className="footer-link">@thedopple</a>
          </Link>
          <Link href={{ pathname: 'https://www.thedopple.com/our-story', query }}>
            <a className="footer-link">Our Story</a>
          </Link>
          <a href="https://jobs.lever.co/dopple" target="_blank" className="footer-link" rel="noopener noreferrer">
            Careers
          </a>
          <Link href={{ pathname: 'https://www.thedopple.com/faq', query }}>
            <a className="footer-link">FAQ</a>
          </Link>
          <a
            href="mailto:partners@thedopple.com?subject=I'd%20like%20to%20sell%20our%20brand%20with%20Dopple!"
            className="footer-link"
          >
            Sell with Dopple
          </a>
          <a href="mailto:parentals@thedopple.com" className="footer-link">
            Contact support
          </a>
        </div>
      </div>
      <div className="container-full flex-edges">
        <div className="footer-menu margin-top-xl">
          <div className="footer-link muted">© Dopple {year}</div>
          <Link href={{ pathname: 'https://www.thedopple.com/privacy', query }}>
            <a className="footer-link muted">Privacy Policy</a>
          </Link>
          <Link href={{ pathname: 'https://www.thedopple.com/terms', query }}>
            <a className="footer-link muted">T&amp;Cs</a>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
