import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Theme } from '../../theme';

type ContainerProps = {
  theme?: Theme;
  isBlack?: boolean;
  hasError?: boolean;
};

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-flow: column wrap;
  width: 100%;

  label {
    margin-bottom: 11px;
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme, isBlack, hasError }) =>
      isBlack
        ? hasError
          ? theme.colors.primaryDanger
          : theme.colors.black
        : hasError
        ? theme.colors.primaryDanger
        : theme.colors.gray};
  }
`;

type ContainerWithLabelProps = ContainerProps & {
  label: string;
  htmlFor?: string;
  children?: ReactNode;
  className?: string;
};

const ContainerWithLabel = ({
  className,
  label,
  htmlFor,
  children,
  isBlack,
  hasError,
  ...labelProps
}: ContainerWithLabelProps) => {
  return (
    <Container className={className} isBlack={isBlack} hasError={hasError}>
      <label htmlFor={htmlFor} {...labelProps}>
        {label}
      </label>
      {children}
    </Container>
  );
};

export default ContainerWithLabel;
