import { Seasons } from '../types';

const seasons: Seasons[] = [
  {
    value: 'warm',
    label: 'Warm weather clothes ☀️⛱️',
  },
  {
    value: 'cold',
    label: 'Cold weather clothes ☃️❄️',
  },
];

export default seasons;
