import Head from 'next/head';
import React, { FC } from 'react';
import { UserProvider } from '../../utils/auth-client';
import Footer from '../Footer';
import Header from '../Header';

type Props = {
  title?: string;
  description?: string;
  showWaitList?: boolean;
};

const defaultTitle = 'Dopple';
const defaultDescription =
  'Dress your little monsters in the coolest styles from hundreds of the best brands, delivered to your door.';

const PageWithAuth: FC<Props> = ({ title, description, children, showWaitList = false }) => (
  <>
    <Head>
      <link rel="shortcut icon" href="/images/favicon.ico" />

      <title>{title || defaultTitle}</title>

      <meta property="og:url" content="https://www.thedopple.com/" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title || defaultTitle} />
      <meta property="og:description" content={description || defaultDescription} />
      <meta property="og:image" content="/images/social-image.png" />
      <meta property="fb:app_id" content="944308482396415" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@dopplehq" />
      <meta name="twitter:title" content={title || defaultTitle} />
      <meta name="twitter:description" content={description || defaultDescription} />
      <meta name="twitter:image" content="/images/social-image.png" />
      <meta name="description" content={description || defaultDescription} />
    </Head>
    <UserProvider>
      <Header />
      {children}
      <Footer showWaitList={showWaitList} />
    </UserProvider>
  </>
);

export default PageWithAuth;
