import React, { createContext, FC, useCallback, useContext, useReducer } from 'react';
import { useKountSession } from '../../../../contexts/KountIntegration';
import {
  logCustomPageView,
  logFBEvent,
  logLegacyGtagEvent,
  pinterestTrack,
  tiktokLogPurchase,
} from '../../../../utils/analytics';
import { PinterestEvent, TrackingEventCategory } from '../../../sale-event/types';
import { stepIdToIndexMap } from '../../constants/steps';
import {
  createOrder as createOrderAPI,
  feeDiscount as feeDiscountApi,
  OrderResponse,
} from '../../services/OrderService';
import { clearStoredStepData } from '../../utils';
import { decodeErrorResponse } from '../../utils/decodeErrorResponse';
import { useCurrentStepId, useGoBackOrToIndex, useQuiz } from '../QuizDataContext';

export type CcField = {
  complete: boolean;
  error: boolean;
};

export type CcData = {
  cardNumber: CcField;
  cardExpiry: CcField;
  cardCvc: CcField;
};

export type FeeDiscounts = {
  total_discount: number;
};

type State = {
  error?: string;
  isLoadingFee?: boolean;
  feeDiscount: FeeDiscounts;
  feeDiscountError?: string;
  isOrderCreated?: boolean;
};
export enum ActionTypes {
  CreateOrderSuccess = 'CreateORderSuccess',
  CreateOrderFailed = 'CreateOrderFailed',
  FetchFeeDiscountStart = 'FetchFeeDiscountStart',
  FetchFeeDiscountSuccess = 'FetchFeeDiscountSuccess',
  FetchFeeDiscountError = 'FetchFeeDiscountError',
}

type Action = {
  type: ActionTypes;
  error?: {
    message: string;
  };
  payload: any;
};

const initialState: State = {
  isLoadingFee: false,
  feeDiscount: { total_discount: 0 },
  isOrderCreated: false,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionTypes.CreateOrderSuccess:
      return { ...state, isOrderCreated: true };
    case ActionTypes.CreateOrderFailed:
      const error = action.error ? action.error.toString() : undefined;
      return { ...state, error };
    case ActionTypes.FetchFeeDiscountStart:
      return { ...state, isLoadingFee: true };
    case ActionTypes.FetchFeeDiscountSuccess:
      return { ...state, isLoadingFee: false, feeDiscount: action.payload };
    case ActionTypes.FetchFeeDiscountError:
      return {
        ...state,
        isLoadingFee: false,
        feeDiscountError: action.payload,
        ...(action.payload ? { feeDiscount: { total_discount: 0 } } : {}),
      };
    default:
      return state;
  }
};

type CreateOrderContextValue = {
  state: State;
  dispatch: any;
  createOrder: (
    coupon: string | null,
    giftCode: string | null,
    isSingle: boolean,
    isNewSubscriber: boolean,
    impactIrClickId?: string,
  ) => Promise<void>;
};

export const CreateOrderContext = createContext<CreateOrderContextValue>({
  createOrder: () => Promise.reject(),
  state: initialState,
  dispatch: () => ({}),
});

export const CreateOrderProvider: FC<{ children: any }> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { sessionId } = useKountSession();
  const goBackOrToIndex = useGoBackOrToIndex();
  const currentStepId = useCurrentStepId();
  const { selectedKids } = useQuiz();

  const _createOrderOnDoppleSide: CreateOrderContextValue['createOrder'] = useCallback(
    async (coupon, giftCode, isSingle, isNewSubscriber, impactIrClickId?) => {
      const response: OrderResponse = await createOrderAPI({
        kount_session_id: sessionId,
        kids: selectedKids,
        coupon,
        gift_code: giftCode,
        is_single: isSingle,
        impact_irclickid: impactIrClickId,
      });
      clearStoredStepData();

      const transactionId = response.orders.map(o => o.id).join('-');
      const transactionAmount = 20.0 * selectedKids.length;

      /*
       * Handle analytics hooks for conversion
       */
      logFBEvent('track', 'Purchase', { currency: 'USD', value: transactionAmount });

      logLegacyGtagEvent('event', 'conversion', {
        send_to: 'AW-776690019/lQtHCJWUvqEBEOOyrfIC',
        transaction_id: transactionId,
      });

      tiktokLogPurchase(TrackingEventCategory.QUIZ, transactionAmount);

      // Hack to trigger the conversion goal already setup in GA.
      logCustomPageView('/quiz/thank-you');

      // send the conversion to Grin
      // @ts-ignore
      Grin = window.Grin || (window.Grin = []);
      // @ts-ignore
      Grin.push(['conversion', transactionAmount, { order_number: transactionId }]);

      if (isNewSubscriber) pinterestTrack(PinterestEvent.SIGNUP, { promo_code: coupon });

      goBackOrToIndex(stepIdToIndexMap.get(currentStepId) + 1);
    },
    [createOrderAPI, sessionId, selectedKids, goBackOrToIndex, currentStepId],
  );

  return (
    <CreateOrderContext.Provider value={{ createOrder: _createOrderOnDoppleSide, state, dispatch }}>
      {children}
    </CreateOrderContext.Provider>
  );
};

export const useOrderErrorMessage = () => {
  const {
    state: { error },
  } = useContext(CreateOrderContext);
  return error;
};

export const useIsOrderCreated = () => {
  const {
    state: { isOrderCreated },
  } = useContext(CreateOrderContext);
  return isOrderCreated;
};

export const useIsLoadingFee = () => {
  const {
    state: { isLoadingFee },
  } = useContext(CreateOrderContext);
  return isLoadingFee;
};

export const useFeeDiscount = () => {
  const {
    dispatch,
    state: { feeDiscount, isLoadingFee, feeDiscountError },
  } = useContext(CreateOrderContext);

  const { sessionId } = useKountSession();
  const { selectedKids } = useQuiz();

  const fetchFeeDiscount = async (coupon: string | null, giftCode: string | null, isSingle: boolean): Promise<void> => {
    if (!selectedKids.length) {
      dispatch({ type: ActionTypes.FetchFeeDiscountSuccess, payload: initialState.feeDiscount });
      return;
    }

    dispatch({ type: ActionTypes.FetchFeeDiscountStart });
    try {
      const discounts = await feeDiscountApi({
        kount_session_id: sessionId,
        kids: selectedKids,
        coupon,
        gift_code: giftCode,
        is_single: isSingle,
      });
      dispatch({ type: ActionTypes.FetchFeeDiscountSuccess, payload: discounts });
      cleanError();
    } catch (e) {
      dispatch({ type: ActionTypes.FetchFeeDiscountError, payload: await decodeErrorResponse(e.response) });
    }
  };

  const cleanError = () => {
    dispatch({ type: ActionTypes.FetchFeeDiscountError }); //empty payload clears the error
  };

  return { isLoadingFee, feeDiscount, feeDiscountError, fetchFeeDiscount, cleanError };
};
