import { FieldProps } from 'formik';
import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import styled from 'styled-components';
import Label, { LabelProps } from '../Label';

const Container = styled.div<{ showError?: boolean }>`
  &.input-wrapper {
    &.full {
      width: 100%;
    }
  }
  .masked-input {
    min-height: 60px;
    border-radius: 4px;
    border: ${({ showError, theme }) =>
      showError ? `solid 1px ${theme.colors.primaryDanger}` : `solid 1px ${theme.colors.white}`} !important;
    box-shadow: 0 2px 6px 0 rgba(94, 97, 100, 0.2);
    background-color: #ffffff;
    padding: 17px 16px;
    width: 100%;
    letter-spacing: 0.05em;

    &:hover,
    &:focus {
      border-color: #cccccc;
    }

    // https://stackoverflow.com/questions/45592319/placeholder-style-not-working-in-chrome
    &::-webkit-input-placeholder {
      color: #787679;
    }
    &:-moz-placeholder {
      color: #787679;
    }
  }
`;

type PhoneInputFieldProps = {
  wrapperClass: string;
  placeholder: string;
  showError: boolean;
  value?: string;
  mask?: string;
};

export const PhoneInputField: FC<PhoneInputFieldProps & FieldProps & LabelProps> = memo(
  ({
    label,
    subLabel,
    labelClass,
    subLabelClass,
    labelWrapperClass,
    showError,
    wrapperClass = '',
    placeholder,
    value = '',
    mask = '(999) 999-9999',
    form,
    field,
  }) => {
    const [fieldValue, setFieldValue] = useState(value);

    useEffect(() => {
      setFieldValue(value);
    }, [setFieldValue, value]);

    const handleChange = useCallback(
      (e: any) => {
        form.setFieldValue(field.name, e.target.value);
        setFieldValue(e.target.value);
      },
      [form, field.name],
    );

    const fieldProps = {
      className: 'masked-input',
      name: field.name,
      onChange: handleChange,
      onBlur: () => form.setFieldTouched(field.name),
      placeholder,
      value: fieldValue,
    };

    const labelProps = { label, subLabel, labelClass, subLabelClass, labelWrapperClass, showError };

    return (
      <Container className={`input-wrapper ${wrapperClass}`} showError={showError}>
        <Label {...labelProps} />
        <InputMask mask={mask} {...fieldProps} />
      </Container>
    );
  },
);
