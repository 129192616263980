import { useState, useLayoutEffect, useCallback } from 'react';
import { useUser } from '../../../utils/auth-client';
import { KlaviyoEvent } from '../types';

type UserInfo = {
  email: string;
  first_name: string;
  last_name: string;
};

function useKlaviyo(identifyUser: boolean = false, userInfo?: UserInfo) {
  const { currentUser } = useUser();
  const [klaviyo, setKlaviyo] = useState(null);

  useLayoutEffect(() => {
    if (!window._learnq || !currentUser) {
      return;
    }

    if (identifyUser) {
      window._learnq.push([
        'identify',
        {
          $email: userInfo ? userInfo.email : currentUser.email,
          $first_name: userInfo ? userInfo.first_name : currentUser.first_name,
          $last_name: userInfo ? userInfo.last_name : currentUser.last_name,
        },
      ]);
    }

    setKlaviyo(window._learnq);
  }, [currentUser, window._learnq]);

  const trackProperties = useCallback(
    (klaviyoEvent: KlaviyoEvent, properties: Record<string, any>) => {
      if (klaviyo) {
        klaviyo.push(['track', klaviyoEvent, properties]);
      }
    },
    [klaviyo],
  );

  return { klaviyo, trackProperties };
}

export default useKlaviyo;
