import { Field, useFormikContext } from 'formik';
import React, { FC, memo } from 'react';
import { down } from 'styled-breakpoints';
import styled from 'styled-components';
import ItemsCheckbox from '../../../../components/ItemsCheckbox';
import Spinner from '../../../../components/Spinner';
import { Choice } from '../../../../types';
import ErrorAlert from '../ErrorAlert';
import { StepBodyWrapper, StepHeader, StepWrapper } from '../StepLayout';

type LikenessSelectorProps = {
  name: string;
  image: string;
  label: string;
  submitOnChange?: boolean;
};

export const choices: Choice[] = [
  { value: 'like', label: 'Like' },
  { value: 'dislike', label: 'Dislike' },
  { value: 'unsure', label: 'Unsure' },
];

const DisclaimerWrapper = styled.p`
  font-size: 12px;
  text-align: center;
  font-style: italic;
`;

const LikenessContainer = styled.div`
  ${down('sm')} {
    width: 100%;
  }

  > * {
    margin: 15px 0;
  }

  .step-input {
    flex-wrap: unset;
    gap: 0.5rem;
    justify-content: space-around;

    & > label {
      min-width: unset;
      max-width: unset;
      flex-basis: 8rem;
    }
  }
`;

const Img = styled.img`
  max-width: 624px;
  width: 100%;
  aspect-ratio: 2.17;

  ${down('sm')} {
    max-width: 100%;
  }
`;

const CenteredSpinner = styled(Spinner)`
  display: block;
  margin: 0 auto;
`;

const LikenessSelector: FC<LikenessSelectorProps> = memo(({ name, image, label, submitOnChange }) => {
  const formik = useFormikContext();

  return (
    <StepWrapper>
      <StepHeader>{label}</StepHeader>
      <Img src={image} alt={label} />
      <StepBodyWrapper>
        <LikenessContainer data-error-field={name} {...(formik.isSubmitting ? { inert: 'inert' } : undefined)}>
          <Field
            name={name}
            wrapperClass="step-input"
            items={choices}
            component={ItemsCheckbox}
            onChoiceChange={() => {
              if (submitOnChange) setTimeout(formik.submitForm, 0);
            }}
            isRadiobox={true}
          />
          {formik.isSubmitting && <CenteredSpinner />}
          <DisclaimerWrapper>
            This question is to help us understand your style... your Drop will include items specifically selected for
            you, which will be different than the pics shown here.
          </DisclaimerWrapper>
        </LikenessContainer>
      </StepBodyWrapper>
      <ErrorAlert fieldName={name} message={'You must select one option.'} />
    </StepWrapper>
  );
});

export default LikenessSelector;
