import styled from 'styled-components';
import { down } from 'styled-breakpoints';

export const FieldRow = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  & > * {
    flex: 1 1 auto;
  }

  .half {
    max-width: 160px;
    margin: 0 !important;
  }
`;

export const FieldSet = styled.div`
  padding: 46px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;

  ${FieldRow} {
    margin: 0 0 32px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ${down('sm')} {
    padding: 18px;
  }
`;

export const MediumContainer = styled.div`
  flex: 1 1 40%;

  ${down('sm')} {
    flex-basis: 100%;
  }
`;

export const SmallContainer = styled.div`
  flex: 0 1 21%;
  margin-left: 36px;

  ${down('sm')} {
    margin-left: 0;
    margin-top: 32px;
    flex: 1 1 100%;
  }
`;
