import { State } from './index';
import { keys, pick } from 'ramda';

export enum ActionType {
  START_SUBMITTING,
  COMPLETE_SUBMITTING,
  SAVE_KID,
  SET_KIDS,
  SET_IS_FORM_CLEAR,
  SAVE_KIDS_FAIL,
}

export type Action = {
  type: ActionType;
  payload?: any;
};

const KID_FIELDS = [
  'name',
  'date_of_birth',
  'gender',
  'gender_preference',
  'top_size',
  'bottom_size',
  'shoes_size',
  'num_items',
  'id',
  'quiz_type',
  'quiz_data',
];

export const reducer = (state: State, { type, payload }: Action) => {
  switch (type) {
    case ActionType.START_SUBMITTING:
      return {
        ...state,
        isSubmitting: true,
        error: undefined,
      };
    case ActionType.COMPLETE_SUBMITTING:
      return {
        ...state,
        isSubmitting: false,
      };
    case ActionType.SAVE_KID:
      const kids = state.kids;
      let kidFound = kids.find(kid => kid.name === payload.kid.name);
      if (kidFound) {
        kidFound = payload.kid;
      } else {
        kids.push(payload.kid);
      }
      const newState = {
        ...state,
        kids,
      };
      payload.persistKidsFn && payload.persistKidsFn(newState.kids);
      return {
        ...newState,
        kidsLength: newState.kids.length,
      };
    case ActionType.SET_KIDS:
      return {
        ...state,
        kids: payload,
        kidsLength: payload.length,
      };
    case ActionType.SET_IS_FORM_CLEAR:
      return {
        ...state,
        isFormClear: payload,
      };
    case ActionType.SAVE_KIDS_FAIL:
      return {
        ...state,
        isSubmitting: false,
        error: payload,
      };
    default:
      return state;
  }
};
