import { useQuiz } from '../../style-quiz/context/QuizDataContext';
import { useEffect } from 'react';
import { State } from './index';
import { keys } from 'ramda';

export const useInitializeCheckoutContext = (shortQuizState: State) => {
  const { selectKid } = useQuiz();

  useEffect(() => {
    Array.isArray(shortQuizState?.kids) && shortQuizState.kids.forEach(kid => selectKid(kid.id));
  }, [shortQuizState.kids]);
};
