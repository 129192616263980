import React, { ButtonHTMLAttributes, FC } from 'react';
import styled from 'styled-components';
import BaseButtonStyle, { ButtonProps } from './BaseButtonStyle';
import loader from '../../../public/static/images/loader.svg';

const StyledButton = styled.button`
  ${BaseButtonStyle}
`;

type Props = ButtonHTMLAttributes<{}> &
  ButtonProps & {
    isLoading?: boolean;
  };

const Button: FC<Props> = React.forwardRef<HTMLButtonElement, Props>(
  ({ isLoading, children, className, ...rest }: Props, ref) => {
    return (
      <StyledButton {...rest} className={className} ref={ref}>
        {isLoading ? <img src={loader} className="loader" alt="" /> : children}
      </StyledButton>
    );
  },
);

// ...wrapping A in a styled() factory makes it eligible for interpolation
// (https://styled-components.com/docs/advanced#caveat,  https://stackoverflow.com/questions/60700774/typescript-styled-components)
export default styled(Button)``;
