import React from 'react';
import styled from 'styled-components';
import { SocialButton } from './index';
import googleLogo from '../../../public/static/images/logos/google-g-logo.png';
import SocialTextButtonStyle from './SocialTextButtonStyle';

const StyledLinkButton = styled(SocialButton)`
  ${SocialTextButtonStyle}
  img {
    margin-top: 1px;
    margin-right: -3px;
    width: 23px;
    height: 23px;
  }
`;

const GoogleTextButton = props => {
  return <StyledLinkButton {...props} fontColor="#484040" icon={<img src={googleLogo} />} />;
};

export default GoogleTextButton;
