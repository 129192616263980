import React from 'react';
import styled from 'styled-components';
import { Field, Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

import Button from '../../../ConfirmKidsAndAddress/components/Button';
import { InputField } from '../../../../components/InputField';

import Alert, { AlertType } from '../../../../components/Alert';
import useQuizRecovery from '../../hooks/useQuizRecovery';

const FormContainer = styled.div<{ remaining: number }>`
  max-width: 300px;
  margin: 0 auto;

  .buttons-placeholder {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
  }

  .code-field {
    display: flex;
    align-items: center;
  }

  .recover-btn {
    margin-left: 5px;
    & > button {
      height: 60px;
    }
  }

  .request-alert {
    margin: 15px 0;
    a {
      cursor: pointer;
      opacity: 1;
      ${({ remaining }) =>
        remaining > 0 &&
        `
      cursor: unset;
      pointer-events: none;
      opacity: 0.6;
      `}
    }
  }
`;

const Container = styled.div`
  .step-header {
    display: none;
  }

  .quiz-step-header-container {
    width: 90%;
    margin: 30px auto;
    display: flex;
    flex-flow: column;
    align-content: center;
    justify-content: center;
    text-align: center;

    @media screen and (min-width: 500px) {
      width: 500px;
    }
  }

  .quiz-step-header {
    font-size: 30px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: 0.6px;
    margin-bottom: 10px;
  }

  .quiz-step-subheader {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.76;
    letter-spacing: 0.28px;
  }

  .underlined {
    text-decoration: underline;
  }

  .style-quiz {
    margin: 2rem auto 4rem auto;

    hr {
      margin: 1rem 0;
      border-color: rgba(94, 97, 100, 0.1);
      border-width: 1px;
    }

    .social-header {
      font-size: small;
    }
  }
`;

type FormValues = {
  code: string;
};

const validationSchema = Yup.object().shape({
  code: Yup.string().required(),
});

const QuizRecoveryForm = () => {
  const {
    recoverQuizHandler,
    error,
    email,
    requestCodeHandler,
    goBackHandler,
    remaining,
    isSubmitting,
  } = useQuizRecovery();

  return (
    <Container>
      <div className="quiz-step-header-container">
        <div className="quiz-step-header">
          Let's Start <span className="underlined">Your</span> Drop
        </div>
        <Alert type={AlertType.INFO} className="quiz-step-subheader">
          <p> We see you’ve already started the quiz!</p>
          <p>
            To return to where you left off, enter the 5 digit code sent to <strong>{email}</strong>.
          </p>
        </Alert>
        {error && (
          <Alert type={AlertType.ERROR} className="quiz-step-subheader">
            {error}
          </Alert>
        )}
      </div>

      <div className="style-quiz">
        <FormContainer remaining={remaining}>
          <Formik
            initialValues={{ code: '', email }}
            validationSchema={validationSchema}
            isInitialValid={false}
            onSubmit={recoverQuizHandler}
            enableReinitialize
          >
            {({ values: { code }, isValid, errors }: FormikProps<FormValues>) => (
              <Form>
                <div className="code-field">
                  <Field
                    name="code"
                    component={InputField}
                    placeholder="Code..."
                    value={code}
                    wrapperClass="full"
                    showError={Boolean(errors.code)}
                    disabled={isSubmitting}
                  />
                  <Button
                    type="submit"
                    className="recover-btn"
                    value="Recover"
                    disabled={!isValid || isSubmitting}
                    isLoading={isSubmitting}
                  />
                </div>
                <Alert type={AlertType.INFO} className="request-alert">
                  Don’t see a code? You can{' '}
                  <a onClick={requestCodeHandler}>
                    <strong>request a new one</strong>
                  </a>
                  {remaining > 0 && ` in ${remaining} seconds`}.
                </Alert>
              </Form>
            )}
          </Formik>
        </FormContainer>
      </div>
    </Container>
  );
};

export default QuizRecoveryForm;
