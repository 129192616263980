import React, { FC, useState, useEffect } from 'react';
import { userToLocalStorage } from '../../../../utils/api-client';
import { loginRedirect } from '../../../../utils/redirect';
import { User } from '../../../../types';
import { useRouter } from 'next/router';
import { cookieDomain, tokenCookieName } from '../../../../constants';
import jsCookie from 'js-cookie';
import useQueryParams from '../../../../hooks/useQueryParams';
import { identifyOnImpact } from '../../../../utils/analytics';
import { validateToken } from '../../../../services/user';

type TokenizedUserLogin = {
  user: User | null;
  isLoading: boolean;
  error: Error | null;
};

function useWishlistLogin(token: string): TokenizedUserLogin {
  const [state, setState] = useState<TokenizedUserLogin>({
    user: null,
    isLoading: true,
    error: null,
  });

  useEffect(() => {
    async function validateUserToken() {
      try {
        const result = await validateToken(token);
        if (result.valid && result.user) {
          setState({ user: result.user, isLoading: false, error: null });
        } else {
          setState({ user: null, isLoading: false, error: new Error('Invalid wishlist token') });
        }
      } catch (error) {
        setState({ user: null, isLoading: false, error: error as Error });
      }
    }

    validateUserToken();
  }, [token]);

  return state;
}

const TokenRedirection: FC<{ token: string }> = ({ token }) => {
  const { user, isLoading, error } = useWishlistLogin(token as string);
  const router = useRouter();
  const { queryAsString } = useQueryParams(['rt', 'return', 'next'], true);

  if (isLoading) {
    return <p className={'with-full text-center m-top-lg'}>Loading token...</p>;
  }

  if (user && !error) {
    userToLocalStorage(user);
    identifyOnImpact(user.id, user.email_sha1);
    const cookieOptions = cookieDomain ? { domain: cookieDomain } : undefined;
    jsCookie.set(tokenCookieName, user.auth_token, cookieOptions);
    if (user.can_refer) {
      jsCookie.set('user_can_refer', 'true', cookieOptions);
    }
    loginRedirect(user, router, queryAsString);
  }

  if (error) {
    router.push('/sign-in');
  }

  return user && !error ? <p className={'with-full text-center m-top-lg'}>Redirecting...</p> : null;
};

export default TokenRedirection;
