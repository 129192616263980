import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { down } from 'styled-breakpoints';
import styled from 'styled-components';
import Alert, { AlertType } from '../../../../../components/Alert';
import Button from '../../../../../components/Buttons/Button';
import ContainerWithLabel from '../../../../../components/ContainerWithLabel';
import LoadingAnimation from '../../../../../components/LoadingAnimation';
import { useShippingAddress } from '../../../../style-quiz/context/ShippingAddress';
import AddressText from './AddressText';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(166, 179, 193, 0.9);
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

const Container = styled.div`
  width: 68%;
  padding: 70px 20px;
  border-radius: 4px;
  box-shadow: 0 12px 26px 0 rgba(20, 20, 20, 0.1);
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-flow: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  ${down('sm')} {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  h5 {
    margin: 20px 0;
    text-align: center;
  }

  p {
    padding: 10px;
  }

  .addressText {
    color: #58627b;
    font-size: 0.8rem;
  }

  ${down('sm')} {
    width: 100%;
    margin: 0 10px;
    padding: 30px 20px;
  }
`;

const ContentBody = styled.div`
  ${down('md')} {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;

    p {
      text-align: center;
    }
  }

  ${down('sm')} {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;

    p {
      text-align: center;
    }
  }
`;

const Columns = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

type AddressColumn = {
  left?: boolean;
};

const AddressColumn = styled.div<AddressColumn>`
  width: 50%;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  ${({ left }) => (left ? 'border-right: 1px solid gray;' : '')}

  ${down('md')} {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    ${({ left }) => (left ? 'border-right: none; border-bottom: 1px solid gray;' : '')}
  }

  ${down('sm')} {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    ${({ left }) => (left ? 'border-right: none; border-bottom: 1px solid gray;' : '')}
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SelectButton = styled(Button)`
  margin-top: 10px;
`;

const H6 = styled.h6`
  margin-bottom: 10px;
  margin-top: 15px;
`;

const StyledContainerWithLabel = styled(ContainerWithLabel)`
  margin: 0 20px;
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 35%;

  ${down('md')} {
    width: 50%;
  }

  ${down('sm')} {
    flex-direction: column;
  }
`;
const ReturnButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.blue};
  width: 95%;
  padding: 10px;

  &:disabled {
    background-color: ${({ theme }) => theme.colors.black};

    &:hover {
      background-color: ${({ theme }) => theme.colors.black};
    }
  }

  ${down('sm')} {
    width: 100%;
  }
`;

const StyledLoadingAnimation = styled(LoadingAnimation)`
  margin-top: 20px;
`;

const ColumnLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const AlertMessage = styled(Alert)`
  margin: 0 0 20px 0;
`;

type State = {
  isLoading: boolean;
  error?: Error;
};

type VerifyAddressProps = {
  addresses: Record<string, any>;
  onClose?: Function;
  confirmed: Function;
  error: string;
};

export const notFoundAddress = 'notFoundAddress';

const ChoiceAddress = memo((props: VerifyAddressProps) => {
  const [status, setStatus] = useState<State>({ isLoading: false });
  const { addresses, confirmed, error, onClose } = props;

  const { setAddressToChoose } = useShippingAddress();

  const mountedRef = useRef(true);

  const handleAddressSelection = useCallback(
    address => {
      setStatus({ isLoading: true });
      confirmed(address).then(res => {
        if (!mountedRef.current) return null;
        setStatus({ isLoading: false });
        return res;
      });
    },
    [confirmed],
  );

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const addressInfo = useMemo(
    () => ({
      entered: !addresses ? {} : addresses[0],
      recommended: !addresses ? {} : addresses[1],
    }),
    [addresses],
  );

  const isInvalidAddressError = useMemo(() => {
    return error === 'Invalid Address';
  }, [error]);

  const handleGoBack = useCallback(() => {
    setAddressToChoose(null);
    onClose && onClose();
  }, [onClose]);

  if (addresses[0] === notFoundAddress || addresses[0]?.address_1 === notFoundAddress) {
    return (
      <>
        <h5 className="heading-s">Confirm Shipping Address</h5>
        {error && (
          <AlertMessage type={isInvalidAddressError ? AlertType.WARNING : AlertType.ERROR}>
            {isInvalidAddressError ? 'Address not found.' : error}
          </AlertMessage>
        )}
        <p>We have verified your address against the USPS and have found it could be an invalid address in the US.</p>
        <Columns>
          <AddressColumn left>
            <ColumnLeft>
              <SelectButton secondary onClick={handleGoBack} disabled={status.isLoading}>
                Go back and Review
              </SelectButton>
            </ColumnLeft>
          </AddressColumn>
          <AddressColumn>
            <ContentBody>
              <H6 className="heading-xs">Keep this address:</H6>
              <AddressText address={addresses[1]} />
              <ButtonWrapper>
                <SelectButton
                  type="button"
                  onClick={() => handleAddressSelection(addresses[1])}
                  disabled={status.isLoading}
                >
                  Use this address
                </SelectButton>
              </ButtonWrapper>
            </ContentBody>
          </AddressColumn>
        </Columns>
        {status.isLoading && <StyledLoadingAnimation />}
      </>
    );
  }

  return (
    <>
      <h5 className="heading-s">Confirm Shipping Address</h5>
      {error && (
        <AlertMessage type={isInvalidAddressError ? AlertType.WARNING : AlertType.ERROR}>
          {isInvalidAddressError ? 'Address not found.' : error}
        </AlertMessage>
      )}
      <p>
        We have verified your address against the United States Postal Service and have found the address to be
        incomplete or inaccurate.
      </p>
      <Columns>
        <AddressColumn left>
          <ContentBody>
            <H6 className="heading-xs">You entered:</H6>
            <AddressText address={addressInfo.entered} />
            <SelectButton
              type="button"
              secondary
              onClick={() => handleAddressSelection(addresses[0])}
              disabled={status.isLoading}
            >
              Keep this address
            </SelectButton>
          </ContentBody>
        </AddressColumn>
        <AddressColumn>
          <ContentBody>
            <H6 className="heading-xs">Recommended Address:</H6>
            <AddressText address={addressInfo.recommended} />
            <SelectButton
              type="button"
              onClick={() => handleAddressSelection(addresses[1])}
              disabled={status.isLoading}
            >
              Use this address
            </SelectButton>
          </ContentBody>
        </AddressColumn>
      </Columns>
      {status.isLoading && <StyledLoadingAnimation />}
    </>
  );
});

const VerifyAddressModal = (props: VerifyAddressProps) => {
  const { addresses, onClose } = props;

  return (
    <Wrapper>
      <Container>
        {addresses !== null && <ChoiceAddress addresses={addresses} {...props} />}
        {addresses === null && (
          <>
            <StyledContainerWithLabel label="Sorry, your address could not be found. Please check the shipping address you entered." />
            <br />
            <ButtonContainer>
              <ReturnButton onClick={() => onClose()}>Return</ReturnButton>
            </ButtonContainer>
          </>
        )}
      </Container>
    </Wrapper>
  );
};

export default memo(VerifyAddressModal);
