import { Address } from '../types';
import client from '../utils/api-client';

export const create = async (values: Partial<Address>) => {
  try {
    await client('api/addresses/', { body: values, method: 'POST' });
  } catch (exception) {
    throw new Error('Failed to edit address.');
  }
};

export const verifyAddress = (shippingAddress: Address) => {
  if (!shippingAddress.verification_status) {
    delete shippingAddress.verification_status;
  }

  if (!shippingAddress.address_2) shippingAddress.address_2 = '';

  return client<Address[]>(`api/addresses/verify/`, {
    body: {
      ...shippingAddress,
    },
  });
};
