import { Field, useFormikContext } from 'formik';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import Alert, { AlertType } from '../../../../../components/Alert';
import { Button } from '../../../../../components/Buttons';
import ConfirmModal from '../../../../../components/ConfirmModal';
import { InputField } from '../../../../../components/InputField';
import Spinner from '../../../../../components/Spinner';
import { useUser } from '../../../../../utils/auth-client';
import { validateRequiredString } from '../../../../../utils/validations';
import useKiddosList from '../../../../Kids/hooks/useKiddosList';
import { Kid } from '../../../../Kids/types';
import { LAST_STEP_INDEX } from '../../../constants/steps';
import {
  IQuizValues,
  useOnCompleteHookProvider,
  useQuiz,
  useSelfInfo,
  useSetLoadedKids,
} from '../../../context/QuizDataContext';
import useRemoveKid from '../../../hooks/useRemoveKid';
import { nestFlatKidDataToSteps } from '../../../services/KidService';
import { nestFlatMeDataToSteps } from '../../../services/MeService';
import { QuizStorageKey } from '../../../types';
import {
  clearStoredCurrentKid,
  retrieveStoredStepData,
  shortOrdinalNames,
  storageCurrentStepData,
} from '../../../utils';
import hasErrorFactory from '../../../utils/hasErrorFactory';
import { NoteWrapper, StepBodyWrapper, StepHeader, StepWrapper } from '../../StepLayout';

const ordinalNames: { [key: number]: string } = {
  1: 'first',
  2: 'second',
  3: 'third',
  4: 'fourth',
  5: 'fifth',
};

const FooterLabelWrapper = styled(NoteWrapper)`
  margin-top: 14px;
  font-weight: 100;
  line-height: 1.64;
  font-size: 14px;
  font-family: 'Soehne Breit';
`;

const SmallAlert = styled(Alert)`
  font-size: 14px;
  line-height: 1.4;
`;

const RemoveKidButton = styled(Button)`
  width: 100%;
  margin-top: 20px;
`;

export const SelfInfoStep: FC = () => {
  const { values, errors, submitCount, touched, setFieldValue } = useFormikContext<any>();
  const hasError = hasErrorFactory(submitCount, touched, errors);
  const selfInfo = useSelfInfo();
  const { data: kiddosData, reload: reloadKids } = useKiddosList();
  const { selectedKids, removeSelectedKid } = useQuiz();
  const { currentUser } = useUser();
  const [openConfirm, setOpenConfirm] = useState(false);
  const toggleOpenConfirm = useCallback(() => setOpenConfirm(prev => !prev), []);
  const { lastKidName: currentStoresKidName } = retrieveStoredStepData();
  const setLoadedKids = useSetLoadedKids();

  const kiddos = useMemo(() => {
    if (kiddosData) return kiddosData.results;
    return [];
  }, [kiddosData]);

  const { error: removingError, isLoading: isRemoving, remove } = useRemoveKid(kidId => {
    setFieldValue('name', '');
    reloadKids();
    clearStoredCurrentKid(kidId);
    removeSelectedKid(kidId);
    setLoadedKids(kiddos.filter(kid => kid.id !== kidId));
  });

  useEffect(() => {
    if (!values.name && currentStoresKidName) {
      setFieldValue('name', currentStoresKidName);
    }
  }, [currentStoresKidName, values.name]);

  const uncompletedQuizFound = useMemo(() => kiddos.some(k => !k.is_quiz_completed), [kiddos]);

  const shouldShowNameInputs = useMemo(() => {
    return !(selfInfo && selfInfo.first_name && selfInfo.last_name);
  }, [selfInfo]);

  const kidsCount = useMemo(() => {
    if (!Array.isArray(selectedKids)) {
      return 1;
    }
    if (!values.name) {
      return selectedKids.length + 1;
    }
    return selectedKids.length;
  }, [selectedKids, values.name]);

  const headerText =
    kidsCount > 1
      ? `Tell us more about your ${shortOrdinalNames(kidsCount)} kiddo!`
      : 'Tell us more about you + your kiddo!';
  const footerLabel = "You'll have the option to add your other kiddos later if you want!";

  const currentChild = useMemo<Kid>(() => {
    if (!values.name) return null;
    return kiddos.find(kid => kid.name === values.name);
  }, [values.name, kiddos]);

  const removeKidHandler = useCallback(async () => {
    toggleOpenConfirm();
    if (currentChild) {
      await remove(currentChild.id);
      setFieldValue('name', '');
    }
  }, [currentChild, remove, toggleOpenConfirm]);

  return (
    <>
      <StepWrapper>
        <StepHeader>{headerText}</StepHeader>
        <StepBodyWrapper>
          {shouldShowNameInputs && (
            <>
              <Field
                name="first_name"
                label="Your first name"
                labelWrapperClass="step-label"
                wrapperClass="step-input"
                placeholder="Enter your first name"
                value={values.first_name}
                component={InputField}
                validate={validateRequiredString}
                showError={hasError('first_name')}
              />
              <Field
                name="last_name"
                label="Your last name"
                labelWrapperClass="step-label"
                wrapperClass="step-input"
                placeholder="Enter your last name"
                value={values.last_name}
                component={InputField}
                validate={validateRequiredString}
                showError={hasError('last_name')}
              />
            </>
          )}
          <Field
            name="name"
            label="What is your child's first name?"
            labelWrapperClass="step-label"
            wrapperClass="step-input"
            placeholder="Enter your child's first name"
            component={InputField}
            value={values.name}
            showError={hasError('name')}
            disabled={Boolean(currentStoresKidName)}
          />
          {/* Only show this message if someone is taking the quiz for the first time for any number of children */}
          {!shouldShowNameInputs && uncompletedQuizFound && currentUser.has_quiz_completed && (
            <SmallAlert type={AlertType.INFO}>
              * If you lost your way, don't worry! Enter your kiddo's name and you will pick up where you left off.
            </SmallAlert>
          )}
          <FooterLabelWrapper>{footerLabel}</FooterLabelWrapper>
          {currentChild && !currentChild.is_quiz_completed && (
            <RemoveKidButton size="small" secondary onClick={toggleOpenConfirm} type="button">
              {isRemoving ? <Spinner /> : 'Remove kid'}
            </RemoveKidButton>
          )}
          {removingError && (
            <Alert type={AlertType.ERROR}>The child you are trying to delete is already subscribed</Alert>
          )}
        </StepBodyWrapper>
      </StepWrapper>
      <ConfirmModal
        open={openConfirm}
        onConfirm={removeKidHandler}
        onClose={toggleOpenConfirm}
        title={'Confirm'}
        message={`Do you want to remove ${values.name}?`}
        confirmButtonText="Remove"
      />
    </>
  );
};

export const useOnComplete = () => {
  const {
    updateSelfInfo,
    createKid,
    updateKid,
    setAllValues,
    selfInfo,
    quizValues,
    loadedKids,
    selectKid,
  } = useOnCompleteHookProvider();
  return async (values: { first_name?: string; last_name?: string; name: string }) => {
    const { first_name, last_name, name } = values;
    let newQuizValues: IQuizValues = {};
    if (first_name && last_name) {
      const updatedInfo = await updateSelfInfo({ first_name, last_name });
      newQuizValues = nestFlatMeDataToSteps(quizValues, updatedInfo);
    } else if (selfInfo) {
      newQuizValues = nestFlatMeDataToSteps(quizValues, selfInfo);
    }
    let kidInfo = loadedKids.find(k => k.name === name);
    let nextStep = null;

    if (!kidInfo) {
      kidInfo = await createKid(name);
    } else {
      newQuizValues = nestFlatKidDataToSteps(newQuizValues, kidInfo);

      if (kidInfo.id) {
        await updateKid(kidInfo.id, kidInfo, true);
        const lastStep = +kidInfo.last_quiz_step;
        nextStep = lastStep >= LAST_STEP_INDEX || lastStep <= 0 ? 1 : +kidInfo.last_quiz_step;
      }
    }

    storageCurrentStepData(QuizStorageKey.LAST_KID_NAME, name);

    await setAllValues(newQuizValues);

    selectKid(kidInfo.id);

    return nextStep;
  };
};
