import React from 'react';
import styled from 'styled-components';
import { FullsizePicture } from 'react-responsive-picture';

const Container = styled.div`
  height: 370px;
`;

const SignUpHeader = () => (
  <Container>
    <FullsizePicture
      cover="height"
      src={require('../../../../../public/static/images/style-quiz/sign_up_hero_image.jpg')}
      sources={[
        {
          srcSet: require('../../../../../public/static/images/style-quiz/sign_up_hero_image.jpg'),
          type: 'image/webp',
        },
      ]}
    />
  </Container>
);

export default SignUpHeader;
